import React, { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import api from "../api";
import { QuestContext } from "../context/questContext/QuestContext";
import { SET_QUEST_HISTORY } from "../context/questContext/action";

const useHistoryQuest = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const { questState, questDispatch } = useContext(QuestContext);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser.token}`,
  };
  const QuestHistory = async ({ questId }) => {
    if (questState.questHistory[questId]) {
      return;
    }
    setLoading(true);

    try {
      let res = await api.get(`/quest/history/${questId}`, {
        headers,
      });
      if (res.status === 200) {
        console.log(res.data, "sdjflksdf");
        questDispatch({
          type: SET_QUEST_HISTORY,
          payload: {
            ...questState.questHistory,
            [questId]: res.data.chatArray,
          },
        });
      }
    } catch (err) {
      console.log(err.response.message);
    }
    setLoading(false);
  };
  return { QuestHistory, questHisLoading: loading };
};

export default useHistoryQuest;
