import React, { useContext, useEffect, useState } from "react";
import DesignQuest from "../../layouts/DesignQuest";
import FoodBg from "../../assets/screens/cluniarywizard/foodBg.webp";
import { SyncLoader } from "react-spinners";
import frame1 from "../../assets/screens/cluniarywizard/weightgain.webp";
import frame2 from "../../assets/screens/cluniarywizard/veg.webp";
import frame3 from "../../assets/screens/cluniarywizard/vegan.webp";
import frame4 from "../../assets/screens/cluniarywizard/nonveg.webp";
import frame5 from "../../assets/screens/cluniarywizard/balancediet.webp";
import frame6 from "../../assets/screens/cluniarywizard/weightloss.webp";
import "./DesignCulinaryWizardy.css";
import { useQuestResult } from "../../hooks/useQuestResult";
import { useNewTextGenerate } from "../../hooks/useNewTextGenerate";
import { UseCopyText } from "../../hooks/useCopyText";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import { useParams } from "react-router-dom";
const buttonArray = [
  {
    name: "Weight Gain Diet",
    img: frame1,
  },
  {
    name: "Vegetarian Diet",
    img: frame2,
  },
  {
    name: "Vegan Diet",
    img: frame3,
  },
  {
    name: "Nonveg Diet",
    img: frame4,
  },
  {
    name: "Balance Diet",
    img: frame5,
  },
  {
    name: "Weight loss Diet",
    img: frame6,
  },
];
const DesignCulinaryWizardy = () => {
  const {quest_type}=useParams()
  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0

  const [desc, setDesc] = useState("");
  const [button, setButton] = useState([]);
  const [cuisine, setCuisine] = useState("");
  const { errorState } = useContext(ErrorContext);
  const handleButton = (butt) => {
    if (button.includes(butt)) {
      setButton(button.filter((e) => e !== butt));
    } else {
      setButton([...button, butt]);
    }
  };
  const { fetchText, textloading, answer } = useNewTextGenerate();
  const { copyText } = UseCopyText();
  const { fetchResult } = useQuestResult();

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
}, []);

  const modalStyle = {
    backgroundImage: `url(${FoodBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };

  const handleGenerateButton = async () => {
    const textPrompt = `create a recipe for ${cuisine} cuisine having ${button.join(
      ","
    )} and ingredients as ${desc}`;

    let response = await fetchText({ prompt: textPrompt, quest_type });
    if (response) {
      let res = await fetchResult({ answer, quest_type, prompt: textPrompt });
      console.log(res);
      if (res === "OK") {
        setButton([]);
        setDesc("");
        setCuisine("");
        console.log("done");
      }
    }
  };
  return (
    <DesignQuest
      modalStyle={modalStyle}
      title="Welcome To Culinary Wizardry"
    >
      {errorState.errorModal && <LimitErrorModal />}
      <div
        style={{ zIndex: 10000 }}
        className="flex gap-20 justify-center items-center px-2 py-2"
      >
        <div className="relative rounded-xl flex-shrink w-[35%] h-[72vh] mt-[4rem]">
          

        <div
          style={{
            background: "linear-gradient(45deg, #7175c2, #5f62a9, #4e507a)",
            lineHeight: 1.8,
          }}
          className="relative text-yellow-500 rounded-xl flex-shrink bg-culinary  w-full px-8 py-4 h-full overflow-scroll"
        >
          {textloading ? (
            <SyncLoader
              color="#36d7b7"
              style={{
                zIndex: 10000,
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                marginTop: "30px",
              }}
            />
          ) : (
            ""
          )}
          {answer && !textloading
            ? answer.split("\n\n").map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                  <br />
                </span>
              ))
            : ""}
              
        </div>
        <button
                  disabled={!answer && textloading}
                  onClick={() => copyText(answer)}
                  style={{ border: "1px solid black" }}
                  className="w-24 absolute bottom-[5%] right-[4%] h-6 uppercase text-black outline-none rounded-md text-sm  shadow-2xl bg-gray-200"
                >
                  Copy text
                </button>
        </div>
        
        <div
          style={{ background: "#4e6d7f" }}
          className="relative flex flex-col gap-6 items-center py-8 px-4 rounded-xl flex-shrink w-[30%]  h-[80vh]  mt-[2rem]"
        >
          <textarea
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            placeholder="Ingredients..."
            style={{ resize: "none" }}
            className="outline-none text-white text-sm w-[90%] h-[19vh] bg-[#3f3151] rounded-xl px-4 py-4 overflow-scroll"
          ></textarea>

          <div className="flex flex-wrap  w-full h-[31vh]">
            {buttonArray.map((e) => (
              <div
                onClick={() => handleButton(e.name)}
                className={`${
                  button.includes(e.name) ? "opacity-20" : ""
                } cursor-pointer w-1/3 h-[50%] flex flex-col items-center`}
              >
                <img
                  src={e.img}
                  alt=""
                  className="w-full h-full object-contain"
                />
                <span className="text-[10px] mt-[-10%] uppercase">
                  {e.name}
                </span>
              </div>
            ))}
          </div>
          <div className="flex gap-2 items-center">
            <div className="uppercase">Cuisine:</div>
            <div>
              <select
                className="bg-black px-4 py-1 rounded-full text-[14px] uppercase outline-none"
                name="countries"
                id="countries"
                value={cuisine}
                onChange={(e) => setCuisine(e.target.value)}
              >
                <option value="">select cuisine</option>
                <option value="AUSTRALIAN">Australian</option>
                <option value="INDIAN">Indian</option>
                <option value="MEXICAN">Mexican</option>
                <option value="CHINESE">Chinese</option>
                <option value="ITALIAN">Italian</option>
                <option value="AMERICAN">American</option>
                <option value="CANADIAN">Canadian</option>
                <option value="FRENCH">French</option>
                <option value="SPANISH">Spanish</option>
                <option value="JAPANESE">Japanese</option>
                <option value="GERMAN">German</option>
                <option value="BRAZILIAN">Brazilian</option>
                <option value="SOUTH_AFRICAN">South African</option>
                <option value="RUSSIAN">Russian</option>
                <option value="BRITISH">British</option>
              </select>
            </div>
          </div>
          <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 flex gap-4">
            <button
              disabled={!desc || !button || textloading || !cuisine}
              onClick={handleGenerateButton}
              className="outline-none bg-[#cc7cc4] px-4 py-2 uppercase rounded-lg w-[100%]"
            >
              Generate
            </button>
          </div>
        </div>
      </div>
    </DesignQuest>
  );
};

export default DesignCulinaryWizardy;
