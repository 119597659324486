import React, { useContext, useEffect } from "react";
import { QuestContext } from "../context/questContext/QuestContext";
import DesignCars from "../screens/designcar/DesignCars";
import DesignSong from "../screens/designsong/DesignSong";
import DesignBlog from "../screens/designblog/DesignBlog";
import DesignCharacter from "../screens/designcharacter/DesignCharacter";
import DesignCulinaryWizardy from "../screens/designculinary/DesignCulinaryWizardy";
import DesignYoutube from "../screens/designyoutube/DesignYoutube";
import DesignCustomBuild from "../screens/desingcustombuild/DesignCustomBuild";
import DesignLogo from "../screens/designlogo/DesignLogo";
import DesignFashionBeauty from "../screens/fashion_beauty/DesignFashionBeauty";
import DesignCreateWebpage from "../screens/design_create_webpage/DesignCreateWebpage";
import { useParams } from "react-router-dom";
import { SET_SIGLE_MODAL } from "../context/questContext/action";
import DesignFreeStyleCoding from "../screens/designcoding/DesignFreeStyleCoding";

const SingleQuest = () => {
  const { quest_type } = useParams();
  const {
    questDispatch,
    questState: { singleModal },
  } = useContext(QuestContext);

  useEffect(() => {
    questDispatch({ type: SET_SIGLE_MODAL, payload: quest_type });
  }, []);
  return (
    <>
      {singleModal === "Design_A_Car" && <DesignCars />}
      {singleModal === "Design_Song" && <DesignSong />}
      {singleModal === "Create_Blog" && <DesignBlog />}
      {singleModal === "Design_Character" && <DesignCharacter />}
      {singleModal === "Create_Culinary_Wizardry" && <DesignCulinaryWizardy />}
      {singleModal === "Design_Youtube" && <DesignYoutube />}

      {singleModal === "Design_Custom_Build" && <DesignCustomBuild />}
      {singleModal === "Create_Logo" && <DesignLogo />}
      {singleModal === "Create_beauty_fashion" && <DesignFashionBeauty />}
      {singleModal === "Create_Webpage" && <DesignCreateWebpage />}
      {singleModal === "Create_Coding_Website" && <DesignFreeStyleCoding />}
    </>
  );
};

export default SingleQuest;
