import React, { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
const SearchInput = () => {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  // Update query state as user types
  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  // Function to handle search
  const handleSearch = () => {
    if (!query) {
      return;
    }
    console.log("Searching for:", query);
    // Navigate to the search page with query
    navigate(`/aisearch?search=${query}`);
    setQuery("")
  };

  // Handle Enter key press in the input
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action to avoid form submission
      handleSearch();
    }
  };

  return (
    <div className="relative lg:flex hidden bg-gray-400 bg-opacity-20 w-[300px] h-8">
      <input
        type="text"
        onChange={handleChange}
        value={query}
        onKeyPress={handleKeyPress} // Listen for Enter key press
        placeholder="Ask AI anything"
        className="h-full px-4 w-[90%] rounded-md text-[12px] font-bold text-white bg-transparent placeholder-white focus:outline-none"
      />
      {
        query?(
          <button
          onClick={()=>setQuery("")}
          className="absolute right-[2%] top-[25%] flex items-center justify-center px-4 text-white"
        >
          <IoMdClose size={20} />
        </button>
        ):( <button
          onClick={handleSearch}
          className="absolute right-[2%] top-[25%] flex items-center justify-center px-4 text-white"
        >
          <IoIosSearch size={15} />
        </button>)
      }
     
    </div>
  );
};

export default SearchInput;
