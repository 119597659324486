import { useContext, useState } from "react"
import { AuthContext } from "../context/AuthContext"
import api from "../api.js"
export const useCourses = () => {
    const { currentUser } = useContext(AuthContext)
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentUser.token}`,
    };
    const [loading, setLoading] = useState(false)
    const [courses, setCourses] = useState([])
    const fetchCourses = async () => {
        setLoading(true)
        try {
            const response = await api.get(`/course`, { headers })
            if (response && response.status === 200) {
                setCourses(response.data.courses)
                setLoading(false)
            }
        } catch (error) {
            console.log("Something went wrong");
        }
    }
    return { fetchCourses, loading, courses }
}