import React, { useContext, useState } from "react";
import ForgetToken from "../components/ForgetToken";
import { AuthContext } from "../context/AuthContext";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useForgetPassword } from "../hooks/useForgetPassword";
const ForgotPass = () => {
  const [email, setEmail] = useState("");
  const {forgetPassword,forgetLoading}=useForgetPassword()
  const searchQuery = useSearchParams()[0];

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
      await forgetPassword({email})
  };

  if (searchQuery.get("token")) {
    return <ForgetToken token={searchQuery.get("token")} />;
  }
  return (
    <div
      className="flex flex-col gap-4"
      style={{ fontFamily: "'Inter', sans-serif" }}
    >
      <span
        className="text-[1.5rem] font-bold mb-4 text-white"
        style={{ fontFamily: "Space Grotesk, sans-serif" }}
      >
        Enter your Verification Email
      </span>
      <input
        type="email"
        name="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="text-white text-[0.9375rem] leading-[1.875rem] shrink-0 w-[23.125rem] h-[3.125rem] rounded-[0.625rem] border border-white px-6 bg-gray-300 bg-opacity-25"
        style={{ fontFamily: "'Inter', sans-serif" }}
        placeholder="Enter Your Email"
      />

      <button disabled={!email} onClick={handleSubmit} className="text-white bg-gradient-to-b from-[#19788E] to-[#0A0601] h-[3.125rem] leading-[1.875rem] text-2xl rounded-[0.625rem]">
        Submit
      </button>
    </div>
  );
};

export default ForgotPass;
