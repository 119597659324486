import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";

import D3 from "../../assets/screens/custombuild/3d.webp";
import zoom from "../../assets/screens/custombuild/zoom.webp";
import ultra from "../../assets/screens/custombuild/ultra.webp";
import random from "../../assets/screens/custombuild/random.webp";
import text from "../../assets/screens/custombuild/text.webp";
import imagebox from "../../assets/screens/custombuild/imagebox.webp";
import { TbPlayerTrackNext, TbPlayerTrackPrev } from "react-icons/tb";
import {
  AiOutlineDownload,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from "react-icons/ai";
import { useNewGenerateImage } from "../../hooks/useNewGenerateImage";
import { SyncLoader } from "react-spinners";
import { useDownload } from "../../hooks/useDownload";
import DesignQuest from "../../layouts/DesignQuest";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import { useParams } from "react-router-dom";
const DesignCustomBuild = () => {
  const {quest_type}=useParams()
  const imgRef = useRef(null);
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  const { downloadFunction, downloading } = useDownload();
  const { fetchImage, imgloading, images, imgStatus } = useNewGenerateImage();
  const [index, setIndex] = useState(0);
  const [button, setButton] = useState([]);
  const { errorState } = useContext(ErrorContext);
  const [zoomed, setZoomed] = useState("");
  const [desc, setDesc] = useState("");
  const handleButton = (butt) => {
    if (button.includes(butt)) {
      setButton(button.filter((e) => e !== butt));
    } else {
      setButton([...button, butt]);
    }
  };
  const handleChangeImage = (item) => {
    if (item === "prev") {
      setIndex(index - 1);
    } else {
      setIndex(index + 1);
    }
  };
  const handleGenerate = async () => {
    const prompt = `create a image of ${button.join(
      ","
    )} and having description as ${desc} and make the zoom ${zoomed}`;
    await fetchImage({
      prompt,
      quest_type,
      width:
        imgDimensions.width % 8 === 0
          ? imgDimensions.width
          : imgDimensions.width + (imgDimensions.width % 8),
      height:
        imgDimensions.height % 8 === 0
          ? imgDimensions.height
          : (imgDimensions.height % 8) + imgDimensions.height,
      num: 5,
    });
    if (imgStatus) {
      setButton([]);
      setDesc("");
      setZoomed("");
    }
  };
  useEffect(() => {
    const currentImg = imgRef.current;

    const handleLoad = () => {
      if (currentImg) {
        const width = currentImg.offsetWidth;
        const height = currentImg.offsetHeight;
        setImgDimensions({ width, height });
      }
    };

    // If the image is cached and loads before the effect runs,
    // it won't trigger the "load" event. So, check if it's already loaded:
    if (currentImg && currentImg.complete) {
      handleLoad();
    } else if (currentImg) {
      currentImg.addEventListener("load", handleLoad);
      // Make sure to clean up the event listener
      return () => currentImg.removeEventListener("load", handleLoad);
    }
  }, [imgRef]);

  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
}, []);
  const modalStyle = {
    background: "linear-gradient(to bottom, #f9edea, #dfd1bd, #b3a598)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };
  return (
    <DesignQuest
      modalStyle={modalStyle}
      title="Build Your Custom Home and Buildings"
    >
      {errorState.errorModal && <LimitErrorModal />}
      <div
        style={{ zIndex: 10000 }}
        className="flex gap-10 justify-center items-center px-2 py-2 "
      >
        <div className="flex flex-col items-center pb-[4%] pt-4 w-[30%] h-[75vh] relative ">
          <div className="flex flex-col shadow-2xl bg-white items-center rounded-2xl w-[100%] pb-10 h-[60%] relative">
            <div className="flex w-full h-[50%] gap-2 items-center">
              <div className={`w-1/3 h-[100%] flex flex-col items-center `}>
                <img
                  src={zoom}
                  className={`${
                    zoomed !== "" ? "opacity-20" : ""
                  } w-full h-full object-contain`}
                  alt=""
                />
                <div style={{ zIndex: 1000 }} className="flex gap-4 mt-[-12%]">
                  <button
                    onClick={() => setZoomed("plus")}
                    className={`${
                      zoomed === "plus" ? "opacity-20" : ""
                    }text-black px-1 py-1 rounded-full bg-gray-200`}
                  >
                    {" "}
                    <AiOutlineZoomIn />{" "}
                  </button>
                  <button
                    onClick={() => setZoomed("minus")}
                    className={`${
                      zoomed === "minus" ? "opacity-20" : ""
                    }text-black px-1 py-1 rounded-full bg-gray-200`}
                  >
                    {" "}
                    <AiOutlineZoomOut />{" "}
                  </button>
                </div>
              </div>
              <div
                onClick={() => handleButton("Random")}
                className={`${
                  button.includes("Random") ? "opacity-20" : ""
                } w-1/3 h-[100%] flex flex-col items-center `}
              >
                <img
                  src={random}
                  className="w-full h-full object-contain"
                  alt=""
                />
                <div className="flex gap-2 text-sm text-black mt-[-20%]">
                  Random
                </div>
              </div>
              <div
                onClick={() => handleButton("3d")}
                className={`${
                  button.includes("3d") ? "opacity-20" : ""
                }  w-1/3 h-[100%] flex flex-col items-center `}
              >
                <img src={D3} className="w-full h-full object-contain" alt="" />
                <div className="flex gap-2 text-black text-sm mt-[-15%]">
                  3d
                </div>
              </div>
            </div>

            <div
              style={{ zIndex: 100000000 }}
              className="flex w-[80%] h-[50%] mt-[4%] gap-2 items-center"
            >
              <div
                style={{ zIndex: 10000000000 }}
                onClick={() => handleButton("Magic")}
                className={`${
                  button.includes("Magic") ? "opacity-20" : ""
                }  w-1/2 items-center flex flex-col`}
              >
                <img
                  src={imagebox}
                  className="w-full h-full object-contain"
                  alt=""
                />
                <div className="flex gap-2 text-black text-sm mt-[-20%]">
                  Magic
                </div>
              </div>
              <div
                onClick={() => handleButton("Ultra")}
                className={`${
                  button.includes("Ultra") ? "opacity-20" : ""
                } w-1/2 items-center flex flex-col`}
              >
                <img
                  src={ultra}
                  className="w-full h-full object-contain"
                  alt=""
                />
                <div className="flex gap-2 text-black text-sm mt-[-20%]">
                  Ultra
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ zIndex: 100000 }}
            className="w-[120%] h-[100%] mt-[-25%]"
          >
            <div className="relative">
              <img src={text} className="w-full  h-full" alt="" />
              <textarea
                value={desc}
                style={{ resize: "none" }}
                onChange={(e) => setDesc(e.target.value)}
                placeholder="Your title"
                className="font-bold w-[45%] px-2 py-2 text-[#5358a4] bg-transparent outline-none h-32 absolute top-[34%] left-[12%]"
              ></textarea>
            </div>
          </div>
          <div
            style={{ zIndex: 1000000 }}
            className="flex gap-4 items-center mt-[-25%]"
          >
            <button
              disabled={!button || !desc || imgloading}
              onClick={handleGenerate}
              style={{
                background: "linear-gradient(45deg, #78afb2, #FFFFFF, #78afb2)",
              }}
              className="uppercase  font-bold text-xl px-4 outline-none rounded-xl py-1 text-black"
            >
              Generate
            </button>
            {!imgloading && images?.generated_images && (
              <div className="flex items-center gap-2">
                <button
                  disabled={index === 0}
                  onClick={() => handleChangeImage("prev")}
                  className={`${
                    index === 0 ? "opacity-20" : ""
                  } font-blod px-2 py-2 bg-gray-300 rounded-full text-black`}
                >
                  {" "}
                  <TbPlayerTrackPrev />{" "}
                </button>
                <button
                  disabled={index === images?.generated_images?.length - 1}
                  onClick={() => handleChangeImage("next")}
                  className={`${
                    index === images?.generated_images?.length - 1
                      ? "opacity-20"
                      : ""
                  } font-blod px-2 py-2 bg-gray-300 rounded-full text-black`}
                >
                  <TbPlayerTrackNext />{" "}
                </button>
              </div>
            )}
          </div>
        </div>

        {/* right */}
        <div
          ref={imgRef}
          style={{
            background: "linear-gradient(45deg, #7175c2, #5f62a9, #4e507a)",
          }}
          className=" rounded-xl relative flex-shrink w-[35%]  h-[72vh] overflow-hidden"
        >
          {imgloading ? (
            <SyncLoader
              color="#36d7b7"
              style={{
                zIndex: 10000,
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                marginTop: "30px",
              }}
            />
          ) : (
            ""
          )}
          {!imgloading && images?.generated_images && (
            <img
              src={images?.generated_images?.[index]?.url}
              className="w-full h-full hover:scale-[1.2] object-cover transition-transform duration-300 ease-in-out"
              alt=""
            />
          )}
          <button
            disabled={downloading}
            onClick={() =>
              downloadFunction({
                imgUrl: images?.generated_images?.[index]?.url,
              })
            }
            style={{ zIndex: 10000000 }}
            className="absolute bottom-5 px-2 py-2 bg-gray-300 rounded-full right-5"
          >
            {!downloading ? (
              <AiOutlineDownload className="text-black" size={20} />
            ) : (
              <span className="text-black font-bold text-lg">...</span>
            )}
          </button>
        </div>
      </div>
    </DesignQuest>
  );
};

export default DesignCustomBuild;
