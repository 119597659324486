import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Import useParams from react-router-dom
import { SlClose } from "react-icons/sl";
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaHourglassHalf,
} from "react-icons/fa";
import { motion } from "framer-motion";
import { FaCheck } from "react-icons/fa"; // Import the check icon for completed steps
import "./Academics.css"; // Assuming you have a CSS file for custom styles
import { IoMdAdd } from "react-icons/io";
import { RiDivideFill, RiSubtractFill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";
import { FaEquals } from "react-icons/fa";

// Modal animation variants
const modalVariants = {
  hidden: { y: "100%", opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 0.5, ease: "easeOut" } },
  exit: {
    y: "100%",
    opacity: 0,
    transition: { duration: 0.5, ease: "easeIn" },
  },
};

const AcademicsModal = ({ setAcademicModal }) => {
  const { id: operation } = useParams(); // Extract 'operation' from URL parameters
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [timer, setTimer] = useState(60); // 1 minute countdown
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState("");
  const [message, setMessage] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [questionIndex, setQuestionIndex] = useState(1);
  const [countdown, setCountdown] = useState(null); // Reference for the timer

  // Function to calculate the correct answer based on the operation
  const calculateCorrectAnswer = () => {
    const [op] = operation.split("-");
    switch (op) {
      case "addition":
        return num1 + num2;
      case "subtraction":
        return num1 - num2;
      case "multiplication":
        return num1 * num2;
      case "division":
        return num2 !== 0 ? (num1 / num2).toFixed(2) : "undefined"; // Handle division by zero
      default:
        return num1 + num2; // Default to addition
    }
  };

  // Split the operation string to extract the mathematical operation part
  const getOperationSymbol = () => {
    const [op] = operation.split("-"); // Split by '-' and take the first part (e.g., 'addition' from 'addition-1')
    switch (op) {
      case "addition":
        return <IoMdAdd />;
      case "subtraction":
        return <RiSubtractFill />;
      case "multiplication":
        return <RxCross2 />;
      case "division":
        return <RiDivideFill />;
      default:
        return "+"; // Default to addition
    }
  };

  const operationSymbol = getOperationSymbol();

  // Function to generate random numbers based on the operation type
  const generateNumbers = () => {
    let newNum1 = Math.floor(Math.random() * 100); // Default random number
    let newNum2 = Math.floor(Math.random() * 100); // Default random number

    const [op] = operation.split("-");
    switch (op) {
      case "multiplication":
        // Ensure one number is <= 10 for multiplication
        newNum1 = Math.floor(Math.random() * 10) + 1; // Generate number <= 10
        newNum2 = Math.floor(Math.random() * 100); // Random number up to 100
        break;
      default:
        // Default random number generation for addition and subtraction
        newNum1 = Math.floor(Math.random() * 100);
        newNum2 = Math.floor(Math.random() * 100);
    }

    setNum1(newNum1);
    setNum2(newNum2);
    setTimer(60); // Reset the timer
  };

  // Start the quiz with random numbers
  useEffect(() => {
    generateNumbers();
    const newCountdown = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    setCountdown(newCountdown); // Save reference to stop timer later
    return () => clearInterval(newCountdown);
  }, []);

  // Function to handle answer checking
  const handleCheck = () => {
    clearInterval(countdown); // Stop the timer

    const correctAnswer = calculateCorrectAnswer(); // Use the utility function
    setCorrectAnswer(correctAnswer);

    if (parseFloat(userAnswer) === parseFloat(correctAnswer)) {
      setResult("Correct");
      setMessage("Great job! Your answer is correct.");
    } else {
      setResult("Wrong");
      setMessage(
        `Oops! The answer is incorrect. The correct answer is ${correctAnswer}.`
      );
    }
    setShowResult(true);
  };

  // Function to handle next question
  const handleNext = () => {
    setShowResult(false);
    setUserAnswer("");
    setQuestionIndex((prev) => prev + 1);
    generateNumbers();
    const newCountdown = setInterval(() => {
      setTimer((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    setCountdown(newCountdown); // Restart the timer
  };

  // When timer reaches zero, show timeout modal
  useEffect(() => {
    if (timer === 0 && !showResult) {
      clearInterval(countdown); // Stop the timer
      const correctAnswer = calculateCorrectAnswer(); // Use the utility function
      setCorrectAnswer(correctAnswer);
      setResult("Timeout");
      setMessage(`Time is up! The correct answer was ${correctAnswer}.`);
      setShowResult(true);
    }
  }, [timer, showResult, num1, num2, operationSymbol]);

  // Function to select the correct icon based on the result
  const getResultIcon = () => {
    if (result === "Correct")
      return <FaCheckCircle className="text-green-400 text-6xl mb-4" />;
    if (result === "Wrong")
      return <FaExclamationCircle className="text-red-400 text-6xl mb-4" />;
    if (result === "Timeout")
      return <FaHourglassHalf className="text-yellow-400 text-6xl mb-4" />;
    return null;
  };

  // Function to get the range of numbers for the step indicator
  const getStepRange = () => {
    const start = Math.floor((questionIndex - 1) / 5) * 5 + 1;
    const end = start + 4;
    return Array.from({ length: 5 }, (_, i) => start + i);
  };

  // Timer CSS classes
  const timerClass = timer < 10 ? "text-red-500 blink-animation" : "text-white";

  return (
    <>
      <div className="text-white bg-transparent flex flex-col gap-10 justify-between items-center rounded-lg shadow-lg w-full  h-[100%] relative">
        {/* Header */}
        <h2 className="quizHeader font-bold mb-6  mt-6">MATHS QUIZ</h2>
        <div className="glassmorphism bg-[#283347]  relative text-white h-[80%] w-full md:w-[80%] max-w-[1028px] flex flex-col items-center px-6 py-6 text-center text-2xl font-bold">
          <div className="timer absolute text-white px-4 py-2 rounded-full text-lg font-bold bg-[#FFC107] w-[200px] h-[60px] flex items-center gap-2 justify-center top-[-8%] left-1/2 transform -translate-x-1/2">
            <div className="w-[50%] flex justify-end">
              <span>TIMER: </span>
            </div>
            <div className="w-[50%] text-white flex items-center justify-start">
              <span className={`text-[22px] ${timerClass}`}>{`00:${timer
                .toString()
                .padStart(2, "0")}`}</span>
            </div>
          </div>
          <div className="board w-full  rounded-3xl border-[16px] border-[#5b1e1c] h-[100%] flex flex-col justify-between">
            {/* Question div */}
            <div className="flex px-4 py-2 w-full gap-6 h-[90%] items-center">
              <div className="w-[150px] h-full flex items-center">
                {/* Crystal Gradient Background for Question Index Box */}

                <div className="question-box flex items-center justify-center">
                  <span>Q{questionIndex}</span>
                </div>
              </div>

              <div className="flex gap-4 items-center w-full h-full ">
                <div className="input-glass flex justify-center items-center">
                  <span className="">{num1}</span>
                </div>
                <div className="signBox flex justify-center items-center">
                  <span>{operationSymbol}</span>
                </div>
                <div className="input-glass flex justify-center items-center">
                  <span className="">{num2}</span>
                </div>
                <div className="signBox flex justify-center items-center">
                  <span>
                    <FaEquals size={30} />
                  </span>
                </div>
                <div className="input-glass">
                  <input
                    type="number"
                    className="text-[30px] w-full h-full outline-none bg-transparent text-black px-4 py-4"
                    value={userAnswer}
                    onChange={(e) => setUserAnswer(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* Check button */}
            <div className="flex items-center justify-end w-full px-10 h-[30%]">
              <button
                onClick={handleCheck}
                className="checkButton bg-yellow-400 rounded-full flex items-center justify-center"
              >
                <span>Check</span>
              </button>
            </div>
          </div>

          {/* Step Indicator */}
          <div className="flex items-center justify-center mt-[5%] w-[80%]">
            {getStepRange().map((step, index) => (
              <React.Fragment key={step}>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "24px",
                  }}
                  className={`w-8 h-8 px-2 py-2  rounded-full flex items-center justify-center ${
                    step < questionIndex
                      ? "bg-green-500 text-white"
                      : "bg-[#D9D9D9] text-black"
                  }`}
                >
                  {step < questionIndex ? <FaCheck /> : step}
                </div>
                {index < 4 && (
                  <div
                    className={`w-[15%] h-[4px] ${
                      step < questionIndex ? "bg-green-500" : ""
                    }`}
                    style={
                      step >= questionIndex
                        ? {
                            background:
                              "linear-gradient(90deg, #FAFD51 0%, #ECE752 49%, #32D250 99.99%)",
                          }
                        : {}
                    }
                  ></div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      {/* Result Modal */}
      {showResult && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalVariants}
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex items-center justify-center"
        >
          <div className="text-white bg-[#1C2541] p-10 flex flex-col items-center rounded-lg shadow-lg">
            {getResultIcon()}
            <h2 className="text-4xl font-bold mb-4">{result}</h2>
            <p className="text-lg mb-6">{message}</p>
            <button
              onClick={handleNext}
              className="w-[150px] h-[60px] bg-yellow-400 rounded-full flex items-center justify-center uppercase"
            >
              Next
            </button>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default AcademicsModal;
