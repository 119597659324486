import React from 'react'
import { radialbg } from '../constants/styles'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link, Outlet } from 'react-router-dom';
import Livechat from '../components/Livechat';
const Schedule = () => {
    return (
        <div className="flex w-[97%] h-full rounded-[2rem] p-4" style={{ background: radialbg, fontFamily: "'Poppins',sans-serif" }}>
            <div className='h-fit p-4 rounded-[2rem]' style={{ background: "radial-gradient(77.86% 43.8% at 50% 50%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)" }}>
                <Calendar />
            </div>
            <div className="flex flex-col border-l-2 p-4 border-[#009580] gap-5 overflow-auto">
                <div className="flex justify-between items-center">
                    <span className=' text-xl font-medium'>My Schedule</span>
                    <button className='py-1 px-6 text-sm leading-normal bg-[#009580] border border-white rounded-lg'>Date &gt;</button>
                </div>
                <div className="flex gap-5 items-center">
                    <Link to="/schedule" className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "text-sm leading-normal font-medium text-white" : "text-sm leading-normal font-medium text-[#84818A]"}>Regular (4)</Link>
                    <Link to="/schedule/OneoOne" className='text-sm leading-normal font-medium text-[#84818A]'>One o One (2)</Link>
                </div>
                <Outlet />
                {/* <Livechat /> */}
            </div>
        </div>
    )
}

export default Schedule