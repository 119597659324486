import React, { useEffect, useState } from "react";
import DesignAcademics from "../layouts/DesignAcademics";
import BG from "../assets/academics/math/bg.webp";
import AcademicsModal from "../components/academicsComponent/AcademicsModal";

const AcademicsPage = () => {
  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
  }, []);

  const modalStyle = {
    backgroundImage: `url(${BG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };
  return (
    <DesignAcademics modalStyle={modalStyle} title="Design Your Character">
      <AcademicsModal />
    </DesignAcademics>
  );
};

export default AcademicsPage;
