// import React, { useState, useEffect } from "react";
// import io from "socket.io-client";
// import { ClipLoader } from "react-spinners";
// import { IoMdClose } from "react-icons/io"; // Importing the close icon

// const socket = io(process.env.REACT_APP_BASE_URL);

function LiveChatComponent({ setLiveChatModal }) {
  // const [message, setMessage] = useState("");
  // const [messages, setMessages] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   socket.on("chat message", (msg) => {
  //     setIsLoading(false);
  //     setMessages((messages) => [...messages, msg]);
  //   });
  // }, []);

  // const sendMessage = () => {
  //   if (message.trim() !== "") {
  //     setIsLoading(true);
  //     socket.emit("chat message", message);
  //     setMessage("");
  //   }
  // };

  return (
    <div></div>
  )
  // return (
  //   <div>
  //     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
  //       <div className="w-4/5 h-4/5 p-4 bg-white rounded shadow-md relative"> {/* Adjusted width and height */}
  //         <button onClick={() => setLiveChatModal(false)} className="absolute text-black top-2 right-2 p-2">
  //           <IoMdClose size={30}/>
  //         </button>
  //         <ul className="mb-4 overflow-y-auto h-5/6"> {/* Adjusted height for messages */}
  //           {messages.map((msg, index) => (
  //             <li key={index} className="p-2 break-words">
  //               {msg}
  //             </li>
  //           ))}
  //         </ul>
  //         <div className="flex">
  //           <input
  //             className="flex-1 p-2 border border-gray-300 rounded-l"
  //             value={message}
  //             onChange={(e) => setMessage(e.target.value)}
  //           />
  //           <button
  //             className="px-4 bg-blue-500 text-white rounded-r"
  //             onClick={sendMessage}
  //           >
  //             Send
  //           </button>
  //         </div>
  //         {isLoading && (
  //           <div className="mt-2 flex justify-center">
  //             <ClipLoader color="#60A5FA" />
  //           </div>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // );
}

export default LiveChatComponent;
