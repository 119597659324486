import React, { useState, useEffect, useRef } from "react";
import styles from "../styles/VideoModal.module.css";
import { motion } from "framer-motion";
import { SlClose } from "react-icons/sl";
import VideoImageImports from "../assets";
import { usePostVideoModal } from "../hooks/usePostVideoModal";
import useOverItem from "../hooks/useOverItem";
import { SET_VIDEO_MODAL } from "../context/overviewcontext/action";
const VideoModal = ({ isOpen, videoSrc }) => {
  const [canClose, setCanClose] = useState(false);
  const [letStart, setLetStart] = useState(false);
  const videoRef = useRef(null);
  const { postVideoModal } = usePostVideoModal();
  const { state, dispatch } = useOverItem();
  const onClose = async () => {
    let data = {
      name: videoSrc,
      isCompleted: true,
      video_count: 1,
    };

    dispatch({ type: SET_VIDEO_MODAL, payload: data });

    await postVideoModal(data);
  };

  useEffect(() => {
    let timer;
    if (letStart) {
      timer = setTimeout(() => {
        setCanClose(true);
      }, 15000); // 15 seconds
    }

    return () => clearTimeout(timer);
  }, [letStart]);

  useEffect(() => {
    if (letStart && videoRef.current) {
      videoRef.current.play();
    }
  }, [letStart]);

  const handleStart = () => {
    setLetStart(true);
  };
  const modalVariants = {
    hidden: { y: "-100vh", opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    exit: { y: "-100vh", opacity: 0, transition: { duration: 1 } },
  };
  return (
    <motion.div
      style={{ zIndex: 100000000 }}
      initial="hidden"
      animate={isOpen ? "visible" : "exit"}
      variants={modalVariants}
      className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center`}
    >
      {!letStart && (
        <div className="absolute z-20 w-full h-full flex items-center justify-center bg-black bg-opacity-60">
          <button
            className="bg-pink-500 text-white text-xl px-2 py-2 md:text-4xl font-bold md:py-4 md:px-8 rounded-xl shadow-lg hover:bg-pink-600 transition duration-300"
            onClick={handleStart}
          >
            Let's Start
          </button>
        </div>
      )}
      <div className="text-black bg-black p-4 flex flex-col justify-center items-center md:rounded-lg md:shadow-lg w-full h-full  md:h-[90%] md:w-[90%]">
        {canClose && (
          <button
            className="text-white absolute top-[5%] right-[10%] cursor-pointer"
            onClick={onClose}
          >
            <SlClose size={30} />
          </button>
        )}
        <div className="relative mx-auto p-2 md:p-5 border w-full md:w-[80%] md:shadow-lg md:rounded-md bg-white">
          <video ref={videoRef} width="100%" controls>
            <source
              src={VideoImageImports.introVideos[videoSrc]}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>

          <SkipButton canSkip={canClose} onClose={onClose} />
        </div>
      </div>
    </motion.div>
  );
};

const SkipButton = ({ canSkip,onClose }) => {
  return (
    <button
      disabled={!canSkip}
      onClick={onClose}
      className={`relative flex justify-center items-center mt-4 p-2 px-8 rounded-lg md:rounded-3xl text-white ${
        canSkip ? "bg-black" : "bg-gray-300"
      }`}
    >
      {!canSkip && (
        <svg width="40" height="40" className="absolute top-0 left-[30%]">
          <circle
            className={!canSkip ? styles.timerCircle : ""}
            cx="20"
            cy="20"
            r="18"
            stroke="white"
            strokeWidth="4"
            fill="none"
          />
        </svg>
      )}
      {canSkip ? "SKIP" : "Wait..."}
    </button>
  );
};

export default VideoModal;
