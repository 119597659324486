const VideoImageImports = {
  quest_type_obj: {
    Create_Blog: {
      animate: require("./screens/dance.webp"),
      box: require("./screens/questbox/blogbox.webp"),
      bg: require("./screens/questbox/blogbg.webp"),
      extra: {
        a: require("./screens/questbox/blogextra.webp"),
        a1: true,
      },
      beginbutton: { bottom: "25%" },
      top: "35%",
      badge_image:require("./badge_images/blog_writing.webp")
    },
    AI_story_Telling: {
      animate: require("./screens/roboquest/robo.webp"),
      box: require("./screens/questbox/storybox.webp"),
      bg: require("./screens/questbox/characterbg.webp"),
      extra: {},
      badge_image:require("./badge_images/story_writing.webp")
    },
    Design_A_Car: {
      animate: require("./screens/dance.webp"),
      box: require("./screens/questbox/designcarbox.webp"),
      bg: require("./screens/questbox/characterbg.webp"),
      extra: {},
      badge_image:require("./badge_images/car_designing.webp")
    },
    Design_Character: {
      animate: require("./screens/roboquest/robo.webp"),
      box: require("./screens/questbox/characterbox.webp"),
      bg: require("./screens/questbox/characterbg.webp"),
      extra: {},
      badge_image:require("./badge_images/avatar_boy.webp")
    },
    Design_Youtube: {
      animate: require("./screens/dance.webp"),
      box: require("./screens/questbox/youtubebox.webp"),
      bg: require("./screens/questbox/youtubebg.webp"),
      beginbutton: { bottom: "25%" },
      extra: {},
      badge_image:require("./badge_images/yt_batch.webp"),
      items:{
        story:require("./screens/youtubescreen/items/story.webp"),
        artistic:require("./screens/youtubescreen/items/artistic.webp"),
        travel:require("./screens/youtubescreen/items/travel.webp"),
        comedy:require("./screens/youtubescreen/items/comedy.webp"),
        shopping:require("./screens/youtubescreen/items/shopping.webp"),
        fitness:require("./screens/youtubescreen/items/fitness.webp"),
        podcast:require("./screens/youtubescreen/items/podcast.webp"),
        event:require("./screens/youtubescreen/items/event.webp"),
    
      }
    },
    Design_Song: {
      animate: require("./screens/roboquest/robo.webp"),
      box: require("./screens/questbox/songbox.webp"),
      bg: require("./screens/questbox/songbg.webp"),
      beginbutton: { bottom: "25%" },
      extra: {},
      badge_image:require("./badge_images/song_writing.webp"),
      items:{
        jazz:require("./screens/designsongitems/jazz.webp"),
        hiphoop:require("./screens/designsongitems/hiphop.webp"),
        guitar:require("./screens/designsongitems/guitar.webp"),
        musicnotes:require("./screens/designsongitems/musicnotes.webp"),

      }
    },
    Design_Custom_Build: {
      animate: require("./screens/dance.webp"),
      box: require("./screens/questbox/custombuildbox.webp"),
      bg: require("./screens/questbox/blogbg.webp"),
      extra: {
        a: require("./screens/questbox/blogextra.webp"),
        a1: true,
      },
      beginbutton: { bottom: "28%" },
      top: "35%",
      badge_image:require("./badge_images/architect_playground.webp")
    },
    Create_Culinary_Wizardry: {
      animate: require("./screens/questbox/culinary/robo.webp"),
      box: require("./screens/questbox/culinary/culinarybox.webp"),
      bg: require("./screens/questbox/culinary/culinarybg.webp"),
      extra: {
        a: require("./screens/questbox/culinary/extra1.webp"),
        left: "40%",
        bottom: "10%",
        a1: true,
      },
      beginbutton: { bottom: "28%" },
      top: "35%",
      badge_image:require("./badge_images/health_batch.webp")
    },
    Create_Logo: {
      animate: require("./screens/questbox/culinary/robo.webp"),
      box: require("./screens/questbox/logo/logobox.webp"),
      bg: require("./screens/questbox/logo/logobg.webp"),
      extra: {
        a: require("./screens/questbox/culinary/extra1.webp"),
        left: "40%",
        bottom: "10%",
        a1: true,
        
      },
      beginbutton: { bottom: "28%" },
      top: "35%",
      badge_image:require("./badge_images/logo_batch.webp")
    },
    Create_beauty_fashion: {
      bg: require("./screens/beautyfashion/insidebox/bg.webp"),
      video_url: require("./screens/beautyfashion/bg.mp4"),
      insideBox: {
        background: require("./screens/beautyfashion/insidebox/bg.webp"),
        aesthetic: require("./screens/beautyfashion/fashionstudio/astetic_icon.webp"),
        cool: require("./screens/beautyfashion/fashionstudio/cool_icon.webp"),
        description: require("./screens/beautyfashion/fashionstudio/description_hat.webp"),
        female: require("./screens/beautyfashion/fashionstudio/female_icon.webp"),
        history: require("./screens/beautyfashion/fashionstudio/history_icon.webp"),
        male: require("./screens/beautyfashion/fashionstudio/male_icon.webp"),
        ultradetail: require("./screens/beautyfashion/fashionstudio/ultra_detailed_icon.webp"),
      },
      badge_image:require("./badge_images/fashion_design.webp")
    },
    Create_Webpage: {
      // z-10 absolute left-[-20%] top-[10%] w-[80vw] h-[80vh]
      animate: require("./screens/questbox/webpagedesing/animate.webp"),
      screen: {
        bg1: require("./screens/webpage/bglines.webp"),
        bg2: require("./screens/webpage/bgtiles.webp"),
        items: {
          item1: require("./screens/webpage/items/item1.webp"),
          item2: require("./screens/webpage/items/item2.webp"),
          item3: require("./screens/webpage/items/item3.webp"),
          item4: require("./screens/webpage/items/item4.webp"),
          item5: require("./screens/webpage/items/item5.webp"),
          item6: require("./screens/webpage/items/item6.webp"),
          item7: require("./screens/webpage/items/item7.webp"),
          item8: require("./screens/webpage/items/item8.webp"),
          item9: require("./screens/webpage/items/item9.webp"),
          item10: require("./screens/webpage/items/item10.webp"),
          item11: require("./screens/webpage/items/item11.webp"),
          item12: require("./screens/webpage/items/item12.webp"),
          item13: require("./screens/webpage/items/item13.webp"),
          item14: require("./screens/webpage/items/item14.webp"),
          item15: require("./screens/webpage/items/item15.webp"),
          
        },
        title:require("./screens/webpage/title.webp")
      },
      animateDimensions: {
        width: "80vw",
        height: "80vh",
        left: "-20%",
        top: "15%",
      },
      boxDimensions: {},
      box: require("./screens/questbox/webpagedesing/webpagebox.webp"),
      bg: require("./screens/questbox/webpagedesing/webpagebg.webp"),
      beginbutton: { bottom: "25%" },
      extra: {},
      badge_image:require("./badge_images/website_icon.webp")
    },
    Create_Coding_Website: {
      animate: require("./screens/roboquest/robo.webp"),
      box: require("./screens/questbox/storybox.webp"),
      bg: require("./screens/questbox/characterbg.webp"),
      extra: {},
      badge_image:require("./badge_images/aifreestylecoding.webp")
    },
    singup:{
      badge_image:require("./badge_images/singup.webp")
    }
   
  },

  introVideos: {
    dashboard: require("./video/dashboard.mp4"),
    freestylecoding: require("./video/freestylecoding.mp4"),
    leaderboard: require("./video/leaderboard.mp4"),
    aiteacher: require("./video/aiteacher.mp4"),
  },
  academics:{
    math:{
      addition:{
        image:require("./academics/math/addition.webp")
      }
    }
  }
 
};

export default VideoImageImports;
