import React, { useContext, useEffect, useState } from "react";
import { textcolor } from "../constants/styles";
import { GoShare } from "react-icons/go";
import profile from "../assets/images/userimg.webp";
import { IoHeart } from "react-icons/io5";
import { IoIosHeartEmpty } from "react-icons/io";
import { FaRegTrashAlt } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FeedContext } from "../context/feedcontext/FeedContext";
import { useFeed } from "../hooks/useFeed";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import useOverItem from "../hooks/useOverItem";
import { UseCopyText } from "../hooks/useCopyText";
import { FiCopy } from "react-icons/fi";
import ShareButton from "../components/ShareButton";
import MobileError from "../components/mobile/MobileError";
const MyFeed = () => {
  const { currentUser } = useContext(AuthContext);
  const [shareButton, setShareButton] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [promptQuote, setPromptQuote] = useState("");
  const [privateArray, setPrivateArray] = useState([]);
  const { state } = useOverItem();
  const navigate = useNavigate();
  const { copyText } = UseCopyText();
  const { feedState } = useContext(FeedContext);
  const {
    fetchOwnFeed,
    loading,
    likeFeeds,
    deleteFeed,
    deleteLoading,
    postStatusUpdateFeed,
    statusLoading,
  } = useFeed();
  useEffect(() => {
    if (!feedState?.feeds?.length || feedState?.getFeed) {
      fetchOwnFeed();
    }
  }, [feedState?.getFeed]);

  const handleLike = async (id) => {
    await likeFeeds({ id });
  };

  const handleFeedDelete = async (id) => {
    await deleteFeed({ id });
  };

  const handleAdminFeed = (id, status) => {
    if (state.overview.role === "admin" && status === "private") {
      if (privateArray.includes(id)) {
        setPrivateArray(privateArray.filter((e) => e !== id));
        return;
      }
      setPrivateArray([...privateArray, id]);
      console.log(privateArray);
    }
  };
  const handleStatusPublic = async () => {
    await postStatusUpdateFeed({
      ids: privateArray,
    });
    setPrivateArray([]);
  };

  const handleShare = (e) => {
    setShareButton(true);
    setImageUrl(e.image ? e.image : window.location.href);
    setPromptQuote(
      e.text
        ? `Prompt: ${e.userPrompt}\n\n Answer: ${e.text}`
        : `Prompt: ${e.userPrompt}`
    );
  };
  return (
    <>
      <div
        style={{
          fontFamily: "'Inter',sans-serif",
        }}
        className="md:flex flex-col items-center h-full"
      >
        {shareButton && (
          <ShareButton
            quote={promptQuote}
            closeModal={() => setShareButton(false)}
            imageUrl={imageUrl}
          />
        )}

        {state.overview.role === "admin" && privateArray.length > 0 && (
          <button
            onClick={handleStatusPublic}
            disabled={statusLoading}
            className="fixed right-[10%] top-[20%] bg-red-600 text-white px-2 py-2 rounded-xl"
          >
            {statusLoading ? "Loading..." : "Make All Public"}
          </button>
        )}
        <div className="flex pt-4 md:pt-0 flex-col h-full w-full lg:w-[70%]">
          <div className="flex px-4 justify-start md:px-0">
            <h1 className="text-[20px]">Feed</h1>
          </div>
          <div className="text-[12px] md:text-[14px] text-[#706a93] font-semibold px-4 md:px-0">
            See all the creations published by other learners like you!
          </div>

          <div className="h-full pb-2 scroll-smooth overflow-auto flex flex-col items-center mt-2">
            <div className="flex gap-4 w-[90%] flex-wrap">
              {loading && (
                <SyncLoader
                  color="#36d7b7"
                  style={{
                    zIndex: 10000,
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                    marginTop: "30px",
                  }}
                />
              )}
              {!loading && feedState.feeds?.length === 0 && (
                <div className="flex justify-center items-center text-[30px] w-full h-full font-bold">
                  <span>--Not Enough Data--</span>
                </div>
              )}
              {!loading &&
                feedState.feeds?.map((e) => (
                  <div
                    onClick={() => handleAdminFeed(e._id, e.postStatus)}
                    key={e._id}
                    className={`${
                      privateArray.includes(e._id) ? "opacity-40" : ""
                    } flex  flex-col gap-4 px-4 py-4 pb-6 shadow-2xl bg-black bg-opacity-30 w-full md:w-[48%] h-[500px] rounded-2xl`}
                  >
                    {/* image div */}

                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <div className="">
                          <img
                            src={e.profilePhoto ? e.profilePhoto : profile}
                            className="w-12 h-12 rounded-full"
                            alt=""
                          />
                        </div>
                        <div className="flex flex-col">
                          <div className="">
                            <h1 className="text-white text-[14px]">
                              {e?.username?.length > 15
                                ? e?.username.substring(0, 15) + "..."
                                : e?.username}
                            </h1>
                          </div>
                          <div
                            className={`text-[${textcolor}] text-[12px] font-bold`}
                          >
                            {e.xp * 10} XP
                          </div>
                        </div>
                      </div>
                      {/* time div */}
                      <div className="text-gray-400 text-[12px] font-bold flex gap-2 items-center">
                        {(e.email === currentUser?.email ||
                          state.overview.role === "admin") && (
                          <button
                            disabled={deleteLoading}
                            onClick={() => handleFeedDelete(e._id)}
                            className="text-blue-500"
                          >
                            <FaRegTrashAlt size={15} />
                          </button>
                        )}

                        <span>1 min ago</span>
                      </div>
                    </div>
                    {/* body div */}
                    <div className="text-[12px] flex gap-4 items-center text-gray-400 overflow-auto">
                      {e?.userPrompt.length > 50
                        ? e?.userPrompt.substring(0, 50) + "..."
                        : e?.userPrompt}
                      <button
                        onClick={() => copyText(e?.userPrompt)}
                        style={{ border: "1px solid black" }}
                        className="flex items-center justify-center rounded-full w-8 h-8 bg-black outline-none"
                      >
                        <FiCopy size={15} />
                      </button>
                    </div>
                    {e.text && (
                      <div className="flex-1 overflow-hidden  bg-[#302a4e] bg-opacity-50 shadow-2xl px-6 py-4 rounded-xl">
                        <div
                          style={{
                            lineHeight: "1.8",
                            whiteSpace: "pre-wrap",
                            fontSize: "16px",
                            color: "#ccc",
                          }}
                          className="h-[99%] overflow-auto text-[16px] text-gray-300"
                        >
                          {e.text
                            .split("\n")
                            .slice(1)
                            .map((line) => line.trimStart())
                            .join("\n")}
                        </div>
                      </div>
                    )}
                    {e.image && (
                      <div className="h-[65%]">
                        <LazyLoadImage
                          alt="Background"
                          threshold={150} // This is where you specify the threshold
                          src={e.image} // Your dynamic background image source
                          effect="blur"
                          className="object-fit rounded-[5%]  w-full h-full"
                          wrapperClassName="w-full h-full rounded-[5%] object-fit"
                        />
                      </div>
                    )}

                    <div className="flex justify-between items-center">
                      <button
                        onClick={() => navigate(`/quest/${e.title}`)}
                        className="rounded-2xl hidden md:flex justify-center items-center w-32 h-8 py-2 text-gray-400 text-[12px] border-2 border-gray-400"
                      >
                        <span>Generate Yours</span>
                      </button>
                      {state.overview?.role === "admin" && (
                        <div className="text-gray-300 text-[14px] rounded-xl px-2 py-2 uppercase bg-black bg-opacity-70">
                          {e.postStatus}
                        </div>
                      )}

                      <div className="flex items-center gap-4 pr-4">
                        <button
                          onClick={() => handleShare(e)}
                          className="flex items-center text-gray-400"
                        >
                          <GoShare size={20} />
                        </button>

                        <button
                          onClick={() => handleLike(e._id)}
                          className="flex gap-1 items-center"
                        >
                          {e.isLike ? (
                            <IoHeart size={20} className="text-red-500" />
                          ) : (
                            <IoIosHeartEmpty
                              size={20}
                              className="text-red-500"
                            />
                          )}

                          <span className="text-[14px] text-gray-200">
                            {e.likeCount}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyFeed;
