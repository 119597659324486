import React, { useRef } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import { DATA } from "../../constants/data";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaUserPlus } from "react-icons/fa6";
import VideoImageImports from "../../assets";
import { textcolor } from "../../constants/styles";
import styles from "../../styles/academics/ListItem.module.css"
import { useNavigate } from "react-router-dom";
const ListItem = ({ subject,setAcademicModal }) => {
  const { academics } = VideoImageImports;
  const navigate=useNavigate()
  const arrowRef = useRef(null);
  const settings = {
    infinite: true,
    // lazyLoad: true,
    speed: 500,
    slidesToShow: 3,

    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    // afterChange: (index) => setImageIndex(index),

    dots: false,

    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,

          centerMode: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  // const { state } = useOverItem();
  // const { quest_type_obj } = VideoImageImports;

  const handlego=(title)=>{
    navigate(`/academics/${title}-1`)
  }
  return (
    <div className="relative w-full h-full">
      <Slider ref={arrowRef} {...settings} className="flex gap-2">
        {DATA?.math?.map((img, idx) => (
          <div
            style={{
              fontFamily: "'Fredoka One', cursive", // Applying a playful font
            }}
            className={`relative flex flex-col bg-[#2c2654] shadow-xl rounded-[5%] h-[245px] w-full`}
          >
            <LazyLoadImage
              alt="Background"
              threshold={150}
              src={academics?.[subject]?.[img.img]?.image}
              effect="blur"
              className="object-cover rounded-[5%] opacity-30 absolute inset-0 w-full h-full"
              wrapperClassName="w-full h-full rounded-[5%] opacity-30 absolute top-0 left-0"
            />
            <div className="absolute right-0 gap-1 px-2 text-gray-400 top-5 flex items-center w-[100px] h-[30px] bg-gray-200 rounded-bl-xl rounded-tl-xl">
              <FaUserPlus size={15} />
              <span className="text-[12px]">1.3k</span>
              <span className="text-[12px]">active</span>
            </div>
            <div className="w-full h-full flex flex-col items-center justify-center gap-4">
              <div className={`uppercase text-[50px] text-white tracking-widest animate-bounce ${styles.animateWiggle}`}>
                {img.title}
              </div>
              <button
              onClick={()=>handlego(img.title)}
                className={`w-[30%] z-50 items-center justify-center  flex px-2 py-2 h-[50px] rounded-full bg-[#2c2654] text-[20px] text-white font-bold`}
              >
                <span>Let's Go</span>
              </button>
            </div>
          </div>
        ))}
      </Slider>
      <div class="h-8 w-full justify-between  cursor-pointer text-[white] border-none flex  absolute top-[45%]">
        <button
          onClick={() => arrowRef.current.slickPrev()}
          className="rounded-full w-12 h-12 px-2 py-2 bg-black bg-opacity-70 flex justify-center items-center"
        >
          <IoIosArrowBack />
        </button>
        <button
          onClick={() => arrowRef.current.slickNext()}
          className="rounded-full w-12 h-12 px-2 py-2 bg-black bg-opacity-70 flex justify-center items-center"
        >
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  );
};

export default ListItem;
