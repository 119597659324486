import React, { useEffect } from 'react'
import DataTable from '../components/DataTable'
import { useAnalytics } from '../hooks/useAnalytics'
const Analytics = () => {

  const {data,loading,analyticsData}=useAnalytics()
  useEffect(()=>{
  analyticsData()
  },[])
  if(loading){
    return (
      <div>Loading...</div>
    )
  }
  return (
    <div className="container mx-auto p-4">
      <DataTable data={data} />
    </div>
  )
}

export default Analytics