import { createContext, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
};
//
export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  // const getPaymetStatus = async () => {
    
  //  dispatch({type:"SET_LOADING",payload:true})
  //   try {
  //     let result = await api.get("/check_payment_status", {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${state.currentUser?.token}`,
  //       },
  //     });
  //     if (result.status === 200) {
  //       dispatch({type:"PAYMENT_STATUS",payload:{
  //         payment_plan: result.data.payment_plan,
  //         paymentStatus: result.data.paymentStatus,
  //       }});
  //     }
  //   } catch (err) {
  //     console.log(err)
  //   }
  //   dispatch({type:"SET_LOADING",payload:false})
  // };
  // useEffect(() => {
  //   getPaymetStatus();
  // }, [state.currentUser?.token]);
 
 
  return (
    <AuthContext.Provider value={{ currentUser: state.currentUser, dispatch }}>
      {
        children
      }
    </AuthContext.Provider>
  );
};
