import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import api from "../api.js";
import { SET_QUEST_PROMPT } from "../context/questContext/action.js";
import { QuestContext } from "../context/questContext/QuestContext.js";
import { FeedContext } from "../context/feedcontext/FeedContext.js";
import {
  SET_FEED_LIST,
  SET_GET_FEED,
  SET_SINGLE_DELETE_LIST,
  SET_UPDATE_LIST,
} from "../context/feedcontext/action.js";
export const useFeed = () => {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [otherFeeds, setOtherFeeds] = useState([]);
  const [postSent, setPostSent] = useState(false);
  const { feedDispatch } = useContext(FeedContext);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser.token}`,
  };
  const success = (message) => toast.success(message);
  const fetchOwnFeed = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/ownfeed`, { headers });
      if (response && response.status === 200) {
        feedDispatch({ type: SET_FEED_LIST, payload: response.data.feeds });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Something went wrong");
    }
  };
  const fetchOtherFeeds = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/otherfeed`, { headers });
      if (response && response.status === 200) {
        setLoading(false);
        setOtherFeeds(response.data.feeds);
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  };
  const likeFeeds = async ({ id }) => {
    try {
      feedDispatch({ type: SET_UPDATE_LIST, payload: { id } });
      const response = await api.get(`/feed/likes/${id}`, { headers });
      if (response.status === 201) {
       console.log(response.data)
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  };
  const deleteFeed = async ({ id }) => {
    setDeleteLoading(true);
    try {
      const response = await api.delete(`/ownfeed/${id}`, { headers });
      if (response.status === 200) {
        feedDispatch({ type: SET_SINGLE_DELETE_LIST, payload: { id } });

        success(response.data.message || "Feed posted Successfully");
      }
    } catch (error) {
      console.log("Something went wrong", error);
      toast.error(error.response.data.message || "Failed to delete feed", {
        position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
        autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
        hideProgressBar: true, // Hide the progress bar
      });
    }
    setDeleteLoading(false);
  };
  const postFeed = async (body) => {
    setPostSent(true);
    try {
      const response = await api.post(`/ownfeed`, body, { headers });
      if (response && response.status === 201) {
        setPostSent(false);

        success("Feed posted Successfully");
      }
    } catch (error) {
      setPostSent(false);
      console.log("Something went wrong");
    }
  };
  const postStatusUpdateFeed = async (body) => {
    setStatusLoading(true);
    try {
      const response = await api.post(`/ownfeed/status`, body, { headers });
      if (response.status === 200) {
        success(response.data.message);
        feedDispatch({
            type:SET_GET_FEED,
            payload:true
        })
        setStatusLoading(false);
      }
    } catch (error) {
      setStatusLoading(false);
      console.log("Something went wrong", error.response.data.message);
    }
  };
  return {
    fetchOwnFeed,
    loading,
    fetchOtherFeeds,
    otherFeeds,
    postFeed,
    postSent,
    likeFeeds,
    deleteFeed,
    deleteLoading,
    postStatusUpdateFeed,
    statusLoading
  };
};
