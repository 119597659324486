import React from 'react'
import { Formik } from 'formik';
import { changepasswordschema } from '../constants/validationschema';
import { useProfile } from '../hooks/useProfile';
import ClipLoader from "react-spinners/ClipLoader";
const PasswordSettings = () => {
    const { changePassword, loading } = useProfile()
    return (
        <div className="flex flex-col px-8 py-6 gap-7">
            <div className="flex flex-col">
                <div className="text-2xl font-bold">Password</div>
                <div className="text-sm font-normal">
                    Please enter your current password to change your password.
                </div>
            </div>
            <Formik
                initialValues={{
                    currentpassword: '',
                    newpassword: '',
                    confirmpassword: ''
                }}
                validationSchema={changepasswordschema}
                onSubmit={async (values) => {
                    const { currentpassword, newpassword, confirmpassword } = values
                    await changePassword(currentpassword, newpassword, confirmpassword)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldTouched
                }) => (
                    <form className='flex flex-col gap-2' onSubmit={handleSubmit}>
                        <div className="flex gap-[2rem] items-center">
                            <div className="w-[11.75rem] text-[#009580] font-medium text-base ">Current password</div>
                            <input
                                type="password"
                                className="w-[18.875rem] h-[3rem] flex-shrink-0 rounded-[0.74rem] border border-solid border-[#009580] p-3"
                                style={{
                                    background:
                                        "radial-gradient(77.86% 43.80% at 50.00% 50.00%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)",
                                }}
                                onChange={handleChange}
                                onBlur={() => setFieldTouched('currentpassword')}
                                name='currentpassword'
                                id='currentpassword'
                                value={values.currentpassword}
                            />
                            {touched.currentpassword && errors.currentpassword && (
                                <span className='text-[#ff0000] font-normal'>{errors.currentpassword}</span>
                            )}
                        </div>
                        <div className="flex gap-[2rem] items-center">
                            <div className="w-[11.75rem] text-[#009580] font-medium text-base ">New password</div>
                            <input
                                type="password"
                                className="w-[18.875rem] h-[3rem] flex-shrink-0 rounded-[0.74rem] border border-solid border-[#009580] p-3"
                                style={{
                                    background:
                                        "radial-gradient(77.86% 43.80% at 50.00% 50.00%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)",
                                }}
                                onChange={handleChange}
                                onBlur={() => setFieldTouched('newpassword')}
                                name='newpassword'
                                id='newpassword'
                                value={values.newpassword}
                            />
                            {touched.newpassword && errors.newpassword && (
                                <span className='text-[#ff0000] font-normal'>{errors.newpassword}</span>
                            )}
                        </div>
                        <div className="flex gap-[2rem] items-center">
                            <div className="w-[11.75rem] text-[#009580] font-medium text-base ">Confirm New password</div>
                            <input
                                type="password"
                                className="w-[18.875rem] h-[3rem] flex-shrink-0 rounded-[0.74rem] border border-solid border-[#009580] p-3"
                                style={{
                                    background:
                                        "radial-gradient(77.86% 43.80% at 50.00% 50.00%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)",
                                }}
                                onChange={handleChange}
                                onBlur={() => setFieldTouched('confirmpassword')}
                                name='confirmpassword'
                                id='confirmpassword'
                                value={values.confirmpassword}
                            />
                            {touched.confirmpassword && errors.confirmpassword && (
                                <span className='text-[#ff0000] font-normal'>{errors.confirmpassword}</span>
                            )}
                        </div>
                        <div className='flex gap-5 my-6 w-[82%] justify-end'>
                            <button className='w-[9.38rem] h-[3.13rem] rounded-[0.625rem] bg-transparent  border border-solid border-[#fff] text-xl font-normal not-italic flex items-center justify-center'>Cancel</button>
                            <button type='submit' className='w-[9.38rem] h-[3.13rem] rounded-[0.625rem] text-xl font-normal not-italic flex items-center justify-center' style={{ background: " linear-gradient(180deg, #19788E 0%, #0A0601 100%)" }}>{loading ? <ClipLoader color="#36d7b7" /> : "Save"}</button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}

export default PasswordSettings