import { Formik } from "formik";
import React from "react";
import { signupschema } from "../constants/validationschema";
import { useSignup } from "../hooks/useSignup";
import { ClipLoader } from "react-spinners";
import { CountryDropdown } from "react-country-region-selector";

const Signup = () => {
  const { signup, loading } = useSignup();
  // const [bannerText, setBannerText] = useState('');
  // const [isVisible, setIsVisible] = useState(false);

  // useEffect(() => {
  //   window.rewardful('ready', () => {
  //     if(window.Rewardful.affiliate) {
  //       setBannerText(`Hi there, friend of ${window.Rewardful.referral}!`);
  //       setIsVisible(true);
  //     }
  //   });
  // }, []); // The empty array ensures this effect runs only once after the initial render
  // const signupFormRef = useRef(null);

  // useEffect(() => {
  //   if (signupFormRef.current) {
  //     window.Rewardful.Forms.add(signupFormRef.current);
  //   }
  // }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <div
      className="flex flex-col gap-4 justify-center items-center w-full h-full px-6"
      style={{ fontFamily: "'Inter', sans-serif" }}
    >
      <span
        className="text-[2.5rem] font-normal mb-4 text-white text-center"
        style={{ fontFamily: "Space Grotesk, sans-serif" }}
      >
        Sign Up
      </span>
 
      <Formik
      className=""
        initialValues={{
          username: "",
          email: "",
          password: "",
          confirmpassword: "",
        }}
        validationSchema={signupschema}
        onSubmit={async (values) => {
          const { username, email, password, dob, country} = values

          await signup(username, dob, email, password, country);
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleSubmit,
          errors,
          setFieldTouched,
          touched,
        }) => (
          <form className="flex flex-col gap-4 justify-center items-center w-full" onSubmit={handleSubmit}>
            <div className="flex flex-col w-full md:w-[23.125rem]">
              <input
                type="text"
                name="username"
                id="username"
                className="text-white text-[0.9375rem] leading-[1.875rem] shrink-0  w-full h-[3.125rem] rounded-[0.625rem] border border-white px-6 bg-gray-300 bg-opacity-25"
                style={{ fontFamily: "'Inter', sans-serif" }}
                placeholder="Username"
                onChange={handleChange}
                onBlur={() => setFieldTouched("username")}
              />
              {touched.username && errors.username && (
                <span className="text-red-600 font-normal">
                  {errors.username}
                </span>
              )}
            </div>
            {/* <div className="flex flex-col">
              <input
                type="date"
                name="dob"
                id="dob"
                className="text-white text-[0.9375rem] leading-[1.875rem] shrink-0 w-[23.125rem] h-[3.125rem] rounded-[0.625rem] border border-white px-6 bg-gray-300 bg-opacity-25"
                style={{ fontFamily: "'Inter', sans-serif" }}
                onChange={handleChange}
                onBlur={() => setFieldTouched("dob")}
              />
              {touched.dob && errors.dob && (
                <span className="text-red-600 font-normal">{errors.dob}</span>
              )}
            </div> */}
            <div className="flex flex-col w-full md:w-[23.125rem]">
              <input
                type="email"
                name="email"
                id="email"
                className="text-white text-[0.9375rem] leading-[1.875rem] shrink-0 w-full h-[3.125rem] rounded-[0.625rem] border border-white px-6 bg-gray-300 bg-opacity-25"
                style={{ fontFamily: "'Inter', sans-serif" }}
                placeholder="Email"
                onChange={handleChange}
                onBlur={() => setFieldTouched("email")}
              />
              {touched.email && errors.email && (
                <span className="text-red-600 font-normal">{errors.email}</span>
              )}
            </div>
            <div className="flex flex-col w-full md:w-[23.125rem]">
              <input
                type="password"
                name="password"
                id="password"
                className="text-white text-[0.9375rem] leading-[1.875rem] shrink-0  w-full h-[3.125rem] rounded-[0.625rem] border border-white px-6 bg-gray-300 bg-opacity-25"
                placeholder="Password"
                onChange={handleChange}
                onBlur={() => setFieldTouched("password")}
              />
              {touched.password && errors.password && (
                <span className="text-red-600 font-normal">
                  {errors.password}
                </span>
              )}
            </div>
            <div className="flex flex-col w-full md:w-[23.125rem]">
              <input
                type="password"
                name="confirmpassword"
                id="confirmpassword"
                className="text-white text-[0.9375rem] leading-[1.875rem] shrink-0  w-full h-[3.125rem] rounded-[0.625rem] border border-white px-6 bg-gray-300 bg-opacity-25"
                placeholder="Confirm Password"
                onChange={handleChange}
                onBlur={() => setFieldTouched("confirmpassword")}
              />
              {touched.confirmpassword && errors.confirmpassword && (
                <span className="text-red-600 font-normal">
                  {errors.confirmpassword}
                </span>
              )}
            </div>
            {/* <div className="flex flex-col">
              <CountryDropdown
                type="text"
                name="country"
            
                id="country"
                className="text-white text-[0.9375rem] leading-[1.875rem] shrink-0 w-[23.125rem] h-[3.125rem] rounded-[0.625rem] border border-white px-6 bg-black bg-opacity-55"
                placeholder="Country"
                value={values.country}
                onChange={(val) => setFieldValue("country", val)}
                onBlur={() => setFieldTouched("country")}
              />
              {touched.country && errors.country && (
                <span className="text-red-600 font-normal">
                  {errors.country}
                </span>
              )}
            </div> */}
            <button
              className="text-white bg-gradient-to-b from-[#19788E] to-[#0A0601]  w-full md:w-[23.125rem] h-[3.125rem] leading-[1.875rem] text-2xl rounded-[0.625rem]"
              type="submit"
            >
              {loading ? <ClipLoader color="#36d7b7" /> : "Sign Up"}
            </button>
          </form>
        )}
      </Formik>
     
    </div>
  );
};

export default Signup;
