import React from "react";
import { textcolor } from "../constants/styles";
import { GiMagickTrick } from "react-icons/gi";
const MilestonesCard = ({ item }) => {
  return (
    <div className="h-[72px] min-w-[55%]  lg:min-w-[28%]  px-4 py-2 bg-[#18162a] flex items-center justify-center gap-2 shadow-xl rounded-xl">
      {item?.is_top && (
        <div className="w-[33%] h-[95%] px-2 bg-[#3d385c] shadow- text-sm font-bold flex justify-center items-center rounded-xl shadow-2xl">
          Top {item?.percent}%
        </div>
      )}
      {!item?.is_top && !item?.badge && !item?.streak && !item?.percent && (
        <div className="w-[33%] h-[95%] px-2 bg-[#3d385c] shadow- text-sm font-bold flex justify-center items-center rounded-xl shadow-2xl">
          <GiMagickTrick size={30} />
        </div>
      )}
      {item?.streak && (
        <div className="w-[33%] h-[95%] px-2 bg-[#3d385c] shadow- text-sm font-bold flex justify-center items-center rounded-xl shadow-2xl">
          <span role="img" aria-label="fire" className="text-xl">
            🔥
          </span>
        </div>
      )}

      <div className="w-full  h-full pl-2 text-sm flex items-center flex-wrap gap-x-2">
        {item.is_top ? (
          <>
            <span>You’re current in the </span>
            <span className={`text-[${textcolor}]`}>
              top {item?.percent}%
            </span>{" "}
            <span>of all the</span>
            <span>learners on</span>{" "}
            <span className={`text-[${textcolor}]`}>XDojo</span>
          </>
        ) : (
          <>
            {item?.message.split(" ").map((e) => (
              <span>{e}</span>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default MilestonesCard;
