import React, { useContext, useEffect, useState } from "react";
import YoutubeBg from "../../assets/screens/youtubescreen/bg.webp";
import Youtube from "../../assets/screens/youtubescreen/items/youtube.webp";
import youtubeicon from "../../assets/screens/youtubescreen/youtubeicon.webp";
import plusicon from "../../assets/screens/youtubescreen/plusicon.webp";
import "./DesignYoutube.css";
import { useNewTextGenerate } from "../../hooks/useNewTextGenerate";
import { SyncLoader } from "react-spinners";
import { UseCopyText } from "../../hooks/useCopyText";
import { useQuestResult } from "../../hooks/useQuestResult";
import DesignQuest from "../../layouts/DesignQuest";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import { useParams } from "react-router-dom";
import VideoImageImports from "../../assets";

const smallButton = [
  { name: "story" },
  { name: "comedy" },
  { name: "travel" },
  { name: "podcast" },
  { name: "shopping" },
  { name: "fitness" },
  { name: "artistic" },
  { name: "event" },
];

const DesignYoutube = () => {
  const { quest_type_obj } = VideoImageImports;
  const { quest_type } = useParams();
  const [selectedButton, setSelectedButton] = useState("");
  const { errorState } = useContext(ErrorContext);
  const [desc, setDesc] = useState("");
  const { fetchText, textloading, answer } = useNewTextGenerate();
  const { copyText } = UseCopyText();
  const { fetchResult } = useQuestResult();
  const handleGenerate = async () => {
    const textPrompt = `Create a 600 words of Youtube Script of the content of ${selectedButton} and having a description as ${desc}`;

    let response = await fetchText({ prompt: textPrompt, quest_type });
    if (response) {
      let res = await fetchResult({ answer, quest_type, prompt: textPrompt });
      console.log(res);
      if (res === "OK") {
        setSelectedButton("");
        setDesc("");
        console.log("done");
      }
    }
  };
  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
  }, []);
  const modalStyle = {
    background: " linear-gradient(107.54deg, #800CBA 0%, #4F2AE6 100%)",

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };
  return (
    <DesignQuest modalStyle={modalStyle} title="Create Youtube Script">
      {errorState.errorModal && <LimitErrorModal />}
      <div className="flex flex-col justify-center items-center gap-2 mt-4">
        <div
          style={{
            boxShadow: "0px 8px 15px 0px #0000001A",
          }}
          className="flex items-center gap-2 px-4 py-2 rounded-2xl bg-white"
        >
          <div className="w-6 h-6">
            <img src={Youtube} alt="" className="w-full h-full" />
          </div>
          <h1
            style={{
              lineHeight: "28.13px",
            }}
            className="uppercase font-semibold text-[24px]"
          >
            YOUTUBE SCRIPT streamers
          </h1>
        </div>
      </div>
      <div
        style={{ zIndex: 10000 }}
        className="flex  justify-center items-center px-2 gap-12 py-2 "
      >
        <div className="flex w-[35%] h-[75vh] mt-[2rem] rounded-2xl">
          <div
            style={{
              background:
                "radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #FBF6FF 100%)",
              boxShadow: "0px 0px 20px -18px #000000",
            }}
            className="relative rounded-tl-2xl rounded-2xl flex flex-col items-center gap-4 w-full h-full  px-2 py-8"
          >
            <div className="w-[80%] text-[30px] font-medium leading-9 tracking-tighter text-left flex items-center px-2 py-2 h-[7%]">
              Your Youtube Script:
            </div>
            <div className="bg-ebebed h-[80%] w-[80%] px-6 py-4  rounded-2xl">
              {textloading ? (
                <SyncLoader
                  color="#36d7b7"
                  style={{
                    zIndex: 10000,
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                    marginTop: "30px",
                  }}
                />
              ) : (
                ""
              )}
              {answer && !textloading
                ? answer.split("\n\n").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                      <br />
                    </span>
                  ))
                : ""}
            </div>

            <button
              disabled={!answer && textloading}
              onClick={() => copyText(answer)}
              style={{ border: "1px solid black" }}
              className="w-24 justify-items-end h-8 uppercase text-white flex items-center justify-center outline-none rounded-2xl text-sm  shadow-2xl bg-black"
            >
              <span>Copy text</span>
            </button>
          </div>
        </div>

        <div
          style={{
            background: "#b3a9c2",
          }}
          className=" rounded-2xl  w-[40%]  h-[75vh] overflow-auto mt-[2rem]"
        >
          <div
            style={{
              background: "linear-gradient(180deg, #FFFFFF 0%, #E0E9FF 100%)",
            }}
            className="flex relative flex-col gap-[5%]  rounded-tl-2xl rounded-tr-2xl w-full h-full px-8 py-4"
          >
            <div className="w-full flex items-center px-2 py-2 h-[8%]">
              <div className="uppercase text-[30px]">Enter Script Details:</div>
            </div>
            <div className="flex justify-center">
              <textarea
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                placeholder="Description..."
                style={{ resize: "none" }}
                className="outline-none font-bold text-[#5358a4] text-sm w-[95%] h-[18vh] bg-[#eaebec] rounded-xl px-4 py-4 overflow-scroll"
              ></textarea>
            </div>
  
                  <div className="flex flex-wrap justify-center w-full gap-2 mt-6">
                    {smallButton.map((e) => (
                      <button
                        key={e.name}
                        onClick={() => setSelectedButton(e.name)}
                        className={`${
                          selectedButton === e.name ? "opacity-20" : ""
                        } uppercase tracking-wider flex items-center gap-2 px-2 py-2 text-white  w-[115px] h-[48px] mx-1 my-1 text-sm rounded-2xl`}
                        style={{
                          background:
                            "linear-gradient(0deg, #1D1D1D, #1D1D1D), radial-gradient(70.71% 70.71% at 50% 50%, #1D1D1D 0%, rgba(0, 0, 0, 0) 50%),radial-gradient(70.71% 70.71% at 50% 50%, #111111 0%, rgba(0, 0, 0, 0) 50%),radial-gradient(70.71% 70.71% at 50% 50%, #0F0F0F 0%, rgba(0, 0, 0, 0) 50%)",
                        }}
                      >
                        <img className="w-6 h-6 object-cover" src={quest_type_obj.Design_Youtube.items[e.name]} alt="" />
                        <span>{e.name}</span>
                      </button>
                    ))}
                  </div>
                  <div className="flex justify-center mt-8">
                  <button
              disabled={textloading}
              onClick={handleGenerate}
              style={{
                background:"#6A5BCD",

              }}
              className="w-40 h-12 uppercase text-white outline-none rounded-md text-lg shadow-2xl "
            >
              Generate
            </button>

                  </div>
            
          
          </div>
        </div>
      </div>
    </DesignQuest>
  );
};

export default DesignYoutube;
