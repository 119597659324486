import React, { useEffect } from "react";
import { SyncLoader } from "react-spinners";
import mobile from "../../assets/screens/aifreecoding/mobile.webp";
import { UseCopyText } from "../../hooks/useCopyText";
import useFreeStyleHistory from "../../hooks/useFreeStyleHistory";
const AIFreeHistory = ({  setHistoryModal }) => {
  const {aifreeHisttory,aifreeHisttoryData,aiFreeHisLoad}=useFreeStyleHistory()
  useEffect(()=>{
    aifreeHisttory()

  },[])
  const onClose = () => setHistoryModal(false);
  const { copyText } = UseCopyText();
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-10 mt-12">
      <div className="relative  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-5 border w-[80%] h-[80%] shadow-lg rounded-md bg-white overflow-hidden z-20">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-3 text-3xl leading-none font-semibold text-gray-600 hover:text-gray-900"
        >
          &times;
        </button>
        <h2 className="text-xl font-bold text-gray-800 mb-5">
          AI Free Style Coding History
        </h2>
        {aiFreeHisLoad ? (
          <SyncLoader
            color="#36d7b7"
            style={{
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              marginTop: "30px",
            }}
          />
        ) : (
          ""
        )}
  <div className="flex flex-col h-[100%] gap-10 overflow-y-auto">
        {!aiFreeHisLoad &&
          aifreeHisttoryData?.map((item, index) => (
            <div key={index} className="mb-4 px-4 py-4">
              <h3 className="text-lg font-semibold text-gray-700">{`Q${
                index + 1
              }: ${item?.prompt}`}</h3>
              <div
        style={{ zIndex: 10000 }}
        className="flex gap-10 justify-center items-center px-2"
      >
        <div className="relative rounded-xl flex-shrink w-[55%] h-[72vh]">
          <div
            style={{
            //   background: "linear-gradient(45deg, #61348b, #4e507a, #61348b)",
              lineHeight: 1.8,
            }}
            className="relative text-yellow-500 rounded-xl flex-shrink  w-full px-8 py-4 h-full"
          >
            <button
              disabled={!aifreeHisttoryData}
              onClick={() => copyText(item.code)}
              style={{ border: "1px solid black" }}
              className="w-24 absolute top-[5%] right-[8%] h-6 z-20 uppercase text-black outline-none rounded-md text-sm  shadow-2xl bg-gray-200"
            >
              Copy text
            </button>
           <div className="mb-4 relative max-h-[60vh] overflow-scroll py-4 bg-black px-10">
            {item?.code?.split("\n")?.map((line,inIndex) => (
              <span key={inIndex}>
                {line}
                <br />
                <br />
              </span>
            ))}
           
          </div>
          </div>
        </div>

        <div className="flex flex-col gap-6 items-center py-1 px-4 rounded-xl flex-shrink w-[60vh]  h-[95vh]">
          <div className="relative flex flex-col gap-6 items-center py-1 px-4 rounded-xl flex-shrink w-full  h-full">
            <img src={mobile} className="w-full h-full" alt="" />
            <div className="absolute top-[10%] overflow-scroll w-[70%] h-[82%] mt-[8%] text-black rounded-lg px-4 py-4">
            
            <iframe
                  title="mobile_iframe"
                  sandbox="allow-same-origin allow-scripts"
                  srcDoc={`
                  <html>
                    <head>
                      <style>
                        ::-webkit-scrollbar {
                          width: 0;
                        }
                        /* Add other styles here if needed */
                        body {
                          margin: 0;
                          padding: 0;
                        }
                      </style>
                    </head>
                    <body>
                      ${item?.code}
                    </body>
                  </html>
                `}
                  className="rounded-lg block mt-[-5%] w-full h-[100%]"
                >
                </iframe>
            </div>
          </div>
        </div>
      </div>
            </div>
          ))}
          </div>
      </div>
    </div>
  );
};

export default AIFreeHistory;
