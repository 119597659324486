import React from "react";
import crown from "../assets/icons/crown.webp";
import { IoArrowForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import { TfiBackRight } from "react-icons/tfi";
import useOverItem from "../hooks/useOverItem";

const LockQuestModal = ({ setModal }) => {
  const { state } = useOverItem();
  return (
    <div
      style={{ zIndex: 10000000000000 }}
      className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center`}
    >
      <div className="bg-transparent p-4 flex justify-center items-center rounded-lg shadow-lg gap-6">
        <button
          onClick={() => {
            setModal();
          }}
          className="absolute top-16 left-5"
        >
          <TfiBackRight className="text-white font-extrabold" size={25} />
        </button>
        <div className="w-16 h-16">
          <img className="w-full h-full" src={crown} alt="" />
        </div>
        {state.overview?.payment_status === true ? (
          <h2 className="text-[20px] font-bold text-white">
            Quest Unlock on daily visits
          </h2>
        ) : (
          <Link to="/settings/plan&billing" className="flex items-center gap-2">
            <h2 className="text-[20px] font-bold text-white">
              Please Subscribed a plan to unlock
            </h2>
            <IoArrowForward size={25} className="text-white" />
          </Link>
        )}
      </div>
    </div>
  );
};

export default LockQuestModal;
