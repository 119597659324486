import { useCopyToClipboard } from "usehooks-ts"
import { toast } from 'react-toastify';
export const UseCopyText=()=>{
    const [, copy] = useCopyToClipboard()
    const copyText=(text)=>{
        copy(text)
        toast.success('Text Copied Succefully', {
            position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
            autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
            hideProgressBar: true, // Hide the progress bar
          });
    }
    return {copyText}



}