import React, { useContext, useEffect, useState, useRef } from "react";
import api from "../api";
import { AuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import { ErrorContext } from "../context/errorcontext/ErrorContext";
import {
  SET_ERROR_MESSAGE,
  SET_ERROR_MODAL,
} from "../context/errorcontext/action";
import useOverItem from "./useOverItem";
import { SET_XP_CHECK } from "../context/overviewcontext/action";

const useFreeStyletext = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [uniqueId, setUniqueId] = useState(null);

  const { dispatch: overViewDispatch } = useOverItem();

  const [errorMessage, setErrorMessage] = useState("");
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(ErrorContext);
  const [apiCall, setApiCall] = useState(10);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser.token}`,
  };

  // Using useRef to store the latest state
  const apiCallRef = useRef(apiCall);
  apiCallRef.current = apiCall;

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        let res = await api.post(
          "/freeStyleCoding-get-single",
          {
            dateId: uniqueId,
          },
          {
            headers,
          }
        );

        if (res.status === 200 && res.data.status === "completed") {
          setData(res.data.data);
          setLoading(false);
          setApiCall(10);
          setUniqueId(null);
          setStatus("completed");
          overViewDispatch({ type: SET_XP_CHECK });
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (status === "pending" && apiCallRef.current > 0) {
      intervalId = setInterval(fetchData, 30000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [status]);

  const freeStyleCodeText = async ({ prompt, quest_type }) => {
    setLoading(true);
    try {
      let res = await api.post(
        "/freeStyleCoding-code",
        {
          prompt,
          quest_type,
        },
        {
          headers,
        }
      );
      if (res.status === 400) {
        setLoading(false);
        toast.error(res.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        return;
      }
      if (res.status === 200) {
        setErrorMessage("");
        setApiCall(apiCallRef.current - 1);
        setUniqueId(res.data.dateId);
        setStatus("pending");
      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 400) {
        dispatch({ type: SET_ERROR_MODAL, payload: true });
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: err.response.data.message,
        });
      } else {
        toast.error(err.response.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        console.log(err);
      }

      // console.error(err);
    }
  };

  return { freeStyleCodeText, textData: data, textLoad: loading, errorMessage };
};

export default useFreeStyletext;
