import { useStripe } from "@stripe/react-stripe-js";
import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import api from "../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const useCancelPayment=()=>{
    const [loading,setLoading]=useState(false)
    const { currentUser } = useContext(AuthContext);
    const navigate=useNavigate()
    const [status,setStatus]=useState(false)
    const cancelPayment = async () => {
        
        setLoading(true);
      
        try {
          console.log("hello");
          const response = await api.get(
            "/cancel-subscription",
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`,
              },
            }
          );
          if(response.status===200){
            toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
                autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
                hideProgressBar: true, // Hide the progress bar
              });
              setStatus(!status)
              navigate("/")
              window.location.reload();
              
          }
          
        } catch (error) {
          console.error(
            "There was an error redirecting to Stripe Checkout:",
            error
          );
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
            autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
            hideProgressBar: true, // Hide the progress bar
          });

        }
    
        setLoading(false);
      };

      return {cancelPayment,cancelLoading:loading,cancelStatus:status}


}