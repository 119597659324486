import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import api from "../api.js";
import { useQuestResult } from "./useQuestResult";
import { toast } from "react-toastify";
import {
  SET_ERROR_MESSAGE,
  SET_ERROR_MODAL,
} from "../context/errorcontext/action.js";
import { ErrorContext } from "../context/errorcontext/ErrorContext.js";
import { SET_XP_CHECK } from "../context/overviewcontext/action.js";
import useOverItem from "./useOverItem.js";
import { SET_QUEST_PROMPT } from "../context/questContext/action.js";
import { QuestContext } from "../context/questContext/QuestContext.js";
export const useSongGenerate = () => {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState("");
  const {
    questDispatch,
  } = useContext(QuestContext);
  const { dispatch } = useContext(ErrorContext);
  const { fetchResult } = useQuestResult();
  const {dispatch:overViewDispatch,notificationPop,state}=useOverItem()
  const createSong = async ({
    desc,
    selectSong,
    quest_type,
    setDesc,
    setSelectSong,
  }) => {
    setLoading(true);
    try {
      const prompt = `generate a 600 words song lyrics of type ${selectSong} and having description ${desc}`;
      questDispatch({type:SET_QUEST_PROMPT,payload:prompt})
      const response = await api.post(
        "/quest/chat",
        {
          prompt,
          quest_type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser.token}`,
          },
        }
      );
      if (response.status === 400) {
        setLoading(false);
        toast.error(response.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        return;
      }
      if (response.status === 200) {
        // console.log(response.data)
        // let ans=response.data.answer.split("\n").map((line, index) => (
        //   <span key={index}>
        //     {line}
        //     <br />
        //   </span>
        // ))
        setAnswer(response.data.answer);
        setLoading(false);

        let res = await fetchResult({ answer, quest_type, prompt });

        if (res === "OK") {
          setDesc("");
          setSelectSong("");
          if(!state?.overview?.questCompletedArray?.some(e=>e.quest.quest_type===quest_type)){
            notificationPop({
              status:"badge"
            })
    
          }
          overViewDispatch({type:SET_XP_CHECK})
        }
      }
    } catch (err) {
      setLoading(false);

      setLoading(false);
      if (err.response.status === 400) {
        dispatch({ type: SET_ERROR_MODAL, payload: true });
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: err.response.data.message,
        });
      } else {
        toast.error(err.response.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        console.log(err);
      }
    }
  };
  return { createSong, loading, answer };
};
