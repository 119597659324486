import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import api from "../api.js";
import { QuestContext } from "../context/questContext/QuestContext.js";
import { SET_QUESTS_LIST } from "../context/questContext/action.js";
export const useGetQuests = () => {
  const { currentUser } = useContext(AuthContext);
  const { questState, questDispatch } = useContext(QuestContext);

  const [loading, setLoading] = useState(false);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser.token}`,
  };
  const fetchQuests = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/quest`, { headers });
      if (response) {
        questDispatch({ type: SET_QUESTS_LIST, payload: response.data.quests });
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log("Something went wrong");
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };
  return { fetchQuests, loading };
};
