import React, { useState } from 'react'
import api from '../api'
import { toast } from 'react-toastify'

export const useForgetPassword = () => {
    const [loading,setLoading]=useState(false)
    const forgetPassword = async (data) => {
        setLoading(true)
       
        try {
        let response = await api.post("/users/forgetemail", data)
          toast.success(response.data.message || 'email sent successfully', {
            position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
            autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
            hideProgressBar: false, // Hide the progress bar
          });
        } catch (error) {
          // Handle any errors that occurred during the API call
    
          toast.error(error.response.data.message || 'Worng credential', {
            position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
            autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
            hideProgressBar: false, // Hide the progress bar
          });
        }
        setLoading(false)
    
      }
      return {forgetPassword,forgetLoading:loading}
}

