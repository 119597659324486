import React, { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { motion } from "framer-motion";
import VideoImageImports from "../assets";
import useOverItem from "../hooks/useOverItem";
import { textcolor } from "../constants/styles";
import { QuestContext } from "../context/questContext/QuestContext";
import { useNavigate } from "react-router-dom";
import {
  SET_SIGLE_MODAL,
  SET_SINGLE_QUEST,
} from "../context/questContext/action";
const ActivityPage = () => {
  const { quest_type_obj } = VideoImageImports;
  const { state } = useOverItem();
  const modalVariants = {
    hidden: { x: "100vh", opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 3 } },
    exit: { x: "100vh", opacity: 0, transition: { duration: 3 } },
  };
  const { questDispatch } = useContext(QuestContext);
  const navigate = useNavigate();
  return (
    <div
      style={{
        fontFamily: "'Poppins',sans-serif",
      }}
      className="relative flex flex-col bg-[#2c2654] shadow-xl rounded-[5%] h-[70%] lg:h-full w-full lg:w-[64%] py-4 px-6"
    >
      <LazyLoadImage
        alt="Background"
        threshold={150} // This is where you specify the threshold
        src={quest_type_obj[state.overview?.activity?.quest_type]?.bg} // Your dynamic background image source
        effect="blur"
        className="bg-cover rounded-[5%] opacity-50 absolute inset-0 w-full h-full"
        wrapperClassName="w-full h-full rounded-[5%] opacity-40 absolute top-0 left-0"
      />
      <motion.div
        initial="hidden"
        animate={!state.loading ? "visible" : "exit"}
        variants={modalVariants}
        className="flex absolute top-10 right-0 rounded-l-full bg-[#362460] w-48 h-12 justify-center items-center"
      >
        Activity of the day
      </motion.div>
      <LazyLoadImage
        alt="Background"
        threshold={150} // This is where you specify the threshold
        src={quest_type_obj["Design_Character"].animate} // Your dynamic background image source
        effect="blur"
        className="w-[90%] h-[90%] object-contain absolute top-[2%] left-[-16%]"
        wrapperClassName="w-[90%] h-[90%] object-contain absolute top-[2%] left-[-12%]"
      />
      <button
      style={{zIndex:10000}}
            onClick={() => {
              navigate(`/quest/${state.overview?.activity?.quest_type}`);
              questDispatch({
                type: SET_SIGLE_MODAL,
                payload: state.overview?.activity?.quest_type,
              });
              questDispatch({
                type: SET_SINGLE_QUEST,
                payload: state.overview?.activity,
              });
            }}
            className={`w-32 h-12 bg-[${textcolor}] absolute bottom-2 right-0 text-black text-md font-bold rounded-full`}
          >
            Start
          </button>
      <div className="flex flex-col w-[70%] h-[95%] lg:h-[65%] justify-start bottom-[-12%]  absolute lg:bottom-[0%] px-4 py-4 left-[30%] items-start ">
        {/* title div */}
        <div className="flex flex-col items-start">
          <div className="flex items-center justify-center">
            <h1
              style={{
                fontFamily: "'Inter','sans-serif'",
              }}
              className="text-[20px] lg:text-[28px] font-bold"
            >
              {state.overview?.activity?.title}
            </h1>
          </div>
          <div className="flex items-center justify-center">
            <h1 className={`text-[16px] text-[${textcolor}] font-bold`}>
              10 XP
            </h1>
          </div>
        </div>
        {/* xp div */}

        {/* description div */}
        <div className="h-[100%] lg:h-[40%] w-[80%] text-gray-200 flex pb-2 mt-4">
          {state.overview?.activity?.desc}
        </div>
        {/* last div */}
        <div className="flex justify-between w-full mt-8 items-center justify-self-stretch">
          {/* text div */}
          <div className="flex gap-2 items-center">
            <div className=""></div>
            <div></div>
          </div>

          {/* button div */}

          
        </div>
      </div>
    </div>
  );
};

export default ActivityPage;
