import React, { createContext, useContext, useReducer, useState } from "react";
import { SET_AI_LIST_DATA, SET_AI_LOADING, SET_AI_MESSAGES } from "./action";
import api from "../../api";
import { AuthContext } from "../AuthContext";


export const AiContext = createContext();

const initialState = {
 listData:[],
 loading:false,
 messages:[],
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_AI_LIST_DATA:{
        return {...state,listData:action.payload}
    }
    case SET_AI_LOADING:{
        return {...state,loading:action.payload}
    }
    case SET_AI_MESSAGES:{
        return {...state,messages:[...state.messages,action.payload]}
    }
   
    
    default:
      return state;
  }
};
const AiProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser?.token}`,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

 const postAiTopics=async(body)=>{
  dispatch({type:SET_AI_LOADING,payload:true});
  try{
    let res=await api.post("/ai_random_topics", body, {
      headers,
    });
    if(res.status===200){
      console.log(res)
      dispatch({type:SET_AI_LIST_DATA,payload:JSON.parse(res.data.topics)})
    }

  }
  catch(err){
    console.log(err)
  }

  dispatch({type:SET_AI_LOADING,payload:false});
 }

  const value = {
   state,
   dispatch,
   postAiTopics,
   isMenuOpen,
   setIsMenuOpen,
  

  };
  return (
    <AiContext.Provider value={value}>
      {children}
    </AiContext.Provider>
  );
};

export default AiProvider;
