import React, { useEffect, useState } from "react";
import { useContext } from "react";
import "./DesignSong.css";
import { useSongGenerate } from "../../hooks/useSongGenerate";
import { SyncLoader } from "react-spinners";
import { UseCopyText } from "../../hooks/useCopyText";
import DesignQuest from "../../layouts/DesignQuest";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import { useParams } from "react-router-dom";
import ShareCompoenents from "../screencomponent/ShareCompoenents";
import { QuestContext } from "../../context/questContext/QuestContext";
import { useFeed } from "../../hooks/useFeed";
import { IoShareSocialOutline } from "react-icons/io5";
import DesignSongCard from "./DesignSongCard";
const ButtonArray = [
  { name: "RAP" },
  { name: "Msg" },
  { name: "Ballad" },
  { name: "Rock" },
  { name: "Romantic" },
  { name: "Country Tale" },
  { name: "Jazz" },
  { name: "Pop" },
  { name: "Folk" },
];

const DesignSong = () => {
  const { quest_type } = useParams();
  const { errorState } = useContext(ErrorContext);
  const { copyText } = UseCopyText();
  const [selectSong, setSelectSong] = useState("");
  const [feedModal, setFeedModal] = useState(false);
  const { postFeed, postSent } = useFeed();
  const [desc, setDesc] = useState("");
  const {
    questState: { questPrompt },
  } = useContext(QuestContext);

  const { createSong, loading, answer } = useSongGenerate();
  const handleGenerate = async () => {
    await createSong({ selectSong, setSelectSong, desc, setDesc, quest_type });
  };
  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
  }, []);
  const modalStyle = {
    background: "linear-gradient(180deg, #3C2A35 0%, #2C212C 100%)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };
  return (
    <DesignQuest modalStyle={modalStyle} title="Welcome To Song World">
      {errorState.errorModal && <LimitErrorModal />}
      {feedModal && (
        <ShareCompoenents
          feedModal={feedModal}
          setFeedModal={setFeedModal}
          body={{
            title: quest_type,
            text: answer,
            userPrompt: questPrompt,
          }}
          postFeed={postFeed}
          postSent={postSent}
        />
      )}
      <div className="flex flex-col gap-2 pt-2 items-center justify-center">
        <h1 className="text-[#E4E8ED] text-[24px]">LYRICS LAB</h1>
        <p className="text-[14px] text-white">
          Input Description, Select Style & Choose Beat Style to generate
        </p>
      </div>

      <div className="flex justify-center mt-10 text-[white] px-2 pb-2">
        <div className="flex justify-between w-full gap-6">
          {/* 1st div */}
          <div
            className="w-[20%] h-[70vh] overflow-auto flex justify-center"
          >
            <div className="flex flex-col gap-6 py-4">
              <DesignSongCard
                buttonName="edm beats"
                music=""
                musicImage="hiphoop"
              />
              <DesignSongCard
                buttonName="rythms"
                music=""
                musicImage="musicnotes"
              />
            </div>
          </div>

          <div className="w-[50%] flex flex-col gap-4">
            <div className="relative bg-[#EAC181] rounded-xl">
              {answer && !loading && (
                <button
                  disabled={postSent}
                  onClick={() => setFeedModal(true)}
                  style={{ zIndex: 10000000 }}
                  className={`absolute top-5 px-2 py-2 bg-gray-300 bg-opacity-50 text-black rounded-full right-5`}
                >
                  <IoShareSocialOutline className="text-black" size={20} />
                </button>
              )}

              <div
                style={{ lineHeight: 1.8 }}
                className="overflow-scroll h-[35vh] text-black px-6 pb-4"
              >
                {loading ? (
                  <SyncLoader
                    color="#36d7b7"
                    style={{
                      zIndex: 10000,
                      display: "flex",
                      justifyContent: "center",
                      alignItem: "center",
                      marginTop: "30px",
                    }}
                  />
                ) : (
                  ""
                )}

                {answer && !loading
                  ? answer.split("\n\n").map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                        <br />
                      </span>
                    ))
                  : ""}
              </div>
              <button
                disabled={loading || !desc || !selectSong}
                onClick={handleGenerate}
                style={{
                  backgroundColor: "#deb376",
                  zIndex: 10000000,
                  // border: "2px solid black",
                }}
                className="shadow-lg border-4 border-[#EAC181] border-dotted  left-1/2  transform -translate-x-1/2 text-black font-bold px-6 py-2 rounded-lg absolute bottom-[-1rem]"
              >
                GENERATE
              </button>
              <button
                disabled={!answer && loading}
                onClick={() => copyText(answer)}
                style={{ border: "1px solid black" }}
                className="w-24 absolute bottom-[2%] right-[2%] h-6 uppercase text-black outline-none rounded-md text-sm  shadow-2xl bg-gray-200"
              >
                Copy text
              </button>
            </div>

            <div className="flex gap-4 justify-around items-center mt-8">
              <div
                style={{
                  border: "2px solid #784B23",
                }}
                className="rounded-xl w-[50%] h-[30vh] flex flex-col gap-4 pt-2 bg-[#EAC181]"
              >
                <div className="flex px-4 text-black font-bold text-[14px]">
                  <span>Choose a style:</span>
                </div>
                <div className="flex flex-wrap gap-4  justify-center">
                  {ButtonArray.map((e, index) => (
                    <button
                      onClick={() => setSelectSong(e.name)}
                      style={{
                        background:
                          "linear-gradient(124.5deg, #D5DBE4 0%, #F8F8F8 100%)",
                        boxShadow:
                          "0px 1px 2px 1px #1E235A66, 0px 0px 1px 1px #FFFFFF inset, 0px -2px 0px 0px #CDCDE6 inset",
                      }}
                      key={index}
                      className={`${
                        selectSong === e.name ? "opacity-20" : ""
                      } z-10 w-[28%] uppercase hover:bg-[#7b4a28] text-[#969FAF] text-[12px] font-bold py-2 px-2 rounded-md`}
                    >
                      {e.name}
                    </button>
                  ))}
                </div>
              </div>
              <textarea
                name=""
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                style={{ resize: "none", border: "2px solid #784B23" }}
                placeholder="Description..."
                id=""
                className="text-black bg-[#EAC181] rounded-xl w-[50%] h-[30vh] text-md font-bold  outline-none  px-2 py-2"
              ></textarea>
            </div>
          </div>
          {/* 3rd div */}
          <div className="w-[20%] h-[70vh] overflow-auto flex justify-center">
            <div className="flex flex-col gap-6 py-4">
              <DesignSongCard
                buttonName="trance vives"
                music=""
                musicImage="guitar"
              />
              <DesignSongCard
                buttonName="house groves"
                music=""
                musicImage="jazz"
              />
            </div>
          </div>
        </div>
      </div>
    </DesignQuest>
  );
};

export default DesignSong;
