import React, { createContext, useContext, useEffect, useReducer } from "react";
import { AuthContext } from "../AuthContext";
import { SET_INDEX_LIST, SET_QUESTS_LIST, SET_QUEST_AI_ID, SET_QUEST_HISTORY, SET_QUEST_PROMPT, SET_SIGLE_MODAL, SET_SINGLE_QUEST } from "./action";


export const QuestContext = createContext();

const initialState = {
 quests:[],
 currIndex:0,
 singleQuest:{},
 questHistory:{},
 singleModal:"",
 questPrompt:"",
 AIChatId:"",
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_QUESTS_LIST:{
        return {...state,quests:action.payload}
    }
    case SET_SINGLE_QUEST:{
        return {...state,singleQuest:action.payload}
    }
    case SET_INDEX_LIST:{
        return {...state,currIndex:action.payload}
    }
    case SET_QUEST_HISTORY:{
        return {...state,questHistory:action.payload}
    }
    
    case SET_SIGLE_MODAL:{
        return {...state,singleModal:action.payload}
    }
    case SET_QUEST_PROMPT:{
        return {...state,questPrompt:action.payload}
    }
    case SET_QUEST_AI_ID:{
        return {...state,AIChatId:action.payload}
    }
    
    default:
      return state;
  }
};
const QuestProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser?.token}`,
  };
  const [questState, questDispatch] = useReducer(reducer, initialState);



  const value = {
    questState,
    questDispatch

  };
  return (
    <QuestContext.Provider value={value}>
      {children}
    </QuestContext.Provider>
  );
};

export default QuestProvider;
