import React from 'react';
import { useNavigate } from 'react-router-dom';

function PaymentCancelPage() {
    const navigate=useNavigate()
    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black flex  items-center justify-center modal">
        <div className="flex items-center justify-center min-h-[60vh] px-4">
            <div className="p-6 bg-white text-black shadow-lg rounded-lg text-center">
                <h2 className="text-2xl font-bold mb-4">Payment Cancelled</h2>
                <p className="mb-4">
                    We're sorry to see you go. If you faced any issues, please{' '}
                    <a href="mailto:Hello@xdojo.ai" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                        contact us
                    </a>.
                </p>
          
                <button
                    onClick={() => navigate("/")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 inline-block"
                >
                    Retry
                </button>
            </div>
        </div>
        </div>
    );
}

export default PaymentCancelPage;
