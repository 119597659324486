import React, { useContext, useState } from 'react'
import api from '../api'
import { AuthContext } from '../context/AuthContext'
import { toast } from 'react-toastify'
import moment from 'moment'
import { SET_XP_CHECK } from '../context/overviewcontext/action'
import useOverItem from './useOverItem'

const usePostUserInfo = () => {
    const { currentUser } = useContext(AuthContext)
    const [status,setStatus]=useState("")
    const [loading,setLoading]=useState(false)
    const {dispatch:overViewDispatch}=useOverItem()
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentUser.token}`,
    };
    const postuserInfo=async(data)=>{
        const parsedDate = moment(data.dob, "DD-MM-YYYY");
        const date_of_birth = parsedDate.toString();
    
        data={...data,dob:date_of_birth}
        setLoading(true)
        try{
           const response= await api.post("/users/post-info",data,{
                headers
            })

            if(response.status===200){
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
                    autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
                    hideProgressBar: true, // Hide the progress bar
                  });
                  setStatus("OK")
                  overViewDispatch({type:SET_XP_CHECK})
            }

        }
        catch(err){
            console.log(err)
        }
        setLoading(false)
    }
  return {postStatus:status,postLoading:loading,postuserInfo}
}

export default usePostUserInfo