import React, { createContext, useContext, useEffect, useReducer } from "react";
import { AuthContext } from "../AuthContext";
import { SET_LEADERBOARD_LIST, SET_LEADERBOARD_LOADING } from "./action";
import useOverItem from "../../hooks/useOverItem";
import api from "../../api";

export const LeaderBoardContext = createContext();

const initialState = {
  leaderboard: [],
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LEADERBOARD_LIST: {
      return { ...state, leaderboard: action.payload };
    }
    case SET_LEADERBOARD_LOADING: {
      return { ...state, loading: action.payload };
    }
    default:
      return state;
  }
};
const LeaderBoardProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const {state}=useOverItem()
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser?.token}`,
  };
  const findAndSortLeaderboard = (email, newXP) => {
    // Find the index of the user in the leaderboard
    const index = leaderboardState.leaderboard.findIndex(user => user.email === email);

    if (index !== -1) {
        // Update the user's XP
        leaderboardState.leaderboard[index].xp = newXP;

        // Slice and sort the array up to the user's position
        const sortedPortion = leaderboardState.leaderboard.slice(0, index + 1)
                            .sort((a, b) => b.xp - a.xp); // Assuming higher XP should come first

        // Get the unsorted remainder of the array
        const unsortedPortion = leaderboardState.leaderboard.slice(index + 1);

        // Combine them back into one array
        const newLeaderboard = [...sortedPortion, ...unsortedPortion];

        // Update the state with the new array
        leaderboardDispatch({
            type: SET_LEADERBOARD_LIST,
            payload: newLeaderboard,
        });
    }
};

  
  const [leaderboardState, leaderboardDispatch] = useReducer(
    reducer,
    initialState
  );


  

  const getLeaderboard = async () => {
    if(leaderboardState.leaderboard.length>0){
      findAndSortLeaderboard(currentUser?.email,state.overview?.xp)
      return
    }
    leaderboardDispatch({ type: SET_LEADERBOARD_LOADING, payload: true });
    try {
      const response = await api.get(`/leaderboard`, { headers });
      if (response && response.status === 200) {
        leaderboardDispatch({
          type: SET_LEADERBOARD_LIST,
          payload: response.data.items,
        });
      }
    } catch (error) {
      console.log(error);
    }
    leaderboardDispatch({ type: SET_LEADERBOARD_LOADING, payload: false });
  };

  const value = {
    leaderboardDispatch,
    leaderboardState,
    getLeaderboard
  };

  return (
    <LeaderBoardContext.Provider value={value}>
      {children}
    </LeaderBoardContext.Provider>
  );
};

export default LeaderBoardProvider;
