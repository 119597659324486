import imgpost from '../assets/images/imagepost.webp'
import cbanner from '../assets/images/schedule.webp'
const post = {
    likes: 100,
    comments: 32,
    content: "On a first-time visit to New Orleans, there's so much to see and do."
}
const user = {
    name: 'Sam Guy',
    id: 'samguy',
}
const imagepost = {
    likes: 100,
    comments: 32,
    caption: "Explore The Seas ",
    repost: 96,
    image: imgpost
}
const progress = {
    stage1: true,
    stage2: true,
    stage3: true,
    stage4: true,
    stage5: true,
}
const certificates = [
    "Certificate for sharp mental abilities.",
    "Certificate for English language. ",
    "Certificate for Microsoft Office Proficiency. ",
]
const courses = [
    {
        title: "Master Digital Product Design: UX Research & UI Design",
        desc: "A complete design education for product designers: Research the…",
        author: "Elijah Snyder",
        banner: cbanner,
        percentage: 75
    },
    {
        title: "Master Digital Product Design: UX Research & UI Design",
        desc: "A complete design education for product designers: Research the…",
        author: "Elijah Snyder",
        banner: cbanner,
        percentage: 35
    },
    {
        title: "Master Digital Product Design: UX Research & UI Design",
        desc: "A complete design education for product designers: Research the…",
        author: "Elijah Snyder",
        banner: cbanner,
        percentage: 45
    },
    {
        title: "Master Digital Product Design: UX Research & UI Design",
        desc: "A complete design education for product designers: Research the…",
        author: "Elijah Snyder",
        banner: cbanner,
        percentage: 65
    },
    {
        title: "Master Digital Product Design: UX Research & UI Design",
        desc: "A complete design education for product designers: Research the…",
        author: "Elijah Snyder",
        banner: cbanner,
        percentage: 25
    },
]
export { post, user, imagepost, progress, certificates, courses }