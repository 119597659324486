import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const pizzaValidationSchema = Yup.object().shape({
  size: Yup.string()
    .required('Size is required')
    .oneOf(['Small', 'Medium', 'Large'], 'Invalid size selection'),
  crust: Yup.string()
    .required('Crust is required')
    .oneOf(['Thin', 'Thick', 'Cheese-filled'], 'Invalid crust selection'),
  toppings: Yup.array().of(Yup.string()),
});

const CreatePizzaForm = ({setFormObj}) => {
  return (
    <Formik
      initialValues={{
        size: '',
        crust: '',
        toppings: [],
      }}
      validationSchema={pizzaValidationSchema}
      onSubmit={(values, actions) => {
        console.log(values);
        setFormObj(values)
        // Reset the form to initial values
         actions.resetForm();
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className="px-8 py-4  w-full max-w-sm">
          <h1 className="text-2xl font-bold mb-6">Create Your Pizza</h1>
          <div className="mb-4">
            <label htmlFor="size" className="block text-sm font-medium text-gray-700">Size</label>
            <Field as="select" name="size" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              <option value="">Select a size</option>
              <option value="Small">Small</option>
              <option value="Medium">Medium</option>
              <option value="Large">Large</option>
            </Field>
            <ErrorMessage name="size" component="div" className="text-red-500 text-xs" />
          </div>

          <div className="mb-4">
            <label htmlFor="crust" className="block text-sm font-medium text-gray-700">Crust</label>
            <Field as="select" name="crust" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
              <option value="">Select a crust</option>
              <option value="Thin">Thin</option>
              <option value="Thick">Thick</option>
              <option value="Cheese-filled">Cheese-filled</option>
            </Field>
            <ErrorMessage name="crust" component="div" className="text-red-500 text-xs" />
          </div>

          <fieldset className="mb-4">
            <legend className="text-sm font-medium text-gray-700">Toppings</legend>
            <div className="mt-2 space-y-2">
              {/* Dynamically create checkboxes for toppings */}
              {['Pepperoni', 'Sausage', 'Mushrooms', 'Onions'].map(topping => (
                <div key={topping} className="flex items-start">
                  <div className="flex items-center h-5">
                    <Field
                      type="checkbox"
                      id={topping.toLowerCase()}
                      name="toppings"
                      value={topping}
                      checked={values.toppings.includes(topping)}
                      onChange={() => {
                        if (values.toppings.includes(topping)) {
                          const nextToppings = values.toppings.filter(t => t !== topping);
                          setFieldValue('toppings', nextToppings);
                        } else {
                          const nextToppings = values.toppings.concat(topping);
                          setFieldValue('toppings', nextToppings);
                        }
                      }}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor={topping.toLowerCase()} className="font-medium text-gray-700">{topping}</label>
                  </div>
                </div>
              ))}
            </div>
          </fieldset>

          <button type="submit" className="w-full bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700">Save Pizza</button>
        </Form>
      )}
    </Formik>
  );
};

export default CreatePizzaForm;
