import React, { useContext, useEffect, useState } from "react";
import api from "../api";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useOverItem from "../hooks/useOverItem";
import { SET_XP_CHECK } from "../context/overviewcontext/action";

function PaymentSuccessPage() {
  const [loading, setLoading] = useState(true);
  const { dispatch: overViewDispatch } = useOverItem();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    // Fetching payment details from the server once the component is mounted
    const fetchPaymentDetails = async () => {
      const sessionId = new URLSearchParams(window.location.search).get(
        "session_id"
      );
      if (sessionId) {
        try {
          const response = await api.get(`/success?session_id=${sessionId}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${currentUser.token}`,
            },
          });
          if (response.status === 200) {
            setPaymentDetails(response.data);
            // setTimeout(()=>{
            //    Navigate
            // },10000)
          }
        } catch (error) {
          console.error("Error fetching payment details:", error);
        }
      }
      setLoading(false);
    };

    fetchPaymentDetails();
  }, []);

  const handleRedirect = () => {
    overViewDispatch({ type: SET_XP_CHECK });
    navigate("/");
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black flex  items-center justify-center modal">
      {loading ? (
        <div className="text-lg text-gray-600">Loading...</div>
      ) : !paymentDetails?.status ? (
        <div className="text-lg text-center text-red-500">
          Error fetching payment details. Please try again.
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center h-full w-full px-2 bg-green-100">
          <h2 className="text-2xl text-center font-semibold text-green-800 mb-2">
            Payment Successful!
          </h2>
          <span  className="text-lg text-center text-green-700">
            Thank you for your payment.
          </span>
          {/* <p className="text-lg text-green-700">
            Invoice is sent to your Email: {paymentDetails?.customer_email}
          </p> */}
          <button
            onClick={handleRedirect}
            className="px-4 py-2 bg-white text-black mt-2"
          >
            View Dashboard
          </button>
          {/* You can display other payment details here if needed */}
        </div>
      )}
    </div>
  );
}

export default PaymentSuccessPage;
