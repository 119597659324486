import React, { useState } from 'react'
import { radialbg } from '../constants/styles'
import back from '../assets/icons/back.webp'
import clock from '../assets/icons/clock.webp'
import intermidiate from '../assets/icons/intermidiate.webp'
import person from '../assets/icons/person.webp'
import check from '../assets/icons/check.webp'
import banner from '../assets/images/schedule.webp'
import shopping from '../assets/icons/shoppingbag.webp'
import archive from '../assets/icons/archive.webp'
import sync from '../assets/icons/sync.webp'
import wallet from '../assets/icons/walletmembership.webp'
import arrowdown from '../assets/icons/arrowdown.webp'
import play from '../assets/icons/playfilled.webp'
import instructor from '../assets/images/instructor.webp'
import school from '../assets/icons/school.webp'
import classs from '../assets/icons/class.webp'
const CourseCard = ({ data }) => {
    const [selected, setSelected] = useState('');
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null);
        }
        setSelected(i)
    }
    return (
        <div className="flex flex-col p-6 rounded-[2rem] gap-4" style={{ background: radialbg, fontFamily: "'Poppins',sans-serif" }}>
            <div className="flex gap-2">
                <img src={back} alt="" className='w-6 h-6' />
                <span>Courses</span>
            </div>
            <div className="flex gap-8">
                <div className="flex flex-col gap-4 flex-1">
                    <span className='text-2xl font-medium'>{data.about_course.name}</span>
                    <span className='text-sm leading-normal'>{data.about_course.short_heading}</span>
                    <div className="flex items-center gap-4">
                        <span className='text-sm leading-normal font-medium text-black py-1 px-2 bg-[#FFAB2A] rounded-lg'>Bestseller</span>
                        <span className='text-sm leading-normal font-medium'>{data.about_course.rating} ({data.about_course.total_ratings} ratings)</span>
                        <span className='text-[#84818A] text-sm leading-normal'>{data.about_course.total_student} students</span>
                    </div>
                    <div className="flex gap-4">
                        <div className="flex gap-2 items-center">
                            <img src={clock} alt="" className='w-6 h-6' />
                            <span className='text-sm leading-normal'>{data.about_course.total_time && Math.ceil(data.about_course.total_time / 3600)} total hour</span>
                        </div>
                        <div className="flex gap-2 items-center ">
                            <img src={intermidiate} alt="" className='w-6 h-6' />
                            <span className='text-sm leading-normal'>{data.about_course.difficulty && data.about_course.difficulty}</span>
                        </div>
                        <div className="flex gap-2 items-center ">
                            <img src={person} alt="" className='w-6 h-6' />
                            <span className='text-sm leading-normal'>by {data.about_instructor.name && data.about_instructor.name}</span>
                        </div>
                    </div>
                    <div className="flex flex-col p-4 rounded-2xl border border-white gap-4" style={{ background: "radial-gradient(77.86% 43.8% at 50% 50%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)" }}>
                        <span className='text-lg leading-relaxed font-medium'>What you'll learn</span>
                        <div className="flex flex-wrap gap-4">
                            {data.about_course.course_output && data.about_course.course_output.map((item, index) => (
                                <div className="flex gap-2 items-center" key={index}>
                                    <img src={check} alt="" className='w-6 h-6' />
                                    <span className='text-sm leading-normal'>{item}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 mt-8">
                        <span className='font-medium text-base text-[#009580]'>Requirements</span>
                        <ul className='list-disc p-[revert] text-sm leading-normal'>
                            {data.about_course.requirements && data.about_course.requirements.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex flex-col gap-3 mt-8">
                        <span className='font-medium text-base text-[#009580]'>Description</span>
                        <span className='text-sm leading-normal'>{data.about_course.description && data.about_course.description}</span>
                    </div>
                    <div className="flex flex-col gap-3 mt-8">
                        <span className='font-medium text-base text-[#009580]'>Course content</span>
                        <div className="flex justify-between">
                            <span className='text-sm leading-normal'>{data.course_content.sections && data.course_content.sections} sections • {data.course_content.lecture && data.course_content.lecture} lectures • {data.course_content.length && data.course_content.length/3600} hour total length</span>
                            <button className='text-[#009580] text-sm leading-normal'>Expan all</button>
                        </div>
                        <div className="flex flex-col gap-4">
                            {/* Map the crads after you get data */}
                            <div className="flex flex-col rounded-2xl border border-white p-5 cursor-pointer gap-4" style={{ background: "radial-gradient(77.86% 43.8% at 50% 50%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)" }} onClick={() => toggle(1)}>
                                <div className="flex items-center gap-4">
                                    <img src={arrowdown} alt="" className='h-6 w-6' />
                                    <span className='text-sm leading-normal font-medium'>Introduction</span>
                                    <span className='text-[#84818A] text-sm leading-normal ml-auto'>6 lectures • 26min</span>
                                </div>
                                {selected === 1 &&
                                    <div className="flex flex-col gap-4 border-t-2 border-white pt-3">
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>How to use this course</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Introduction</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Designer Shortage</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Copy Inspiration</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Make it Pretty</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Summary</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* //////////////////////////////// */}
                            <div className="flex flex-col rounded-2xl border border-white p-5 cursor-pointer gap-4" style={{ background: "radial-gradient(77.86% 43.8% at 50% 50%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)" }} onClick={() => toggle(2)}>
                                <div className="flex items-center gap-4">
                                    <img src={arrowdown} alt="" className='h-6 w-6' />
                                    <span className='text-sm leading-normal font-medium'>Introduction</span>
                                    <span className='text-[#84818A] text-sm leading-normal ml-auto'>6 lectures • 26min</span>
                                </div>
                                {selected === 2 &&
                                    <div className="flex flex-col gap-4 border-t-2 border-white pt-3">
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>How to use this course</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Introduction</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Designer Shortage</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Copy Inspiration</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Make it Pretty</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Summary</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* ///////////////////////////////// */}
                            <div className="flex flex-col rounded-2xl border border-white p-5 cursor-pointer gap-4" style={{ background: "radial-gradient(77.86% 43.8% at 50% 50%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)" }} onClick={() => toggle(3)}>
                                <div className="flex items-center gap-4">
                                    <img src={arrowdown} alt="" className='h-6 w-6' />
                                    <span className='text-sm leading-normal font-medium'>Introduction</span>
                                    <span className='text-[#84818A] text-sm leading-normal ml-auto'>6 lectures • 26min</span>
                                </div>
                                {selected === 3 &&
                                    <div className="flex flex-col gap-4 border-t-2 border-white pt-3">
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>How to use this course</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Introduction</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Designer Shortage</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Copy Inspiration</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Make it Pretty</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                        <div className="flex gap-3">
                                            <img src={play} alt="" className='h-4 w-4' />
                                            <span className='text-sm leading-normal'>Summary</span>
                                            <span className='text-[#84818A] text-sm leading-normal ml-auto'>03:34</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* ////////////////////////////////// */}
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 mt-8">
                        <span className='font-medium text-base text-[#009580]'>Instructor</span>
                        <div className="flex items-center gap-4">
                            <img src={instructor} alt="" className='h-[4.5rem] w-[4.5em] rounded-[4.5rem] border border-white' />
                            <div className="flex flex-col">
                                <span className='text-[#009580] text-sm leading-normal font-medium'>{data.about_instructor.name && data.about_instructor.name}</span>
                                <span className='text-sm leading-normal'>{data.about_instructor.designation && data.about_instructor.designation}</span>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <span className='text-[#009580] text-sm leading-normal'>{data.about_instructor.rating} rating</span>
                            <span className='flex items-center text-sm leading-normal gap-2'><img src={school} alt="" className='h-6 w-6' /> {data.about_instructor.total_student} students</span>
                            <span className='flex items-center text-sm leading-normal gap-2'><img src={classs} alt="" className='h-6 w-6' /> {data.about_instructor.total_courses} Courses</span>
                        </div>
                        <span className='text-sm leading-normal'>{data.about_instructor.description && data.about_instructor.description}</span>
                    </div>
                </div>
                <div className="flex flex-col p-4 border border-[#009580] rounded-2xl items-start gap-4 h-fit" style={{ background: "radial-gradient(77.86% 43.8% at 50% 50%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)" }}>
                    <img src={banner} alt="" className='rounded-lg w-72 h-48' />
                    <div className="flex items-center gap-2 w-full">
                        <span className='text-[#009580] font-medium text-2xl'>${data.course_payment.discount_price && data.course_payment.discount_price}</span>
                        <span className='text-sm leading-normal line-through text-[#FF686A]'>${data.course_payment.real_price && data.course_payment.real_price}</span>
                        <span className='text-sm leading-normal ml-auto'>{data.course_payment.discount}% off</span>
                    </div>
                    <button className='py-3 border border-[#009580] w-full rounded-xl text-[#009580] text-sm leading-normal' style={{ background: "radial-gradient(77.86% 43.8% at 50% 50%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)" }}>Enrolled</button>
                    <span className='text-[#009580] text-base font-medium mt-6'>This course includes:</span>
                    <div className="flex flex-col gap-3">
                        <span className='flex gap-3 items-center text-sm leading-normal'><img src={shopping} alt="" className='w-4 h-4' /> 15.5 hours on-demand video</span>
                        <span className='flex gap-3 items-center text-sm leading-normal'><img src={archive} alt="" className='w-4 h-4' /> 26 downloadable resources</span>
                        <span className='flex gap-3 items-center text-sm leading-normal'><img src={sync} alt="" className='w-4 h-4' /> Full lifetime access</span>
                        <span className='flex gap-3 items-center text-sm leading-normal'><img src={wallet} alt="" className='w-4 h-4' /> Certificate of completion</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseCard