import React, { useContext, useEffect, useRef, useState } from "react";
import xdojo from "../assets/icons/Xdojo.webp";
import profile from "../assets/images/userimg.webp";
import badge from "../assets/icons/totalpoints.webp";
import leaderboard_img1 from "../assets/images/userimg.webp";
import { TfiBackRight } from "react-icons/tfi";
import { BsBell } from "react-icons/bs";
import useOverItem from "../hooks/useOverItem";
import { QuestContext } from "../context/questContext/QuestContext";
import LockQuestModal from "../components/LockQuestModal";
import { SET_SIGLE_MODAL } from "../context/questContext/action";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowDropDown } from "react-icons/md";
import PlanText from "../components/PlanText";
import { AuthContext } from "../context/AuthContext";
import { GiMagicBroom } from "react-icons/gi";
import { IoHeart } from "react-icons/io5";

const DesignAcademics = ({ children, title, modalStyle }) => {
  const { state, setNotificationStatus } = useOverItem();
  const [notificationModal, setNotificationModal] = useState(false);

  const {
    state: { notificatioArray, notificationStatus },
  } = useOverItem();
  const [profileModal, setProfileModal] = useState(false);
  const { dispatch } = useContext(AuthContext);

  const navigate = useNavigate();
  const nullSetModal = () => {
    navigate("/questlist");
  };
  const bellRef = useRef(null);
  const modalRef = useRef(null);
  const settingRef = useRef(null);
  const modalSettingRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        bellRef.current &&
        !bellRef.current.contains(event.target)
      ) {
        setNotificationModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, bellRef]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalSettingRef.current &&
        !modalSettingRef.current.contains(event.target) &&
        settingRef.current &&
        !settingRef.current.contains(event.target)
      ) {
        setProfileModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalSettingRef, settingRef]);
  return (
    <div
      style={{ fontFamily: "'Poppins',sans-serif" }}
      className="h-screen w-full"
    >
      <div
        className={`relative w-full h-full mx-auto shadow-lg z-50 overflow-auto`}
        style={modalStyle}
      >
        {notificationModal && (
          <div
            style={{
              zIndex: 1000000000000000,
            }}
            className="absolute w-[50%] h-[60%] rounded-xl top-[8%] gap-2 right-[10%] flex flex-col bg-[#3E3769]"
          >
            {notificatioArray?.length === 0 && (
              <div className="flex items-center justify-center my-auto">
                <h1 className="text-gray-300">-No Notification-</h1>
              </div>
            )}
            {notificatioArray?.length > 0 && (
              <div
                ref={modalRef}
                className="flex flex-col px-4 py-2 gap-2 overflow-auto"
              >
                {notificatioArray?.map((item) => {
                  if (item.status === "badge") {
                    return (
                      <div className="flex flex-col gap-2 rounded-xl shadow-lg py-4 px-4">
                        <div className="flex items-center gap-2">
                          <small className="">You have won a new badge</small>
                          <GiMagicBroom size={20} className="text-yellow-300" />
                        </div>
                        {/* <small className="text-gray-400 text-[12px] font-bold">
                          {item?.name}
                        </small> */}
                        <div className="flex gap-4 items-center">
                          {/* <div className="w-12 h-12 bg-gray-500 flex items-center rounded-full justify-center">
                            <img
                              src={item?.image}
                              className="h-full w-full object-contain rounded-full"
                              alt=""
                            />
                          </div> */}
                          {/* <div className="flex flex-col text-sm text-gray-300 font-bold">
                            <h2>
                              {item?.username.length > 10
                                ? item?.username.substring(0, 10) + "..."
                                : item?.username}
                            </h2>
                            <h2>
                              {item?.name.length > 20
                                ? item?.name.substring(0, 20) + "..."
                                : item?.name}
                            </h2>
                          </div> */}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="flex flex-col gap-2 rounded-xl shadow-lg py-4 px-4">
                        <div className="flex items-center gap-2">
                          <small className="">Your Feeds got a like </small>
                          <IoHeart size={20} className="text-red-500" />
                        </div>
                        <small className="text-gray-400 text-[12px] font-bold">
                          {item.prompt}
                        </small>
                        <div className="flex gap-4 items-center">
                          <div className="w-12 h-12 bg-gray-500 flex items-center rounded-full justify-center">
                            <img
                              src={
                                item?.profilePhoto
                                  ? item?.profilePhoto
                                  : leaderboard_img1
                              }
                              className="h-full w-full object-contain rounded-full"
                              alt=""
                            />
                          </div>
                          <div className="flex flex-col text-sm text-gray-300 font-bold">
                            <h2>
                              {item?.username?.length > 10
                                ? item?.username.substring(0, 10) + "..."
                                : item?.username}
                            </h2>
                            <h2>
                              {item?.name?.length > 20
                                ? item?.name.substring(0, 20) + "..."
                                : item?.name}
                            </h2>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
        )}
        {/* header */}

        <div
          style={{
            background: `linear-gradient(to bottom right, #292157 0%, #000000 100%)`,
            fontFamily: "'Poppins',sans-serif",
          }}
          className="flex px-4 justify-between text-white"
        >
          <div className="flex h-14 gap-6 items-center sticky top-0 justify-between">
            <button
              onClick={() => {
                navigate("/academics");
              }}
              className=""
            >
              <TfiBackRight className="text-white font-extrabold" size={25} />
            </button>
            <div className="">
              <h1 className="text-[25px] font-bold">xdojo.ai</h1>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <button
              ref={bellRef}
              className="relative"
              onClick={() => {
                setNotificationModal(!notificationModal);
                setNotificationStatus(false);
                localStorage.setItem("notification", "false");
              }}
            >
              <BsBell size={20} />
              {notificationStatus && (
                <div className="absolute top-[-50%]  right-[-10%] w-4 h-4 rounded-full bg-red-600 flex justify-center items-center"></div>
              )}
            </button>

            {/* <img src={bell} alt="" /> */}

            <button
              ref={settingRef}
              onClick={() => setProfileModal(!profileModal)}
              style={{
                background: `linear-gradient(to bottom right, #292157 0%, #000000 100%)`,
              }}
              className="relative md:flex gap-2 items-center h-10 rounded-2xl border-2 border-solid border-[#FFE787] p-2"
            >
              <img
                src={
                  state.overview?.profileimg
                    ? state.overview?.profileimg
                    : profile
                }
                className="w-6 h-6 rounded-full"
                alt=""
              />

              <div>{state.overview?.xp * 10} XP</div>
              <button>
                <MdOutlineArrowDropDown size={20} />
              </button>
              {profileModal && (
                <div
                  ref={modalSettingRef}
                  style={{ zIndex: 9999999999 }}
                  className="absolute w-56 h-32 rounded-xl top-12 gap-2 right-0 flex flex-col bg-[#3E3769] z-10"
                >
                  <button
                    onClick={() => navigate("/settings")}
                    className="flex items-center h-[28%] w-full  text-gray-300 px-4 py-4 text-sm hover:bg-black hover:bg-opacity-20"
                  >
                    Settings
                  </button>
                  <button
                    onClick={() => navigate("/settings/plan&billing")}
                    className="flex items-center h-[28%] w-full  text-gray-300 px-4 py-4 text-sm hover:bg-black hover:bg-opacity-20 "
                  >
                    {/*  */}
                    Update plan
                  </button>
                  <button
                    onClick={() => {
                      dispatch({ type: "LOGOUT" });
                      localStorage.removeItem("user");
                      navigate("/");
                    }}
                    className="flex items-center  h-[28%] w-full text-gray-300 px-4 py-4 text-sm hover:bg-black hover:bg-opacity-20"
                  >
                    Logout
                  </button>
                </div>
              )}
            </button>
          </div>
        </div>

        {/* <div
            style={{ zIndex: 10000,  background: `linear-gradient(to bottom right, #292157 0%, #000000 100%)` }}
            className="relative h-12 flex items-center justify-center px-4 py-2 "
          >
        
            <div className="flex justify-center items-center">
              <h1 className="font-bold text-center text-lg text-white">
                {title}
              </h1>
            </div>
          </div> */}

        {/* {((state.overview?.payment_status === false && singleQuest?.index  > 5) ||
          (state.overview?.payment_status === true &&
            singleQuest?.index > state.overview?.total_unlock_quest + 5)) && (
          <LockQuestModal setModal={nullSetModal} />
        )} */}
        <div className="w-full h-[calc(100vh-3.5rem)] overflow-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default DesignAcademics;
