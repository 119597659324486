import React, { createContext, useContext, useReducer } from "react";
import { AuthContext } from "../AuthContext";
import { SET_FEED_LIST, SET_GET_FEED, SET_SINGLE_DELETE_LIST, SET_UPDATE_LIST } from "./action";
import useOverItem from "../../hooks/useOverItem";

export const FeedContext = createContext();

const initialState = {
  feeds: [],
  loading: false,
  getFeed:false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_FEED_LIST: {
      return { ...state, feeds: action.payload };
    }
    case SET_GET_FEED: {
      return { ...state, getFeed: action.payload };
    }
    case SET_SINGLE_DELETE_LIST: {
      return { ...state, feeds:state.feeds.filter(e=>e._id.toString()!==action.payload.id.toString()) };
    }
    case SET_UPDATE_LIST: {
      // Find the index of the item you want to update
      const index = state.feeds.findIndex(
        (feed) => feed._id.toString() === action.payload.id.toString()
      );

      // If the item was found
      if (index !== -1) {
        // Use the spread operator to create a new array
        // And update the item at the found index
        const updatedFeeds = [
          ...state.feeds.slice(0, index), // Elements before the item
          {
            ...state.feeds[index],
            likeCount: state.feeds[index].isLike
              ? state.feeds[index].likeCount - 1
              : state.feeds[index].likeCount + 1,
            isLike: !state.feeds[index].isLike,
          },
          ...state.feeds.slice(index + 1), // Elements after the item
        ];

        return {
          ...state,
          feeds: updatedFeeds,
        };
      }
    }

    default:
      return state;
  }
};
const FeedProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const { state } = useOverItem();
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser?.token}`,
  };

  const [feedState, feedDispatch] = useReducer(reducer, initialState);

  const value = {
    feedState,
    feedDispatch,
  };

  return <FeedContext.Provider value={value}>{children}</FeedContext.Provider>;
};

export default FeedProvider;
