import React, { useContext } from "react";
import profile from "../assets/images/userimg.webp";
import { NavLink, Outlet, useMatch } from "react-router-dom";
import { radialbg } from "../constants/styles";
import { AuthContext } from "../context/AuthContext";
import useOverItem from "../hooks/useOverItem";
const Settings = () => {
  const { currentUser } = useContext(AuthContext);
  const { state } = useOverItem();
  return (
    <div className="flex flex-col gap-6 w-[90%]">
      <div
        className="rounded-[2rem] h-[7.8rem] w-[80%] shrink-0"
        style={{ background: radialbg }}
      >
        <div className="flex  p-[0.62rem] px-[3.56rem] gap-4">
          <div>
            <img
              className="rounded-full"
              style={{ width: "7rem", height: "7rem" }}
              src={
                state.overview.profileimg ? state.overview.profileimg : profile
              }
              alt=""
            />
            {/* <img
                            src={camera}
                            className="relative top-[-34px] left-[69%] flex"
                            alt=""
                        /> */}
          </div>
          <div className="flex-col gap-2 my-6">
            <div className="text-var-5 font-medium text-[1.5rem] leading-1.625">
              {state.overview?.username?.length > 9
                ? state.overview?.username.substring(0, 9) + "..."
                : state.overview?.username}
            </div>
            <div className="text-var-5 font-medium text-xs underline">
              {state.overview?.email}
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col  flex-shrink-0 rounded-[1rem]"
        style={{ background: radialbg }}
      >
        <div className="flex gap-[4.2rem] my-9 justify-center text-[#009580] font-medium text-base ">
          <NavLink
            to="/settings"
            className={
              useMatch("/settings")
                ? "underline decoration-[#009580] underline-offset-8 text-white"
                : ""
            }
          >
            Profile
          </NavLink>
          <NavLink
            to="password"
            className={({ isActive, isPending }) =>
              isPending
                ? "pending"
                : isActive
                ? "underline decoration-[#009580] underline-offset-8 text-white"
                : ""
            }
          >
            Password
          </NavLink>
          <NavLink
            to="plan&billing"
            className={({ isActive, isPending }) =>
              isPending
                ? "pending"
                : isActive
                ? "underline decoration-[#009580] underline-offset-8 text-white"
                : ""
            }
          >
            Plan & Billing
          </NavLink>
          {/* <NavLink to='paymentmethods' className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "underline decoration-[#009580] underline-offset-8 text-white" : ""}>Payment Methods</NavLink> */}
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default Settings;
