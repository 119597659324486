import React from 'react'
import { SlClose } from 'react-icons/sl'
import useOverItem from '../../hooks/useOverItem';
import { motion } from 'framer-motion';
import { SET_QUEST_MESSAGE } from '../../context/overviewcontext/action';
import styles from "../../styles/UnlockModal.module.css"
import VideoImageImports from '../../assets';
const UnlockModal = () => {
  const { quest_type_obj } = VideoImageImports;
  const {state,dispatch}=useOverItem()
  const modalVariants = {
    hidden: { y: '-100vh', opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    exit: { y: '-100vh', opacity: 0, transition: { duration: 1 } }
  };
  const handleClose = () => {
    // You'll need to implement modal closing logic here
    dispatch({type:SET_QUEST_MESSAGE,payload:""})
  };
  return (
    <motion.div 
    style={{ zIndex: 100000000 }}
    initial="hidden"
    animate={state.streakDays.length ? "visible" : "exit"}
    variants={modalVariants}
    className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center`}
  >
    <div className="text-white bg-black p-4 flex flex-col justify-center items-center rounded-lg shadow-lg h-[90%] w-[80%]">
      <button className="text-white absolute top-[10%] right-[15%] cursor-pointer" onClick={handleClose}>
        <SlClose size={30}/>
      </button>
      <h3  className={`${styles.shiny_golden_text} text-[30px]`}>{state.questMessage}</h3>
      <div className="flex justify-center items-center w-64 h-64">
        <img 
          src={quest_type_obj[state?.quest?.quest_type]?.badge_image} 
          alt="badgeImage" 
          className={`${styles.rotating_image} w-full h-full`}
        />
      </div>
    </div>
  </motion.div>
        
  )
}

export default UnlockModal