import React, { useContext, useEffect, useRef, useState } from "react";
import DesignQuest from "../../layouts/DesignQuest";
import { UseCopyText } from "../../hooks/useCopyText";
import AIBG from "../../assets/screens/aifreecoding/aifreecodingbg.webp";
import mobile from "../../assets/screens/aifreecoding/mobile.webp";
import { SyncLoader } from "react-spinners";
import CreatePizzaForm from "../../components/CreatePizzaForm";
import TextGenerator from "./TextGenerator";
import useFreeStyleCoding from "../../hooks/useFreeStyleCoding";
import useFreeStyletext from "../../hooks/useFreeStyletext";
import LoaderVideo from "../../assets/video/lodervideo.mp4";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import { useCountdownTimer } from "../../hooks/useCountdownTimer";
import useOverItem from "../../hooks/useOverItem";
import VideoModal from "../../components/VideoModal";
import { useParams } from "react-router-dom";
import { QuestContext } from "../../context/questContext/QuestContext";
import { useFeed } from "../../hooks/useFeed";
import ShareCompoenents from "../screencomponent/ShareCompoenents";
const DesignFreeStyleCoding = ({ setModal }) => {
  const {quest_type}=useParams()
  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0
  // const [historyModal,setHistoryModal]=useState(false)
  //  const [desc, setDesc] = useState("");
  //  const [button, setButton] = useState([]);
  const { minutes, seconds, startTimer, stopTimer, resetTimer } =
    useCountdownTimer();
  const { state } = useOverItem();
  const { freeStyleCodeText, textData, textLoad, errorMessage } =
    useFreeStyletext();
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  const { errorState } = useContext(ErrorContext);

  const [feedModal, setFeedModal] = useState(false);
  const {
    questState: { questPrompt },
  } = useContext(QuestContext);
  const {
    questDispatch,
  } = useContext(QuestContext);
  const { postFeed, postSent } = useFeed();

  //  const handleButton = (butt) => {
  //    if (button.includes(butt)) {
  //      setButton(button.filter((e) => e !== butt));
  //    } else {
  //      setButton([...button, butt]);
  //    }
  //  };

  // useEffect(() => {
  //   if (ImageUrl) {
  //     freeStyleCodeText({url:ImageUrl});
  //   }
  // }, [ImageUrl]);
  useEffect(() => {
    if (textLoad) {
      startTimer();
    }
    if (!textLoad && textData) {
      resetTimer();
    }
  }, [textLoad]);
  
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
}, []);
  const modalStyle = {
    backgroundImage: `url(${AIBG})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };
  const iframeRef = useRef(null);

  useEffect(() => {
    if (textData) {
      const iframe = iframeRef.current;
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      iframeDoc.open();
      const style = `
        ::-webkit-scrollbar {
          width: 0;
        }
        /* Add other styles here if needed */
      `;
      // Include the style tag in the document's head
      iframeDoc.write(`<head><style>${style}</style></head>`);
      // Write the rest of the content
      iframeDoc.write(`<body>${textData}</body>`);
      iframeDoc.close();
    }
  }, [textData]);
  useEffect(() => {
    const updateDimensions = () => {
      if (iframeRef.current) {
        setImgDimensions({
          width: iframeRef.current.offsetWidth,
          height: iframeRef.current.offsetHeight,
        });
      }
    };

    // Update dimensions initially
    updateDimensions();

    // // Optional: Update dimensions on window resize
    // window.addEventListener('resize', updateDimensions);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  return (
    <DesignQuest
      modalStyle={modalStyle}
      title="Your Coding Assistant"
    >

      {errorState.errorModal && <LimitErrorModal />}
      {feedModal && (
        <ShareCompoenents
          feedModal={feedModal}
          setFeedModal={setFeedModal}
          body={{
            title: quest_type,
            text: textData,
            userPrompt: questPrompt,
          }}
          postFeed={postFeed}
          postSent={postSent}
        />
      )}
      {!state.overview?.videoArray?.find(
        (e) => e.name === "freestylecoding" && e.isCompleted === true
      ) && (
        <VideoModal
          isOpen={
            !state.overview?.videoArray?.find(
              (e) => e.name === "freestylecoding" && e.isCompleted === true
            )
          }
          videoSrc="freestylecoding"
        />
      )}
      {/* {
      historyModal&&<AIHistoryModal setHistoryModal={setHistoryModal} loading={aiHisLoading} data={aihistory} />
    } */}

      <div
        style={{ zIndex: 10000 }}
        className="flex gap-10 justify-center items-center px-2"
      >
        <div className="relative rounded-xl flex-shrink w-[55%] h-[72vh]">
          <div
            style={{
              background: "linear-gradient(45deg, #61348b, #4e507a, #61348b)",
              lineHeight: 1.8,
            }}
            className="relative text-yellow-500 rounded-xl flex-shrink  w-full px-8 py-4 h-full"
          >
            <TextGenerator
              errorMessage={errorMessage}
              freeStyleCodeText={freeStyleCodeText}
              textData={textData}
              textLoad={textLoad}
              imgDimensions={imgDimensions}
              feedModal={feedModal}
              setFeedModal={setFeedModal}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6 items-center py-1 px-4 rounded-xl flex-shrink w-[60vh]  h-[95vh]">
          <div className="relative flex flex-col gap-6 items-center py-1 px-4 rounded-xl flex-shrink w-full  h-full">
            <img src={mobile} className="w-full h-full" alt="" />
            <div className="absolute top-[10%] flex flex-col overflow-scroll w-[70%] h-[82%] mt-[8%] text-black rounded-lg px-4 py-4">
              {textLoad && (
                <h2 className="text-center text-red-600">{`${minutes
                  .toString()
                  .padStart(2, "0")}:${seconds
                  .toString()
                  .padStart(2, "0")}`}</h2>
              )}

              {textLoad ? (
                <video
                  autoPlay
                  loop
                  muted
                  style={{
                    zIndex: 10000,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "5%",
                    width: "100%", // Keeps width responsive to the parent
                    height: "90%", // Adjusts height to match parent div
                    objectFit: "cover", // Covers the entire area of the parent
                  }}
                >
                  <source src={LoaderVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <iframe
                  title="mobile_iframe"
                  ref={iframeRef}
                  sandbox="allow-same-origin allow-scripts"
                  className="rounded-lg block mt-[-5%] w-full h-[100%]"
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </div>
    </DesignQuest>
  );
};

export default DesignFreeStyleCoding;
