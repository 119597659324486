import React, { useEffect, useState } from "react";
import { format } from "date-fns";
// import profile from "../assets/icons/Settings/profile.svg";
import profile from "../assets/images/userimg.webp";
import { SyncLoader } from "react-spinners";
import moment from "moment";
import useOverItem from "../hooks/useOverItem";
import { useImageUpload } from "../hooks/useImageUpload";
import PricingPlan from "../components/PricingPlan";
import CalenderPop from "../components/popups/CalenderPop";
import { Tooltip } from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";
import UnlockModal from "../components/popups/UnlockModal";
import Modal from "react-modal";
import LeaderBoardPage from "../components/LeaderBoardPage";
import ActivityPage from "../components/ActivityPage";
import { textcolor } from "../constants/styles";
import MIlestoneList from "../components/MIlestoneList";
import { LazyLoadImage } from "react-lazy-load-image-component";
import VideoImageImports from "../assets";
import PaymentPlanButton from "../components/mobile/paymetplan/PaymentPlanButton";
import MobileError from "../components/mobile/MobileError";
Modal.setAppElement("#root");
const streakSystemInfo = (
  <div className="text-sm text-white rounded-3xl px-4 py-4">
    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
      Streak System:
    </div>
    <ul className="list-disc pl-5">
      <li>Daily Visit: 10 xp</li>
      <li>
        <span>Streak Bonuses:</span>
        <ul className="list-disc pl-4 mt-1">
          <li>5-Day Streak Bonus: 50 xp</li>
          <li>10-Day Streak Bonus: 100 xp</li>
          <li>15-Day Streak Bonus: 150 xp</li>
          <li>And so on...</li>
        </ul>
      </li>
    </ul>
    <h2 className="text-lg leading-6 font-medium text-indigo-500">Rules:</h2>
    <ul className="list-disc pl-5 mt-2">
      <li>Each day of consecutive visits earns 10 xp.</li>
      <li>Achieve a 5-day streak for an additional 50 xp bonus.</li>
      <li>Reach a 10-day streak for an extra 100 xp bonus.</li>
      <li>
        Streak bonuses continue to increase in increments of 50 xp for every
        additional 5 consecutive days.
      </li>
      <li>If the streak breaks, reset to zero.</li>
      <li>Streaks are counted based on calendar days.</li>
    </ul>
    <p className="mt-2">Keep the streak alive for maximum rewards!</p>
  </div>
);

const XPCard = (
  <div className="max-w-md mx-auto text-white rounded-xl shadow-md overflow-hidden md:max-w-2xl my-5">
    <div className="p-8">
      <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
        Total XP System
      </div>
      <ul className="list-disc pl-5 mt-3">
        <li>
          <strong>First Occurrence:</strong> 10 xp
        </li>
        <li>
          <strong>Repeat Occurrence (after 3 times):</strong> Additional 10 xp
        </li>
      </ul>
      <div className="mt-4">
        <h2 className="text-lg leading-6 font-medium text-indigo-500">
          Rules:
        </h2>
        <ul className="list-disc pl-5 mt-2 ">
          <li>Earn 10 xp on the first occurrence of a task.</li>
          <li>
            For every subsequent occurrence (after 3 times), get an additional
            10 xp.
          </li>
          <li>Keep track of your total XP for more rewards!</li>
        </ul>
      </div>
    </div>
  </div>
);

const BadgeSystemCard = (
  <div className="max-w-md mx-auto text-white rounded-xl shadow-md overflow-hidden md:max-w-2xl my-5">
    <div className="p-8">
      <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
        Badge System
      </div>
      <ul className="list-disc pl-5 mt-3">
        <li>
          <strong>Quest Completion Badge:</strong> Earned upon completing any
          quest.
        </li>
      </ul>
      <div className="mt-4">
        <h2 className="text-lg leading-6 font-medium text-indigo-500">
          Rules:
        </h2>
        <ul className="list-disc pl-5 mt-2 ">
          <li>
            Successfully complete a quest to earn the Quest Completion Badge.
          </li>
          <li>
            Collect and showcase your badges as a testament to your
            achievements.
          </li>
          <li>Explore and conquer quests to unlock a variety of badges.</li>
          <li>Keep questing to build your badge collection!</li>
        </ul>
      </div>
    </div>
  </div>
);

const TotalQuestsCard = (
  <div className="max-w-md mx-auto text-white rounded-xl shadow-md overflow-hidden md:max-w-2xl my-5">
    <div className="p-8">
      <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
        Total Quests Completed
      </div>
      <p className="mt-3">
        This reflects the overall count of quests successfully accomplished to
        date.
      </p>
      {/* Here you can dynamically display the count of completed quests */}
      {/* <div className="mt-4 text-lg font-medium">
            <span className="text-green-500">Number of Quests Completed: </span> */}
      {/* Replace with dynamic data */}
      {/* <span className="text-black">0</span> 
          </div> */}
    </div>
  </div>
);

const paymentArrayButton = [
  {
    title: "Gold",
  },
  {
    title: "Premium",
  },
];
const Overview = () => {
  const { quest_type_obj } = VideoImageImports;
  const [paymentButton, setPaymentButton] = useState("Gold");
  const [previewURL, setPreviewURL] = useState(null);
  const [image, setImage] = useState(null);
  const { state, Overview } = useOverItem();
  const [profilePage, setProfilePage] = useState(0);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const MonthYear = (timestamp) => {
    const date = new Date(timestamp);

    // Format the date to show only the month and year
    const formattedDate = format(date, "MMMM yyyy");
    return formattedDate;
  };
  const DayMonthYear = (timestamp) => {
    // Convert the timestamp to a moment object
    const date = moment(timestamp);

    // Format the date to show only the month and year
    const formattedDate = date.format("DD MMMM YYYY");
    return formattedDate;
  };
  const { ImageUpload, imgLoading } = useImageUpload();
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   Overview();
  // }, [postStatus]);
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setImage(selectedFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Reset image and previewURL if no file is selected
      setImage(null);
      setPreviewURL(null);
    }
  };

  if (!state.loading && !state.overview?.payment_plan) {
    const goldFeatures = [
      "Unlimited access to AI teacher",
      "Unlimited access to Quest",
      "Participation in daily challenges",
    ];

    const premiumFeatures = [
      "Everything in the Gold Plan",
      "Access to interactive gamified task",
      "Personalized learning calls on a monthly basis",
    ];
    const freeFeatures = [
      "Unlimed access to AI teacher",
      "Limted Access to Quest",
      "Limted access to Code Assistant",
    ];

    return (
      <div
        style={{ zIndex: 100000000000000 }}
        className="fixed inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center transition-opacity duration-300 ease-in-out"
      >
        <div
          // style={{ background: bg-gray-800 }}
          className="md:p-2 pt-8 md:pt-8 bg-black shadow-lg flex flex-col items-center justify-center gap-4 w-full h-full overflow-auto transform transition-transform duration-300 ease-out"
        >
          {/* <div
            style={{ lineHeight: 1.9 }}
            className="text-gray-200 uppercase text-[30px] text-center w-2/5 font-semibold p-4"
          >
            Subscribe To Proceed with your Incredible Journey!
          </div> */}
          <h1 className="text-[32px] font-bold">Choose your Plan</h1>
          <div
            style={{
              background: "linear-gradient(180deg, #CCE3FF 0%, #EDEDED 100%)",
              borderRadius: "25px 25px 0px 0px",
            }}
            className="md:hidden px-2 py-4 gap-6 flex flex-col items-center w-full h-full overflow-auto transform transition-transform duration-300 ease-out scale-95"
          >
            <div className="flex gap-4 items-center justify-center w-full">
              {paymentArrayButton?.map((e) => (
                <PaymentPlanButton
                  key={e.title}
                  setPaymentButton={setPaymentButton}
                  paymentButton={paymentButton}
                  title={e.title}
                />
              ))}
            </div>
            {paymentButton === "Gold" && (
              <PricingPlan
                plan="Gold"
                price={30}
                features={goldFeatures}
                isPremium={false}
                seven_days_trail={state.overview?.seven_days_trail}
                payment_status={state.overview?.payment_status}
                prev_Plan={state.overview?.payment_plan}
              />
            )}
            {paymentButton === "Premium" && (
              <PricingPlan
                plan="Premium"
                price={60}
                features={premiumFeatures}
                isPremium={true}
                seven_days_trail={state.overview?.seven_days_trail}
                payment_status={state.overview?.payment_status}
                prev_Plan={state.overview?.payment_plan}
              />
            )}
          </div>
          <div className="md:flex hidden gap-4 lg:gap-10 justify-center w-full h-full overflow-auto transform transition-transform duration-300 ease-out scale-95">
            {/* <PricingPlan
              plan="Free"
              price={0}
              features={freeFeatures}
              isPremium={false}
              seven_days_trail={state.overview?.seven_days_trail}
              payment_status={state.overview?.payment_status}
              prev_Plan={state.overview?.payment_plan}
            /> */}
            <PricingPlan
              plan="Gold"
              price={30}
              features={goldFeatures}
              isPremium={false}
              seven_days_trail={state.overview?.seven_days_trail}
              payment_status={state.overview?.payment_status}
              prev_Plan={state.overview?.payment_plan}
            />
            <PricingPlan
              plan="Premium"
              price={60}
              features={premiumFeatures}
              isPremium={true}
              seven_days_trail={state.overview?.seven_days_trail}
              payment_status={state.overview?.payment_status}
              prev_Plan={state.overview?.payment_plan}
            />
          </div>
        </div>
      </div>
    );
  }

  let progress = state.overview?.xp * 10;
  let size = 120;
  let strokeWidth = 6;
  let strokeColor = "#FFE787";
  // Determine the current range based on progress
  let step = 500;
  const currentRangeStart = Math.floor(progress / step) * step;
  const currentRangeEnd = currentRangeStart + step;
  const adjustedProgress = progress - currentRangeStart;

  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (adjustedProgress / step) * circumference;

  const now = new Date();
  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  let greeting;
  if (hours < 12) {
    greeting = "Good Morning";
  } else if (hours >= 12 && hours < 17) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return (
    <>
       <MobileError/>


    <div className="hidden md:flex flex-col gap-4 justify-between lg:h-full">
      {state.streakMessage ? <CalenderPop /> : ""}
      {state.questMessage ? <UnlockModal /> : ""}
      {/* {!state.loading &&
        !state.overview?.videoArray?.find(
          (e) => e.name === "dashboard" && e.isCompleted === true
        ) && (
          <VideoModal
            isOpen={
              !state.overview?.videoArray?.find(
                (e) => e.name === "dashboard" && e.isCompleted === true
              )
            }
            videoSrc="dashboard"
          />
        )} */}
      {/* {<CalenderPop />} */}
      {state.loading ? (
        <div
          style={{
            zIndex: 100000000,
            background: `linear-gradient(to bottom right, #292157 0%, #000000 100%)`,
          }}
          className="fixed top-0 left-0 w-full h-full bg-black flex  items-center justify-center modal"
        >
          <SyncLoader
            color="#36d7b7"
            style={{
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              marginTop: "30px",
            }}
          />
        </div>
      ) : (
        <>
          <div className="flex justify-between flex-col lg:flex-row  h-[95vh] lg:h-[59vh] gap-4 lg:gap-0 items-center">
            {/* profile div */}
            <div
              className="flex flex-col justify-between bg-[#2c2654] items-between shadow-xl rounded-[5%] h-[60%] lg:min-h-full w-full lg:w-[33%] py-4 3xl:px-10 3xl:py-10 px-6"
              style={{
                fontFamily: "'Poppins',sans-serif",
              }}
            >
              <div className="flex gap-6">
                <button
                  onClick={() => setProfilePage(0)}
                  className={`${
                    profilePage === 0 ? "text-white" : "text-gray-500"
                  }  text-sm`}
                >
                  Profile
                </button>
                <button
                  onClick={() => setProfilePage(1)}
                  className={`${
                    profilePage === 1 ? "text-white" : "text-gray-500"
                  } text-sm`}
                >
                  Leaderboard
                </button>
              </div>
              {profilePage === 0 && (
                <>
                  <div className="flex gap-6 items-center px-4 py-2 mt-4">
                    {/* image div */}
                    <div className="flex relative flex-col items-center">
                      <svg className="w-full h-full" width={size} height={size}>
                        {/* Circle as a background */}
                        <circle
                          className="text-gray-500"
                          fill="transparent"
                          strokeWidth={strokeWidth}
                          stroke="currentColor"
                          cx={size / 2}
                          cy={size / 2}
                          r={radius}
                        />

                        {/* Clipped image inside the circle */}
                        <defs>
                          <clipPath id="circleView">
                            <circle
                              cx={size / 2}
                              cy={size / 2}
                              r={radius - strokeWidth / 2}
                              fill="transparent"
                            />
                          </clipPath>
                        </defs>
                        <image
                          href={state.overview?.profileimg || profile}
                          clipPath="url(#circleView)"
                          x={strokeWidth / 2}
                          y={strokeWidth / 2}
                          height={size - strokeWidth}
                          width={size - strokeWidth}
                          preserveAspectRatio="xMidYMid slice"
                        />

                        {/* Foreground progress circle */}
                        <circle
                          fill="transparent"
                          strokeWidth={strokeWidth}
                          stroke={strokeColor}
                          strokeDasharray={circumference}
                          strokeDashoffset={-offset}
                          strokeLinecap="round"
                          transform={`rotate(-90 ${size / 2} ${size / 2})`}
                          cx={size / 2}
                          cy={size / 2}
                          r={radius}
                        />
                      </svg>
                      <div className="absolute flex text-black justify-center items-center text-[10px] font-bold bg-[#FFE787] bottom-[-2%] right-[8%] rounded-full w-[100px] h-6">
                        <h4>
                          {state.overview?.xp * 10}/{currentRangeEnd} XP
                        </h4>
                      </div>
                    </div>

                    {/* points div */}
                    <div className="flex t flex-col gap-1 mt-[-2%]">
                      <div>
                        <h1 className="text-gray-500 text-[14px]">
                          {greeting},{" "}
                        </h1>
                      </div>
                      <div>
                        <h1 className="text-white text-[20px] font-bold">
                          {state.overview?.username?.length > 9
                            ? state.overview?.username.substring(0, 10) + "..."
                            : state.overview?.username}
                        </h1>
                      </div>
                      <div>
                        <h1 className="text-[#FFE787] text-[16px] font-bold">
                          Level {Math.floor((state.overview?.xp * 10) / 100)}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 items-center mt-4 px-6">
                    <div className="text-sm text-gray-500 font-bold">
                      <h1>Achievments</h1>
                    </div>
                    <div
                      className={`w-8 h-8 border-2 border-[${textcolor}] text-[${textcolor}] text-sm font-bold rounded-full flex justify-center items-center`}
                    >
                      <h2>{state.overview?.questCompletedArray?.length + 1}</h2>
                    </div>
                  </div>
                  <div className="flex gap-2 items-center mt-4 px-4 flex-wrap">
                    <div className="w-10 h-10 rounded-full flex justify-center items-center">
                      <LazyLoadImage
                        alt="Background"
                        threshold={250} // This is where you specify the threshold
                        src={quest_type_obj.singup.badge_image}
                        effect="blur"
                        wrapperClassName="w-full h-full rounded-full object-contain"
                        className="w-full h-full rounded-full object-contain"
                      />
                    </div>

                    {state.overview?.questCompletedArray?.map((e, i) => {
                      if (i > 13) {
                        return "";
                      }
                      if (i >= 13) {
                        return (
                          <button className="bg-transparent ml-2 w-16 h-8 text-[10px] rounded-full border-2 border-gray-500">
                            View all
                          </button>
                        );
                      }
                      return (
                        <div className="w-10 h-10 rounded-full flex justify-center items-center">
                          <LazyLoadImage
                            alt="Background"
                            threshold={250} // This is where you specify the threshold
                            src={
                              quest_type_obj[e?.quest?.quest_type]?.badge_image
                            }
                            effect="blur"
                            wrapperClassName="w-full h-full rounded-full object-contain"
                            className="w-full h-full rounded-full object-contain"
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex justify-between items-center px-6 mt-6">
                    <div className="flex gap-1 items-center text-sm text-gray-500 font-bold">
                      <h1>Streak</h1>
                      <FaInfoCircle className="" data-tooltip-id="streakTip" />
                      <Tooltip style={{ zIndex: 10000000000 }} id="streakTip">
                        {streakSystemInfo}
                      </Tooltip>
                    </div>
                    <div className="flex gap-1 items-center text-sm text-gray-500 font-bold">
                      <h1>Quests Completed</h1>
                      <FaInfoCircle className="" data-tooltip-id="questTip" />
                      <Tooltip
                        style={{ zIndex: 10000000000 }}
                        place="top-start"
                        id="questTip"
                      >
                        {TotalQuestsCard}
                      </Tooltip>
                    </div>
                  </div>
                  <div className="flex items-center gap-80 lg:gap-20 px-6 mt-2">
                    <div className="flex flex-1 items-center text-sm text-gray-500 font-bold">
                      <h2 className="text-[#FB7185]">
                        {state?.overview?.dailyCount}-Day Streak
                      </h2>
                    </div>
                    <div className="flex flex-1 font-bold items-center text-md text-white">
                      <h2>{state.overview?.questCompletedArray?.length}</h2>
                    </div>
                  </div>
                </>
              )}
              {profilePage === 1 && (
                <div className="h-full">
                  <LeaderBoardPage />
                </div>
              )}
            </div>

            {/* Activity div */}

            <ActivityPage />
          </div>
        </>
      )}
      {!state.loading && <MIlestoneList />}

      {/* <Livechat /> */}
    </div>
    </>
  );
};

export default Overview;
