import { useStripe } from "@stripe/react-stripe-js";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import api from "../api";
import useOverItem from "./useOverItem";
import { SET_OVERVIEW } from "../context/overviewcontext/action";

export const useCheckout=()=>{
    const [loading,setLoading]=useState()
    const stripe = useStripe();
    const { currentUser } = useContext(AuthContext);
    const [referral,setReferral]=useState(null)
    const {dispatch,state}=useOverItem()
    useEffect(() => {
      window.rewardful('ready', () => {
        if(window.Rewardful.affiliate) {
          setReferral(window.Rewardful.referral);
        }
      });
    }, []);
    const handleCheckout = async ({plan,amount,unit}) => {
        
        setLoading(true);
        if(plan==="Free"){
            try{
             let res=await api.get('/check_free_plan',{
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${currentUser.token}`,
                },
              })
              if(res.status===200){
                dispatch({
                    type:SET_OVERVIEW,
                    payload:{
                        ...state.overview,payment_plan:res.data.payment_plan
                    }
                })
              }
            
            }
            catch(err){
              console.log(err)
            }
           
          }
          else{
        try {
          console.log("hello");
          const response = await api.post(
            "/create-checkout-session",
            {
              amount,
              unit,
              referral
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${currentUser.token}`,
              },
            }
          );
          console.log(response.data);
          const result = await stripe.redirectToCheckout({
            sessionId: response.data.id,
          });
    
          if (result.error) {
            console.error(result.error.message);
          }
        } catch (error) {
          console.error(
            "There was an error redirecting to Stripe Checkout:",
            error
          );
        }
    }
        setLoading(false);
      };

      return {handleCheckout,loading}


}