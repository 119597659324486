import React from "react";
import { SyncLoader } from "react-spinners";
import { UseCopyText } from "../../hooks/useCopyText";

const GeneratedCode = ({ data, setCodeModal, loading }) => {
  const onClose = () => setCodeModal(false);
  const { copyText } = UseCopyText();

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-10 mt-12">
      <div className="relative  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-5 border w-4/5 h-4/5 shadow-lg rounded-md bg-white overflow-y-auto z-10000">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-3 text-3xl leading-none font-semibold text-gray-600 hover:text-gray-900"
        >
          &times;
        </button>
        <h2 className="text-xl font-bold text-gray-800 mb-5">Your HTML CODE</h2>
        {loading ? (
          <SyncLoader
            color="#36d7b7"
            style={{
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              marginTop: "30px",
            }}
          />
        ) : (
          ""
        )}
         <button
              disabled={!data}
              onClick={() => copyText(data)}
              style={{ border: "1px solid black" }}
              className="w-24 absolute bottom-[5%] right-[4%] h-6 uppercase text-black outline-none rounded-md text-sm  shadow-2xl bg-gray-200"
            >
              Copy text
            </button>
        {!loading && (
          <div className="mb-4 relative max-h-[60vh] overflow-scroll py-4 bg-black px-10">
            {data.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
                <br />
              </span>
            ))}
           
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneratedCode;
