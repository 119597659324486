import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import api from "../api.js";
import { toast } from "react-toastify";
import { ErrorContext } from "../context/errorcontext/ErrorContext.js";
import {
  SET_ERROR_MESSAGE,
  SET_ERROR_MODAL,
} from "../context/errorcontext/action.js";
import useOverItem from "./useOverItem.js";
import { SET_XP_CHECK } from "../context/overviewcontext/action.js";
import { SET_QUEST_PROMPT } from "../context/questContext/action.js";
import { QuestContext } from "../context/questContext/QuestContext.js";
export const useGenerateImage = () => {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [images, setImages] = useState({});
  const { dispatch } = useContext(ErrorContext);
  const {
    questDispatch,
  } = useContext(QuestContext);
  const {dispatch:overViewDispatch,notificationPop,state}=useOverItem()
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser.token}`,
  };
  const fetchImage = async ({
    button,
    setButton,
    quest_type,
    desc,
    setDesc,
    color,
    setColor,
    width,
    height,
    num,
    carType,
    setCarType,
  }) => {
    setLoading(true);

    try {
      console.log(width, height);
      const prompt = `create a car of name ${carType} of color ${color} having ${button.join(
        ","
      )} and  description as ${desc} `;
      questDispatch({type:SET_QUEST_PROMPT,payload:prompt})
      let res = await api.post(
        "/quest/imagegenaration",
        {
          prompt: prompt,
          quest_type,
          width,
          height,
          num: 4,
        },
        {
          headers,
        }
      );
      if (res.status === 400) {
        setLoading(false);
        toast.error(res.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        return;
      }
     
      setImages(res.data.imageArray.generations_by_pk);
      setButton([]);
      setDesc("");
      setColor("");
      setCarType("");
      
      if(!state?.overview?.questCompletedArray?.some(e=>e.quest.quest_type===quest_type)){
        notificationPop({
          status:"badge"
        })

      }
      overViewDispatch({type:SET_XP_CHECK})
    } catch (err) {
      console.log(err);
      if (err.response.status === 400) {
        dispatch({ type: SET_ERROR_MODAL, payload: true });
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: err.response.data.message,
        });
      } else {
        toast.error(err.response.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        console.log(err);
      }
    }
    setLoading(false);
  };
  return { fetchImage, loading, images, errorMessage };
};
