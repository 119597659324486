import { radialbg } from '../constants/styles'
import statusicon from '../assets/icons/questbaricon.webp'
import { Outlet } from 'react-router-dom'
import Livechat from '../components/Livechat'

const Quest = () => {

    return (
        <div className="flex flex-col gap-6">
            <div className="flex rounded-[2rem] p-6 py-7 items-center gap-6" style={{ background: radialbg }}>
                <img src={statusicon} alt="icon" className='w-[4.5rem] h-[4.5rem]' />
                <div className="flex flex-col gap-3">
                    <span className='text-[#009580] text-sm leading-normal'>Task 1</span>
                    <span className=' text-lg leading-relaxed font-medium'>Learn how to write  podcast script</span>
                </div>
                <div className="flex flex-col items-center ml-auto gap-3">
                    <button className='bg-[#009580] rounded-xl text-sm leading-normal font-medium p-2'>Available at 7:30 AM</button>
                    <span className='text-sm leading-normal'>00 : 06 : 23</span>
                </div>
            </div>
            <div className='h-96 rounded-lg' style={{ background: radialbg }}>

            </div>
            
            <Outlet />
            {/* <Livechat /> */}
        </div>
    )
}

export default Quest