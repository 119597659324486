import React from 'react'
import { Link } from 'react-router-dom'
import google from '../assets/icons/google.webp'
const FreeTrial = () => {
    return (
        <div className="flex flex-col gap-3 items-center" style={{ fontFamily: "'Inter', sans-serif" }} >
            <span className='text-[2.5rem] font-normal text-white' style={{ fontFamily: 'Space Grotesk, sans-serif' }}>Get Free Trial</span>
            <span className='leading-[1.875rem] text-white text-[1.125rem] font-light'>Already a user? <Link className='text-[#99D6EA]' to='/auth'><u>Sign In</u></Link> </span>
            <button className="bg-white text-center text-black w-[22.5rem] h-12 rounded-lg flex justify-center items-center gap-3 mb-3"><img src={google} alt="Google logo" /> Sign in with Google</button>
            <span className='text-white text-[1.25rem] leading-normal'>Please enter your card details below</span>
            <div className="flex w-[50rem] flex-wrap gap-x-12 gap-y-8">
                <div className="flex flex-col items-start gap-3" style={{ fontFamily: "'Poppins',sans-serif" }} >
                    <label htmlFor="name" className='text-white font-bold text-lg leading-normal'>Name</label>
                    <input type="text" name="name" id="name" className='text-white w-[22.5rem] h-[3.125rem] shrink-0 border border-white rounded-[0.625rem] bg-gray-300 bg-opacity-25 px-2' />
                </div>
                <div className="flex flex-col items-start gap-3" style={{ fontFamily: "'Poppins',sans-serif" }} >
                    <label htmlFor="cardnumber" className='text-white font-bold text-lg leading-normal'>Card Number</label>
                    <input type="text" name="cardnumber" id="cardnumber" className='text-white w-[22.5rem] h-[3.125rem] shrink-0 border border-white rounded-[0.625rem] bg-gray-300 bg-opacity-25 px-2' />
                </div>
                <div className="flex flex-col items-start gap-3" style={{ fontFamily: "'Poppins',sans-serif" }} >
                    <label htmlFor="expiry" className='text-white font-bold text-lg leading-normal'>Expiry</label>
                    <input type="text" name="expiry" id="expiry" className='text-white w-[22.5rem] h-[3.125rem] shrink-0 border border-white rounded-[0.625rem] bg-gray-300 bg-opacity-25 px-2' />
                </div>
                <div className="flex flex-col items-start gap-3" style={{ fontFamily: "'Poppins',sans-serif" }} >
                    <label htmlFor="cvc" className='text-white font-bold text-lg leading-normal'>CVC</label>
                    <input type="text" name="cvc" id="cvc" className='text-white w-[22.5rem] h-[3.125rem] shrink-0 border border-white rounded-[0.625rem] bg-gray-300 bg-opacity-25 px-2' />
                </div>
                <button className="text-white bg-gradient-to-b from-[#19788E] to-[#0A0601] h-[3.125rem] w-[23.125rem] leading-[1.875rem] text-2xl rounded-[0.625rem] shrink-0 mx-auto">Start your Free trial</button>
            </div>
        </div>
    )
}

export default FreeTrial