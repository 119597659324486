import React, { useContext, useEffect, useRef, useState } from "react";
import DesignQuest from "../../layouts/DesignQuest";
import VideoImageImports from "../../assets";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import { TbPlayerTrackNext, TbPlayerTrackPrev } from "react-icons/tb";
import { SyncLoader } from "react-spinners";
import { AiOutlineDownload } from "react-icons/ai";
import { useDownload } from "../../hooks/useDownload";
import { FaHistory } from "react-icons/fa";
import { useNewGenerateImage } from "../../hooks/useNewGenerateImage";
import useHistoryQuest from "../../hooks/useHistoryQuest";
import { QuestContext } from "../../context/questContext/QuestContext";
import ImageQuestHistory from "../../components/ImageQuestHistory";
import { useParams } from "react-router-dom";
import styles from "../../styles/screens/DesignFashionBeauty.module.css";
const buttonArray = [
  {
    name: "Male",
    value: "",
    // img: frame2,
    type:"male"
  },
  {
    name: "Female",
    value: "",
    // img: frame3,
    type:"female"
  },
  {
    name: "Aesthetic",
    value: "",
    // img: frame4,
    type:"aesthetic"
  },
  {
    name: "Ultra Detail",
    value: "",
    // img: frame5,
    type:"ultradetail"
  },
  {
    name: "Cool",
    value: "",
    // img: frame6,
    type:"cool"
  },
];
const DesignFashionBeauty = () => {
  const imageArray = [0, 1, 2, 3];
  const { quest_type_obj } = VideoImageImports;
  const { quest_type } = useParams();
  const [desc, setDesc] = useState("");
  const [currIndex, setCurrIndex] = useState(null);
  const imgRef = useRef(null);
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  const { downloadFunction, downloading } = useDownload();
  const { fetchImage, imgloading, images } = useNewGenerateImage();
  const { QuestHistory, questHisLoading } = useHistoryQuest();
  const [index, setIndex] = useState(0);
  const {
    questState: { singleQuest, questHistory },
  } = useContext(QuestContext);
  const [previewURL, setPreviewURL] = useState(null);
  const [image, setImage] = useState(null);
  const [button, setButton] = useState([]);
  const { errorState } = useContext(ErrorContext);
  const [historyQuest, setHistoryQuest] = useState(false);
  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
  }, []);
  

  const modalStyle = {
    backgroundImage: `url(${quest_type_obj[quest_type]?.insideBox.background})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };

  const handleButton = (butt) => {
    if (button.includes(butt)) {
      setButton(button.filter((e) => e !== butt));
    } else {
      setButton([...button, butt]);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setImage(selectedFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Reset image and previewURL if no file is selected
      setImage(null);
      setPreviewURL(null);
    }
  };

  const handleGenerateButton = async () => {
    let imagePrompt;
    if (image) {
      imagePrompt = `Analyze the uploaded image and make changes with the following description in ${desc}`;
    } else {
      imagePrompt = `Create a fashion design image of ${button.join(
        ","
      )} having ${desc}`;
    }

    await fetchImage({
      prompt: imagePrompt,
      quest_type,
      width:
        imgDimensions.width % 8 === 0
          ? imgDimensions.width
          : imgDimensions.width - (imgDimensions.width % 8),
      height:
        imgDimensions.height % 8 === 0
          ? imgDimensions.height
          : imgDimensions.height - (imgDimensions.height % 8),
      num: 4,
      upload: image,
    });
    setDesc("");
    setButton([]);
    setImage(null);
    setPreviewURL(null);
  };
  const handleChangeImage = (item) => {
    if (item === "prev") {
      setIndex(index - 1);
    } else {
      setIndex(index + 1);
    }
  };
  useEffect(() => {
    const updateDimensions = () => {
      if (imgRef.current) {
        setImgDimensions({
          width: imgRef.current.offsetWidth,
          height: imgRef.current.offsetHeight,
        });
      }
    };

    // Update dimensions initially
    updateDimensions();

    // // Optional: Update dimensions on window resize
    // window.addEventListener('resize', updateDimensions);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  const handleHistory = async () => {
    setHistoryQuest(true);
    await QuestHistory({ questId: singleQuest._id.toString() });
  };
  console.log(questHistory);
  return (
    <DesignQuest modalStyle={modalStyle} title="Universal Fashion Design">
      {errorState.errorModal && <LimitErrorModal />}
      {historyQuest && (
        <ImageQuestHistory
          title="Universal Fashion Design"
          questId={singleQuest._id.toString()}
          setHistoryQuest={setHistoryQuest}
          questHisLoading={questHisLoading}
        />
      )}
      <div className="flex flex-col justify-center items-center gap-2 mt-2">
        <h1 className={`${styles.logo_header} font-[inter] uppercase`}>
          Fashion Beauty
        </h1>
        <div
          style={{
            background: "transparent",
            // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

            border: "1px solid #C6CCE0",

            borderRadius: "8px",
          }}
          className="w-[587px] h-[41px] flex justify-start items-center gap-4 px-4"
        >
          <div className="w-6 h-6 ">
            <img
              className="w-full h-full"
              src={quest_type_obj[quest_type]?.insideBox.description}
              alt="descritpiton logo"
            />
          </div>
          <h1 className="font-[500] text-[16px] text-[#5A6793] font-[inter]">
            Bring your imagination into reality with our creative fashion studio
          </h1>
        </div>
      </div>

      <div
        style={{ zIndex: 10000 }}
        className="flex gap-10 justify-center  px-2 py-1 w-full mt-2"
      >
        <div className="relative flex flex-col background-transparent gap-2 items-center py-4 px-4 rounded-xl flex-shrink min-h-[550px] h-[75vh] w-[85%] max-w-[650px]">
          <div className={`${styles.descriptionBox} w-[90%]`}>
            <div className={`w-full ${styles.descriptiondiv1}`}>
              Description Box:
            </div>
            <textarea
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              placeholder="Enter your Description here..."
              style={{ resize: "none", border: " 1px solid #C2C5E2" }}
              className="outline-none text-black text-sm h-[105px] bg-white rounded-xl px-4 py-4 w-full"
            ></textarea>
          </div>

          <div className="flex flex-wrap  w-full gap-x-6 gap-y-2 justify-center h-[50%]  px-2 py-2">
            <div
              style={{
                boxShadow: "0px 24px 44px 0px #B4BFE166",
              }}
              className={`relative cursor-pointer w-[28.33%] rounded-[16px] h-[40%] bg-white flex flex-col items-center justify-center`}
            >
              {!image && (
                <input
                  name="image"
                  accept=".png, .jpg, .jpeg, .webp"
                  onChange={handleFileChange}
                  style={{ bottom: "3%" }}
                  className="right-[0%] absolute opacity-5 z-20 w-full h-full rounded-full"
                  type="file"
                />
              )}
              {image && (
                <button
                  style={{ bottom: "5%" }}
                  className="absolute text-[12px] bg-gray-200 text-black w-16 h-4 rounded-md"
                  onClick={() => {
                    setPreviewURL(null);
                    setImage(null);
                  }}
                >
                  clear
                </button>
              )}
              {image ? (
                <img
                  src={previewURL}
                  alt="uploaded_file"
                  className="h-16 w-16 object-cover rounded-full"
                />
              ) : (
                <span className="text-[16px] text-[#1E2246]  font-semibold font-[poppins]">Upload your file</span>
              )}
            </div>
            {buttonArray.map((e) => (
              <button
                style={{
                  boxShadow: "0px 24px 44px 0px #B4BFE166",
                }}
                onClick={() => handleButton(e.name)}
                className={`${
                  previewURL ? "opacity-20 pointer-events-none" : ""
                } ${
                  button.includes(e.name) ? "opacity-20" : ""
                } cursor-pointer w-[28.33%] rounded-[16px]  h-[40%] bg-white flex  items-center justify-center gap-2`}
              >
                <div className="w-12 h-12 rounded-full flex justify-self-start">
                  <img className="w-full h-full object-cover rounded-full" src={quest_type_obj[quest_type]?.insideBox[e.type]} alt="" />

                </div>
                <span className="text-[18px] text-[#1E2246]  font-bold font-[poppins]">
                  {e.name}
                </span>
              </button>
            ))}
          </div>
          <div className="flex justify-center items-center gap-4 w-[90%]">
            <button
              disabled={!desc || !button || imgloading}
              onClick={handleGenerateButton}
              style={{
                background: "#1E2246",

                border: "1px solid #C2C5E2",

                mixBlendMode: "normal",
                lineHeight: "28px",
              }}
              className="outline-none px-4 py-2 font-[poppins] text-[18px] text-white rounded-lg h-[59px] w-[48%] "
            >
              Generate
            </button>
            {images?.generated_images && !imgloading && (
              <div className="flex items-center gap-2">
                <button
                  disabled={index === 0}
                  onClick={() => handleChangeImage("prev")}
                  className={`${
                    index === 0 ? "opacity-20" : ""
                  } font-blod px-2 py-2 bg-gray-300 rounded-full text-black`}
                >
                  {" "}
                  <TbPlayerTrackPrev />{" "}
                </button>
                <button
                  disabled={index === images?.generated_images?.length - 1}
                  onClick={() => handleChangeImage("next")}
                  className={`${
                    index === images?.generated_images?.length - 1
                      ? "opacity-20"
                      : ""
                  } font-blod px-2 py-2 bg-gray-300 rounded-full text-black`}
                >
                  <TbPlayerTrackNext />{" "}
                </button>
              </div>
            )}
          </div>
        </div>
        <div
        style={{ zIndex: 100 }}
        className="flex gap-20 justify-center px-2 py-2"
      >
        <div
          ref={imgRef}
          style={{
            background: "linear-gradient(to top right, rgba(94, 255, 197, 0.6), rgba(94, 148, 255, 0.6))"
          }}
          className="relative rounded-xl flex-shrink min-w-[512px] min-h-[512px] w-[512px]  h-[512px]  overflow-hidden"
        >
          {imgloading ? (
            <SyncLoader
              color="#36d7b7"
              style={{
                zIndex: 10000,
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                marginTop: "30px",
              }}
            />
          ) : (
            ""
          )}
          {!imgloading && images?.generated_images && (
            <img
              src={images?.generated_images?.[index]?.url}
              className="w-full h-full hover:scale-[1.2] object-cover transition-transform duration-300 ease-in-out"
              alt=""
            />
          )}

          <button
      disabled={downloading || !images.generated_images}
            onClick={() =>
              downloadFunction({
                imgUrl: images?.generated_images?.[index]?.url,
              })
            }
            style={{ zIndex: 100 }}
            className="w-8 h-8 absolute bottom-5 right-5 flex items-center justify-center bg-gray-300 rounded-full"
          >
            {!downloading ? (
              <AiOutlineDownload className="text-black" size={20} />
            ) : (
              <span className="text-black font-bold text-lg">...</span>
            )}
          </button>
          <button
            onClick={handleHistory}
            disabled={imgloading}
            style={{ zIndex: 100 }}
            className="w-8 h-8 absolute top-5 right-5 flex items-center justify-center bg-gray-300 rounded-full"
          >
            <FaHistory className="text-black" size={20} />
          </button>
        </div>
        </div>
      </div>
    </DesignQuest>
  );
};

export default DesignFashionBeauty;
