import { useState, useEffect } from 'react';

export const usePopup = () => {
  // State to control if the popup is visible
  const [isPopupVisible, setPopupVisible] = useState(false);

  // Effect hook to add event listener on mount and clean up on unmount
  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window; // We're only interested in width for responsive design
      // Show popup if screen width is less than 768px (Tailwind's 'md' breakpoint)
      setPopupVisible(innerWidth < 1240);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call the handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isPopupVisible };
};
