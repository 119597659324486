import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import moment from 'moment';

export const DayMonthYear = (timestamp) => {
  // Convert the timestamp to a moment object
  const date = moment(timestamp);

  // Format the date to show only the month and year
  const formattedDate = date.format("DD MMMM YYYY");
  return formattedDate;
};

const DataTable = ({ data }) => {
  return (
    <div className="overflow-x-auto w-full">
      <table className="min-w-full bg-white">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th className="py-3 px-4 uppercase font-semibold text-sm text-center">Username</th>
            <th className="py-3 px-4 uppercase font-semibold text-sm text-center">Parent's Email</th>
            <th className="py-3 px-4 uppercase font-semibold text-sm text-center">Email</th>
            <th className="py-3 px-4 uppercase font-semibold text-sm text-center">Subscribed Status</th>
            <th className="py-3 px-4 uppercase font-semibold text-sm text-center">Subscribe Plan</th>
            <th className="py-3 px-4 uppercase font-semibold text-sm text-center">Join Date</th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {data.map((item) => (
            <tr key={item._id}>
              <td className="py-3 px-4 text-center">{item?.username?.length > 9
                  ? item?.username.substring(0, 9) + "..."
                  : item?.username}</td>
                     <td className="py-3 px-4 text-center">{item.parent_email}</td>
              <td className="py-3 px-4 text-center">{item.email}</td>
           
              <td className="py-3 px-4 text-center">
                {item.subscribed_status ? (
                  <FaCheck className="text-green-500 inline" />
                ) : (
                  <FaTimes className="text-red-500 inline" />
                )}
              </td>
              <td className="py-3 px-4 text-center">{item.subscribe_plan || 'N/A'}</td>
              <td className="py-3 px-4 text-center">{DayMonthYear(item.join_date)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
