import React, { useContext, useEffect, useState } from "react";
import api from "../api";
import { AuthContext } from "../context/AuthContext";

const useAiAudio = () => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const { currentUser } = useContext(AuthContext);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser.token}`,
  };
  const [data, setData] = useState({});

  const audioinbase64 = async (body) => {
    setLoading(true);
    try {
      let res = await api.post("/aiChatText-single", body, {
        headers,
      });
      if (res.status === 200) {
        setData(res.data.singelChat);
      }
    } catch (err) {
        console.log(err)
    }
    setLoading(false)
  };
  return {
    audioinbase64,
    aiaudioLoading: loading,
    aiaudioData: data,
  };
};

export default useAiAudio;
