import { SyncLoader } from "react-spinners";
const StraightCarousel = ({
  images,
  selected,
  loading,
}) => {
  return (
    <div className="flex px-2 py-2 gap-8 items-center w-full justify-center">
      {loading ? (
          <SyncLoader
            color="#36d7b7"
            style={{
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              marginTop: "30px",
            }}
          />
        ) : (
          ""
        )}
    
       { images?.generated_images?.map((image, index) => (
        
         
          <div
            className="w-[200px] h-[150px] bg-gray-500 rounded-lg flex justify-center items-center"
            key={image?.url}
            style={{
              cursor: "pointer",
              // opacity: selected === index ? 1 : 0.5,
              transform: selected === index ? "scale(1.2)" : "scale(1)",
              border: selected === index ? "2px solid white" : "",
            }}
          >
           
            <img
              className="w-[80%] h-[80%] rounded-lg"
              src={image?.url}
              alt={image?.url}
            />
          </div>
        ))}
    </div>
  );
};

export default StraightCarousel;
