// using ie9 polyfills as the "kitchen sink" of polyfills
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import OverViewProvider from "./context/overviewcontext/OverviewContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-tooltip/dist/react-tooltip.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import ErrorProvider from "./context/errorcontext/ErrorContext";
import QuestProvider from "./context/questContext/QuestContext";
import LeaderBoardProvider from "./context/leaderboardContext/LeaderBoardContext";
import FeedProvider from "./context/feedcontext/FeedContext";
import AiProvider from "./context/aicontext/AiContext";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "a");
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthContextProvider>
    <OverViewProvider>
      <Elements stripe={stripePromise}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <ErrorProvider>
            <QuestProvider>
              <LeaderBoardProvider>
                <FeedProvider>
                  <AiProvider>

                  <App />
                  </AiProvider>
                </FeedProvider>
              </LeaderBoardProvider>
            </QuestProvider>
          </ErrorProvider>
        </GoogleOAuthProvider>
      </Elements>
    </OverViewProvider>
    <ToastContainer />
  </AuthContextProvider>
);
