import { RouterProvider } from "react-router-dom";
import { publicrouter } from "./routes/PublicRoute";
import { privaterouter } from "./routes/PrivateRoute";
import { useContext} from "react";
import { AuthContext } from "./context/AuthContext";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { AiContext } from "./context/aicontext/AiContext";

function App() {
  const { currentUser } = useContext(AuthContext);
  const {isMenuOpen,setIsMenuOpen}=useContext(AiContext)
  
  return (
    <>
      <div
        style={{ zIndex: 1000000 }}
        className="flex h-12 md:hidden items-center sticky top-0 justify-between px-4  text-white bg-black"
      >
        <h1 className="text-[25px] font-bold">xdojo.ai</h1>
        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {isMenuOpen ? <RxCross2 size={25} /> : <GiHamburgerMenu size={25} />}
        </button>
      </div>

      

      {currentUser ? (
        <RouterProvider router={privaterouter} />
      ) : (
        <RouterProvider router={publicrouter} />
      )}
    </>
  );
}

export default App;
