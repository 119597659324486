import React, { useContext, useState } from "react";
import { QuestContext } from "../context/questContext/QuestContext";
import { TbPlayerTrackNext, TbPlayerTrackPrev } from "react-icons/tb";
import { SyncLoader } from "react-spinners";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AiOutlineDownload } from "react-icons/ai";
import { useDownload } from "../hooks/useDownload";

const ImageQuestHistory = ({
  title,
  questId,
  setHistoryQuest,
  questHisLoading,
}) => {
  const {
    questState: { questHistory },
  } = useContext(QuestContext);

  const onClose = () => setHistoryQuest(false);
  const [indexes, setIndexes] = useState({});
  const { downloadFunction, downloading } = useDownload();
  // Update to handle changing image per quest item
  const handleChangeImage = (item, questIndex) => {
    setIndexes((prevIndexes) => {
      const currentIndex = prevIndexes[questIndex] || 0;
      return {
        ...prevIndexes,
        [questIndex]:
          item === "prev"
            ? Math.max(currentIndex - 1, 0)
            : Math.min(
                currentIndex + 1,
                questHistory?.[questId]?.[questIndex]?.img_array[0]
                  ?.generations_by_pk?.generated_images.length - 1
              ),
      };
    });
  };
  return (
    <div
      style={{ zIndex: 1000000000 }}
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    >
      <div className="relative  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-5 border w-[80%] h-[calc(100vh-6rem)] shadow-lg rounded-lg bg-white overflow-hidden z-20">
        {questHisLoading ? (
          <SyncLoader
            color="#36d7b7"
            style={{
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              marginTop: "30px",
            }}
          />
        ) : (
          ""
        )}
        <button
          onClick={onClose}
          className="absolute top-0 right-0 m-3 text-3xl leading-none font-semibold text-gray-600 hover:text-gray-900"
        >
          &times;
        </button>
        <h2 className="text-xl font-bold text-gray-800 mb-5">{title}</h2>
        <div className="flex flex-col h-[100%] gap-10 overflow-y-auto">
          {questHistory?.[questId]?.map((e, questIndex) => (
            <div className="flex flex-col h-[100%] gap-10">
              <div>
                <h1 className="text-black">
                  {questIndex + 1}. {e.prompt}
                </h1>
              </div>
              <div className="flex gap-0 justify-around">
                <div className="flex flex-col">
                  <p className="text-black">Uploaded Image</p>
                  {e.uploadImageData.length > 0 ? (
                    <div className="relative w-64 h-64 rounded-lg">
                      <LazyLoadImage
                        src={e.uploadImageData[0]?.init_images_by_pk?.url}
                        alt="box"
                        className="w-full h-full object-contain"
                        effect="blur"
                      />
                      <button
                        disabled={downloading}
                        onClick={() =>
                          downloadFunction({
                            imgUrl:
                              e.uploadImageData[0]?.init_images_by_pk?.url,
                          })
                        }
                        style={{ zIndex: 100 }}
                        className="w-8 h-8 absolute bottom-5 right-5 flex items-center justify-center bg-gray-300 rounded-full"
                      >
                        {!downloading ? (
                          <AiOutlineDownload className="text-black" size={20} />
                        ) : (
                          <span className="text-black font-bold text-lg">
                            ...
                          </span>
                        )}
                      </button>
                    </div>
                  ) : (
                    <h1 className="text-black">No Uploaded Image</h1>
                  )}

                  <div className="flex items-center gap-2">
                    <button
                      disabled={
                        (!indexes[questIndex] || indexes[questIndex === 0]) ===
                        0
                      }
                      onClick={() => handleChangeImage("prev", questIndex)}
                      className={`${
                        !indexes[questIndex] || indexes[questIndex === 0]
                          ? "opacity-20"
                          : ""
                      } font-bold px-2 py-2 bg-gray-300 rounded-full text-black`}
                    >
                      <TbPlayerTrackPrev />
                    </button>
                    <button
                      disabled={
                        indexes[questIndex] ===
                        e.img_array[0]?.generations_by_pk?.generated_images
                          ?.length -
                          1
                      }
                      onClick={() => handleChangeImage("next", questIndex)}
                      className={`${
                        indexes[questIndex] ===
                        e.img_array[0]?.generations_by_pk?.generated_images
                          ?.length -
                          1
                          ? "opacity-20"
                          : ""
                      } font-bold px-2 py-2 bg-gray-300 rounded-full text-black`}
                    >
                      <TbPlayerTrackNext />
                    </button>
                  </div>
                </div>

                <div>
                  <div className="relative w-[400px] h-[400px]">
                    <LazyLoadImage
                      src={
                        e.img_array[0]?.generations_by_pk?.generated_images[
                          indexes[questIndex] ? indexes[questIndex] : 0
                        ]?.url
                      }
                      alt={
                        e.img_array[0]?.generations_by_pk?.generated_images[
                          indexes[questIndex] ? indexes[questIndex] : 0
                        ]?.id
                      }
                      className="object-contain rounded-lg w-[400px] h-[400px]"
                      effect="blur"
                    />
                    <button
                      disabled={downloading}
                      onClick={() =>
                        downloadFunction({
                          imgUrl:
                            e.img_array[0]?.generations_by_pk?.generated_images[
                              indexes[questIndex] ? indexes[questIndex] : 0
                            ]?.url,
                        })
                      }
                      style={{ zIndex: 100 }}
                      className="w-8 h-8 absolute bottom-5 right-5 flex items-center justify-center bg-gray-300 rounded-full"
                    >
                      {!downloading ? (
                        <AiOutlineDownload className="text-black" size={20} />
                      ) : (
                        <span className="text-black font-bold text-lg">
                          ...
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageQuestHistory;
