import { Link } from "react-router-dom";
import { Formik } from "formik";
import { loginschema } from "../constants/validationschema";
import { useLogin } from "../hooks/useLogin";
import { GoogleLogin } from "@react-oauth/google";
import ClipLoader from "react-spinners/ClipLoader";
import { useGoogleApi } from "../hooks/useGoogleApi";
import { useGoogleLogin } from "@react-oauth/google";
const Login = () => {
  const { login, loginLoading } = useLogin();
  const { fetchGoogle } = useGoogleApi();
  const handleGoogleLogn = useGoogleLogin({
    onSuccess: async(codeResponse) => {
      await fetchGoogle(codeResponse.access_token,"link");

    },
    onError: (error) => console.log("Login Failed:", error),
  });
  return (
    <div
      className="flex flex-col gap-4 justify-center items-center w-full h-full px-6 text-center"
      style={{ fontFamily: "'Inter', sans-serif" }}
    >
      <span
        className="text-[2.5rem] font-normal mb-4 text-white"
        style={{ fontFamily: "Space Grotesk, sans-serif" }}
      >
        Login
      </span>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={async (values) => {
          const { email, password } = values;
          await login(email, password);
        }}
        validationSchema={loginschema}
      >
        {({
          errors,
          values,
          handleChange,
          setFieldTouched,
          handleSubmit,
          touched,
        }) => (
          <form className="flex flex-col gap-4 justify-center items-center w-full" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-1 w-full md:w-[23.125rem]">
              <input
                type="text"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={() => setFieldTouched("email")}
                className="text-white text-[0.9375rem] leading-[1.875rem] shrink-0 w-full h-[3.125rem] rounded-[0.625rem] border border-white px-6 bg-gray-300 bg-opacity-25"
                style={{ fontFamily: "'Inter', sans-serif" }}
                placeholder="Email"
              />
              {touched.email && errors.email && (
                <span className="text-red-600 font-normal">{errors.email}</span>
              )}
            </div>
            <div className="flex flex-col gap-1 w-full md:w-[23.125rem]">
              <input
                type="password"
                name="password"
                id="password"
                onChange={handleChange}
                onBlur={() => setFieldTouched("password")}
                className="text-white text-[0.9375rem] leading-[1.875rem] shrink-0 w-full h-[3.125rem] rounded-[0.625rem] border border-white px-6 bg-gray-300 bg-opacity-25"
                placeholder="Password"
              />
              {touched.password && errors.password && (
                <span className="text-red-600 font-normal">
                  {errors.password}
                </span>
              )}
            </div>
            <Link
              to="forgot/password"
              className="text-white text-[0.9375rem] leading-[1.875rem]"
            >
              Forgot Password ?
            </Link>
            <button
              type="submit"
              disabled={loginLoading}
              value="Sign In"
              className="text-white bg-gradient-to-b from-[#19788E] to-[#0A0601] w-full md:w-[23.125rem] h-[3.125rem] leading-[1.875rem] text-2xl rounded-[0.625rem] shrink-0"
            >
              {loginLoading ? <ClipLoader color="#36d7b7" /> : "Sign In"}
            </button>
          </form>
        )}
      </Formik>
      <span className="text-white text-base leading-[1.875rem]">
        or sign in with
      </span>
      <div className="flex gap-4">
        {/* <div id="google_signin"></div> */}
        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            await fetchGoogle(credentialResponse.credential);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
        ;
        {/* <button className="bg-white text-black w-[10.70819rem] h-[3.36031rem] rounded-[0.625rem] leading-[1.875rem] text-[1.125rem] items-center flex justify-center gap-4"> <img src={facebbok} alt="facebook logo" /> Facebook</button> */}
      </div>
      <span className="leading-[1.875rem] text-white text-[1.1rem] font-light">
        Individuals Under 13 need manual{" "}
        <Link className="text-[#99D6EA]" to="signup">
          <u>Sign Up</u>
        </Link>{" "}
        or parent registration via{" "}
        <span
          className="text-[#99D6EA] cursor-pointer"
          onClick={handleGoogleLogn}
        >
          {" "}
          <u>Google</u>
        </span>
        , due to age policy.
      </span>
      <span className="leading-[1.875rem] text-white text-[1.125rem] font-light">
        Don't have a account?{" "}
        <Link className="text-[#99D6EA]" to="signup">
          <u>Sign Up</u>
        </Link>{" "}
      </span>
    </div>
  );
};

export default Login;
