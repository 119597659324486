import xdojo from "../assets/icons/Xdojo.webp";
import { BsBell } from "react-icons/bs";
import leaderboard_img1 from "../assets/images/userimg.webp";
import { MdOutlineArrowDropDown } from "react-icons/md";
import authbg from "../assets/images/authbg.webp";
import PhoneInput from "react-phone-number-input";
import { GiTeacher } from "react-icons/gi";
import "react-phone-number-input/style.css";
import { RxCross2 } from "react-icons/rx";
import profile from "../assets/images/userimg.webp";
import camera from "../assets/icons/Settings/camera.webp";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { CiUser } from "react-icons/ci";
import { IoIosAnalytics } from "react-icons/io";
import { IoMdSearch } from "react-icons/io";
import { TbDeviceAnalytics } from "react-icons/tb";
import { BsRobot } from "react-icons/bs";
import { useContext, useEffect, useRef, useState } from "react";
import { GiHamburgerMenu, GiMagicBroom } from "react-icons/gi";
import { AuthContext } from "../context/AuthContext";
import useOverItem from "../hooks/useOverItem";
import { usePopup } from "../hooks/usePopup";
import PlanText from "../components/PlanText";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import usePostUserInfo from "../hooks/usePostUserInfo";
import { useImageUpload } from "../hooks/useImageUpload";
import { isValidPhoneNumber } from "react-phone-number-input";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import api from "../api";
import moment from "moment";
import { CountryDropdown } from "react-country-region-selector";
import { QuestContext } from "../context/questContext/QuestContext";
import SearchInput from "../components/SearchInput";
import { textcolor } from "../constants/styles";
import { IoHeart } from "react-icons/io5";
import notificationSound from "../assets/audio/notification-audio.mp3";
import { Tooltip } from "react-tooltip";
import { AiContext } from "../context/aicontext/AiContext";
const Dashboard = () => {
  const {isMenuOpen,setIsMenuOpen}=useContext(AiContext)

  const { dispatch } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const headers = {
    Authorization: `Bearer ${currentUser.token}`,
  };
  const [notificationModal, setNotificationModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const { isPopupVisible } = usePopup();
  const [previewURL, setPreviewURL] = useState(null);
  const [image, setImage] = useState(null);
  const { postStatus, postLoading, postuserInfo } = usePostUserInfo();
  const { ImageUpload, imgLoading } = useImageUpload();
  const {
    questDispatch,
    questState: { currIndex, singleQuest: data },
  } = useContext(QuestContext);

  const now = new Date();
  let hours = now.getHours();
  const { state, Overview, setNotificationStatus } = useOverItem();
  const {
    state: { notificatioArray, notificationStatus },
  } = useOverItem();
  const [loading, setLoading] = useState(false);
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  let greeting;
  if (hours < 12) {
    greeting = "Good Morning";
  } else if (hours >= 12 && hours < 17) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const navigate = useNavigate();

  const ProfileSchema = Yup.object().shape({
    parent_email: Yup.string()
      .email("Enter valid email")
      .required("Cannot be left blank"),
    dob: Yup.date().required("Date of birth is required"),
    country: Yup.string().required("Country is required"),
    fullName: Yup.string().required("Full name is required"),
    username: Yup.string().required("Username is required"),
    gender: Yup.string()
      .oneOf(["male", "female", "other"], "Invalid gender")
      .required("Gender is required"),
    phone: Yup.string()
      .required("Phone is required")
      .test("isValidPhoneNumber", "Phone number is not valid", (value) =>
        isValidPhoneNumber(value)
      ),
  });

  const mobNavArray = [
    {
      name: "Home",
      to: "/",
      icon: TbDeviceAnalytics,
    },
    {
      name: "Quests",
      to: "questlist",
      icon: BsRobot,
    },
    {
      name: "Feed",
      to: "feeds",
      icon: CiUser,
    },
    {
      name: "AI Search",
      to: "aisearch",
      icon: IoMdSearch,
    },
  ];

  const bellRef = useRef(null);
  const modalRef = useRef(null);
  const settingRef = useRef(null);
  const modalSettingRef = useRef(null);

  const toggleNotificationModal = () => {
    setNotificationModal(!notificationModal);
    setNotificationStatus(false);
    localStorage.setItem("notification", "false");
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        bellRef.current &&
        !bellRef.current.contains(event.target)
      ) {
        setNotificationModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, bellRef]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modalSettingRef.current &&
        !modalSettingRef.current.contains(event.target) &&
        settingRef.current &&
        !settingRef.current.contains(event.target)
      ) {
        setProfileModal(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalSettingRef, settingRef]);
  if (state.error && !state.loading) {
    return (
      <div
        style={{ backgroundImage: `url(${authbg})` }}
        className="fixed inset-0 bg-no-repeat bg-cover bg-opacity-50 h-full w-full overflow-y-auto flex items-center justify-center px-4"
      >
        <div className="bg-white p-4 md:p-5 rounded-lg shadow-lg max-w-full sm:max-w-md w-full">
          <h2 className="text-lg md:text-xl font-bold mb-4">Session Timeout</h2>
          <p className="text-md md:text-lg mb-4">
            Your session has timed out. Please log in again to continue.
          </p>
          <button
            onClick={() => {
              dispatch({ type: "LOGOUT" });
              localStorage.removeItem("user");
              navigate("/");
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Login
          </button>
        </div>
      </div>
    );
  }
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setImage(selectedFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      // Reset image and previewURL if no file is selected
      setImage(null);
      setPreviewURL(null);
    }
  };

  if (
    !state.loading &&
    (!state.overview?.parent_email ||
      !state.overview?.username ||
      !state.overview?.fullName ||
      !state.overview?.phone ||
      !state.overview?.dob)
  ) {
    return (
      // <div style={{ fontFamily: "'Poppins',sans-serif" }}>
      // <div className="fixed inset-0 flex items-center justify-center">
      //   <div className="modal-overlay absolute inset-0 bg-black opacity-90"></div>

      <div className="bg-black h-screen overflow-auto w-full">
        {/* header */}

        <div className="flex h-14 items-center sticky top-0 justify-between px-6 py-2 bg-gray-900">
          <div className="h-[2.0625rem] w-[5.02363rem] md:h-[2.5625rem] md:w-[8.02363rem]">
            <img
              src={xdojo}
              alt="Xdojo logo"
              className=" shrink-0 mx-auto w-full h-full"
            />
          </div>
        </div>
        <div className="flex justify-center items-center h-[calc(100vh - 56px)] md:mt-10 w-full">
          <div
            style={{
              boxShadow: "0px 0px 30px 0px #393939, 0px 0px 30px 0px #040404",
              background: "#191919",
            }}
            className="p-4 md:px-4 md:py-4 flex flex-col  items-center w-full h-full lg:w-[70vw] md:h-[75vh]  md:max-h-[600px]  justify-start gap-4"
          >
            <div className="flex flex-col md:flex-row gap-4 w-full h-full justify-between items-center md:items-start">
              <div className="flex gap-6 justify-center flex-col  items-center">
                <div
                  style={{ lineHeight: 1.9 }}
                  className="text-gray-200 uppercase text-center md:text-start font-semibold px-2 w-full"
                >
                  Your Profile Details
                </div>
                <div className="relative flex flex-col">
                  {previewURL ? (
                    <div style={{ width: "8rem", height: "8rem" }}>
                      <img
                        src={previewURL}
                        className="rounded-full w-full h-full"
                        alt=""
                      />
                    </div>
                  ) : (
                    <div style={{ width: "8rem", height: "8rem" }}>
                      <img
                        src={
                          state.overview.profileimg
                            ? state.overview.profileimg
                            : profile
                        }
                        className="rounded-full w-full h-full"
                        alt=""
                      />
                    </div>
                  )}

                  <img
                    src={camera}
                    alt=""
                    className="absolute w-[1.95rem] h-[1.95rem] bottom-[3%] right-[3%]"
                  />
                  <input
                    name="image"
                    onChange={handleFileChange}
                    style={{ bottom: "3%" }}
                    className="right-[0%] absolute opacity-5 z-20 w-[1.95rem] h-[1.95rem] rounded-full"
                    type="file"
                  />
                </div>

                {image && (
                  <>
                    <span className="text-red-400" style={{ fontSize: "10px" }}>
                      Max Size: 100X100
                    </span>
                    <div className="flex flex-col items-center gap-4 w-full">
                      <button
                        disabled={loading}
                        className="text-[12px] bg-gray-300 text-black px-1 py-1 w-[90%] rounded-lg"
                        onClick={async () => {
                          setLoading(true);
                          const reader = new FileReader();
                          reader.readAsDataURL(image);
                          reader.onloadend = async () => {
                            const base64Image = reader.result.split(",")[1];

                            // The endpoint URL of your API Gateway
                            const API_URL =
                              "https://coobjvzmg3.execute-api.ap-south-1.amazonaws.com/dev/upload";
                            const sanitized = image.name
                              .toLowerCase()
                              .replace(/\s+/g, "");
                            const date = new Date();
                            const timestamp = `${date.getFullYear()}${String(
                              date.getMonth() + 1
                            ).padStart(2, "0")}${String(
                              date.getDate()
                            ).padStart(2, "0")}${String(
                              date.getHours()
                            ).padStart(2, "0")}${String(
                              date.getMinutes()
                            ).padStart(2, "0")}${String(
                              date.getSeconds()
                            ).padStart(2, "0")}`;

                            // Combine sanitized name with timestamp
                            const finalName = timestamp + sanitized;

                            try {
                              const response = await api.post(API_URL, {
                                image: base64Image,
                                name: finalName,
                                prevname: state.overview?.profileimg
                                  ? state.overview?.profileimg
                                  : null,
                                contentType: image.type,
                              });

                              if (response.status === 200) {
                                setImage(null);

                                await ImageUpload({ finalName });
                                // setPreviewURL(null);
                                // await Overview();
                              }
                            } catch (error) {
                              console.error("Error uploading image:", error);
                            }
                            setLoading(false);
                          };

                          // if (res === 200) {

                          // }
                        }}
                      >
                        {loading ? "loading..." : "Upload"}
                      </button>
                      <button
                        className="text-[12px] w-[90%] bg-gray-300 text-black px-1 py-1 rounded-lg"
                        onClick={() => {
                          setPreviewURL(null);
                          setImage(null);
                        }}
                      >
                        clear
                      </button>
                    </div>
                  </>
                )}
              </div>

              <div className="flex w-full md:w-[90%] lg:w-[60%] items-between h-full overflow-auto flex-col gap-4 mr-2">
                <Formik
                  style={{
                    height: "100%",
                  }}
                  initialValues={{
                    dob: state.overview?.dob
                      ? moment(new Date(state.overview.dob)).format(
                          "YYYY-MM-DD"
                        )
                      : "",
                    country: state.overview?.country || "",
                    fullName: state.overview?.fullName || "",
                    username: state.overview?.username || "",
                    gender: state.overview?.gender || "",
                    phone: state.overview?.phone || "",
                    parent_email: state.overview?.parent_email || "",
                  }}
                  validationSchema={ProfileSchema}
                  onSubmit={async (values) => {
                    // Handle form submission
                    let { phone } = values;
                    phone = formatPhoneNumberIntl(phone);
                    values = { ...values, phone };
                    console.log(values);
                    await postuserInfo(values);
                    // if(postStatus==="OK"){
                    //   await Overview()
                    // }
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    errors,
                    setFieldTouched,
                    touched,
                  }) => (
                    <Form className="h-full">
                      <div className="flex flex-col justify-between  h-full gap-2">
                        {/* First Column */}
                        <div className="flex w-full flex-col gap-4 mr-4">
                          <div className="flex flex-col">
                            <label
                              htmlFor="username"
                              className="text-gray-200 mb-1"
                            >
                              Username
                            </label>
                            <Field
                              name="username"
                              type="text"
                              className="rounded-md 
                        text-black
                        border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 p-2"
                            />
                            {errors.username && touched.username ? (
                              <div className="text-red-500 text-sm">
                                {errors.username}
                              </div>
                            ) : null}
                          </div>
                          <div className="flex flex-col">
                            <label
                              htmlFor="parent_email"
                              className="text-gray-200 mb-1"
                            >
                              Parent's Email
                            </label>
                            <Field
                              name="parent_email"
                              type="email"
                              className="rounded-md border-gray-300 text-black focus:border-indigo-500 focus:ring-indigo-500 p-2"
                            />
                            {touched.parent_email && errors.parent_email && (
                              <span className="text-[#ff0000] font-normal">
                                {errors.parent_email}
                              </span>
                            )}
                          </div>

                          <div className="flex flex-col">
                            <label htmlFor="fullName" className="text-gray-200">
                              Full Name
                            </label>
                            <Field
                              name="fullName"
                              type="text"
                              className="rounded-md border-gray-300 text-black focus:border-indigo-500 focus:ring-indigo-500 p-2"
                            />
                            {errors.fullName && touched.fullName ? (
                              <div className="text-red-500 text-sm">
                                {errors.fullName}
                              </div>
                            ) : null}
                          </div>

                          <div className="flex flex-col">
                            <label htmlFor="country" className="text-gray-200">
                              Country
                            </label>
                            <CountryDropdown
                              name="country"
                              id="country"
                              className="rounded-md text-black border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 p-2"
                              value={values.country}
                              onChange={(val) => setFieldValue("country", val)}
                              onBlur={() => setFieldTouched("country")}
                            />
                            {touched.country && errors.country && (
                              <div className="text-red-500 text-sm">
                                {errors.country}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Second Column */}
                        <div className="flex flex-col md:flex-row w-full md:w-[30%] gap-4 mt-2">
                          <div className="flex flex-1 flex-col">
                            <label htmlFor="dob" className="text-gray-200 mb-1">
                              Date of Birth
                            </label>
                            <Field
                              name="dob"
                              type="date"
                              className="rounded-md border-gray-300 text-black focus:border-indigo-500 focus:ring-indigo-500 p-2"
                            />
                            {errors.dob && touched.dob ? (
                              <div className="text-red-500 text-sm">
                                {errors.dob}
                              </div>
                            ) : null}
                          </div>

                          <div className="flex flex-col">
                            <label
                              htmlFor="gender"
                              className="text-gray-200 mb-1"
                            >
                              Gender
                            </label>
                            <Field
                              as="select"
                              name="gender"
                              className="rounded-md 
                        text-black
                        border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 p-2"
                            >
                              <option value="">Select Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                            </Field>
                            {errors.gender && touched.gender ? (
                              <div className="text-red-500 text-sm">
                                {errors.gender}
                              </div>
                            ) : null}
                          </div>

                          <div className="flex flex-col">
                            <label
                              htmlFor="phone"
                              className="text-gray-200 mb-1"
                            >
                              Phone
                            </label>
                            <div className="rounded-md bg-white text-black border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 p-2">
                              <PhoneInput
                                name="phone"
                                placeholder="Enter phone number"
                                className="px-4"
                                value={values.phone}
                                onChange={(val) => setFieldValue("phone", val)}
                                onBlur={() => setFieldTouched("phone")}
                              />
                            </div>
                            {touched.phone && errors.phone && (
                              <div className="text-red-500 text-sm">
                                {errors.phone}
                              </div>
                            )}
                          </div>

                          {/* <div className="flex flex-col">
                        <label htmlFor="phone" className="text-gray-200 mb-1">
                          Phone
                        </label>
                        <Field
                          name="phone"
                          type="number"
                          className="rounded-md text-black border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 p-2"
                        />
                        {errors.phone && touched.phone ? (
                          <div className="text-red-500 text-sm">
                            {errors.phone}
                          </div>
                        ) : null}
                      </div> */}
                        </div>
                        <div className="flex flex-col">
                          <button
                            type="submit"
                            className="mt-4 px-4 py-2 w-full md:w-[300px] bottom-0 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                          >
                            {postLoading ? "Submitting..." : "Submit"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        {/* </div>
        </div> */}
      </div>
    );
  }

  return (
 
     
      <div
        className="w-full h-[calc(100dvh-3rem)] md:h-screen overflow-hidden relative  md:flex text-white gap-6"
        style={{
          background: `linear-gradient(to bottom right, #292157 0%, #000000 100%)`,
          fontFamily: "'Poppins',sans-serif",
      
        }}
      >
        <div
          className="min-w-[114px] hidden h-full md:flex bg-transparent pt-10"
          style={{ borderRight: "1px solid gray" }}
        >
          <div className="flex flex-col items-center w-full text-white gap-2 txt-sm not-italic font-medium ">
            <NavLink
              data-tooltip-id="overviewTip"
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending"
                  : isActive
                  ? `text-[${textcolor}] rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20`
                  : "rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20"
              }
              to="/"
            >
              <TbDeviceAnalytics size={25} />
              <Tooltip
                style={{
                  zIndex: 10000000000,
                  backgroundColor: `${textcolor}`,
                  color: "black",
                }}
                place="right"
                id="overviewTip"
              >
                Home
              </Tooltip>
            </NavLink>

            <NavLink
              data-tooltip-id="questlist"
              to="questlist"
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending"
                  : isActive
                  ? `text-[${textcolor}] rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20`
                  : "rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20"
              }
            >
              <BsRobot size={25} />
              <Tooltip
                style={{
                  zIndex: 10000000000,
                  backgroundColor: `${textcolor}`,
                  color: "black",
                }}
                place="right"
                id="questlist"
              >
                Quests
              </Tooltip>
            </NavLink>
            <NavLink
              data-tooltip-id="feeds"
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending"
                  : isActive
                  ? `text-[${textcolor}] rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20`
                  : "rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20"
              }
              to="feeds"
            >
              <CiUser size={25} />
              <Tooltip
                style={{
                  zIndex: 10000000000,
                  backgroundColor: `${textcolor}`,
                  color: "black",
                }}
                place="right"
                id="feeds"
              >
                Feed
              </Tooltip>
            </NavLink>
            <NavLink
              data-tooltip-id="aisearch"
              className={({ isActive, isPending }) =>
                isPending
                  ? "pending"
                  : isActive
                  ? `text-[${textcolor}] rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20`
                  : "rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20"
              }
              to="aisearch"
            >
              <IoMdSearch size={25} />
              <Tooltip
                style={{
                  zIndex: 10000000000,
                  backgroundColor: `${textcolor}`,
                  color: "black",
                }}
                place="right"
                id="aisearch"
              >
                AI Search
              </Tooltip>
            </NavLink>

            {state.overview?.role === "admin" && (
              <NavLink
                data-tooltip-id="analytics"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? `text-[${textcolor}] rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20`
                    : "rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20"
                }
                to="analytics"
              >
                <IoIosAnalytics size={25} />
                <Tooltip
                  style={{
                    zIndex: 10000000000,
                    backgroundColor: `${textcolor}`,
                    color: "black",
                  }}
                  place="right"
                  id="analytics"
                >
                  Analytics
                </Tooltip>
              </NavLink>
            )}
            {state.overview?.role === "admin" && (
              <NavLink
                data-tooltip-id="academics"
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? `text-[${textcolor}] rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20`
                    : "rounded-xl w-full h-12 flex justify-center gap-2.5 items-center hover:bg-slate-500 hover:bg-opacity-20"
                }
                to="academics"
              >
                <GiTeacher size={25} />
                <Tooltip
                  style={{
                    zIndex: 10000000000,
                    backgroundColor: `${textcolor}`,
                    color: "black",
                  }}
                  place="right"
                  id="academics"
                >
                  Academics
                </Tooltip>
              </NavLink>
            )}
          </div>
        </div>

        {isMenuOpen && (
          <div className="md:hidden absolute top-35 w-full flex flex-col gap-4 px-2 py-4 bg-gray-800 z-50">
            {mobNavArray?.map((e) => (
              <NavLink
                onClick={() => setIsMenuOpen(false)}
                key={e.name}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? `text-[${textcolor}] rounded-xl gap-4 px-4 py-2 w-full h-12 flex  items-center hover:bg-slate-500 hover:bg-opacity-20`
                    : "rounded-xl w-full h-12 flex gap-4  px-4 py-2  items-center hover:bg-slate-500 hover:bg-opacity-20"
                }
                to={e.to}
              >
                <e.icon size={20} />
                <span>{e.name}</span>
              </NavLink>
            ))}
            <button
              onClick={() => {
                dispatch({ type: "LOGOUT" });
                localStorage.removeItem("user");
                navigate("/");
              }}
              className="rounded-xl w-full h-12 flex gap-4  px-4 py-2  items-center mt-10 bg-slate-500 bg-opacity-40"
            >
              Logout
            </button>
          </div>
        )}
        <div className="h-full w-full flex flex-col md:pt-8 md:pr-2 relative gap-4">
          {notificationModal && (
            <div
              ref={modalRef}
              className="absolute w-[50%] h-[60%] rounded-xl top-[12%] gap-2 right-[10%] flex flex-col bg-[#3E3769] z-10"
            >
              {notificatioArray?.length === 0 && (
                <div className="flex items-center justify-center my-auto">
                  <h1 className="text-gray-300">-No Notification-</h1>
                </div>
              )}
              {notificatioArray?.length > 0 && (
                <div className="flex flex-col px-4 py-2 gap-2 overflow-auto">
                  {notificatioArray?.map((item) => {
                    if (item.status === "badge") {
                      return (
                        <div className="flex flex-col gap-2 rounded-xl shadow-lg py-4 px-4">
                          <div className="flex items-center gap-2">
                            <small className="">You have won a new badge</small>
                            <GiMagicBroom
                              size={20}
                              className="text-yellow-300"
                            />
                          </div>
                          {/* <small className="text-gray-400 text-[12px] font-bold">
                          {item?.name}
                        </small> */}
                          <div className="flex gap-4 items-center">
                            {/* <div className="w-12 h-12 bg-gray-500 flex items-center rounded-full justify-center">
                            <img
                              src={item?.image}
                              className="h-full w-full object-contain rounded-full"
                              alt=""
                            />
                          </div> */}
                            {/* <div className="flex flex-col text-sm text-gray-300 font-bold">
                            <h2>
                              {item?.username.length > 10
                                ? item?.username.substring(0, 10) + "..."
                                : item?.username}
                            </h2>
                            <h2>
                              {item?.name.length > 20
                                ? item?.name.substring(0, 20) + "..."
                                : item?.name}
                            </h2>
                          </div> */}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="flex flex-col gap-2 rounded-xl shadow-lg py-4 px-4">
                          <div className="flex items-center gap-2">
                            <small className="">Your Feeds got a like </small>
                            <IoHeart size={20} className="text-red-500" />
                          </div>
                          <small className="text-gray-400 text-[12px] font-bold">
                            {item.prompt}
                          </small>
                          <div className="flex gap-4 items-center">
                            <div className="w-12 h-12 bg-gray-500 flex items-center rounded-full justify-center">
                              <img
                                src={
                                  item?.profilePhoto
                                    ? item?.profilePhoto
                                    : leaderboard_img1
                                }
                                className="h-full w-full object-contain rounded-full"
                                alt=""
                              />
                            </div>
                            <div className="flex flex-col text-sm text-gray-300 font-bold">
                              <h2>
                                {item?.username?.length > 10
                                  ? item?.username.substring(0, 10) + "..."
                                  : item?.username}
                              </h2>
                              <h2>
                                {item?.name?.length > 20
                                  ? item?.name.substring(0, 20) + "..."
                                  : item?.name}
                              </h2>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          )}

          <div className="hidden md:flex justify-between">
            <div className="flex h-14 gap-6 items-center sticky top-0 justify-between">
              <div className="">
                <h1 className="text-[25px] font-bold">xdojo.ai</h1>
              </div>
              <PlanText name={state.overview?.payment_plan} />

              <SearchInput />
            </div>
            <div className="flex items-center gap-4">
              <button
                ref={bellRef}
                className="relative"
                onClick={toggleNotificationModal}
              >
                <BsBell size={20} />
                {notificationStatus && (
                  <div className="absolute top-[-50%]  right-[-10%] w-4 h-4 rounded-full bg-red-600 flex justify-center items-center"></div>
                )}
              </button>

              {/* <img src={bell} alt="" /> */}

              <button
                ref={settingRef}
                onClick={() => setProfileModal(!profileModal)}
                style={{
                  background: `linear-gradient(to bottom right, #292157 0%, #000000 100%)`,
                }}
                className="relative md:flex gap-2 items-center h-10 rounded-2xl border-2 border-solid border-[#FFE787] p-2"
              >
                <img
                  src={
                    state.overview?.profileimg
                      ? state.overview?.profileimg
                      : profile
                  }
                  className="w-6 h-6 rounded-full"
                  alt=""
                />

                <div>{state.overview?.xp * 10} XP</div>
                <button>
                  <MdOutlineArrowDropDown size={20} />
                </button>
                {profileModal && (
                  <div
                    ref={modalSettingRef}
                    className="absolute w-56 h-32 rounded-xl top-12 gap-2 right-0 flex flex-col bg-[#3E3769] z-10"
                  >
                    <button
                      onClick={() => navigate("/settings")}
                      className="flex items-center h-[28%] w-full  text-gray-300 px-4 py-4 text-sm hover:bg-black hover:bg-opacity-20"
                    >
                      Settings
                    </button>
                    <button
                      onClick={() => navigate("/settings/plan&billing")}
                      className="flex items-center h-[28%] w-full  text-gray-300 px-4 py-4 text-sm hover:bg-black hover:bg-opacity-20 "
                    >
                      {/*  */}
                      Update plan
                    </button>
                    <button
                      onClick={() => {
                        dispatch({ type: "LOGOUT" });
                        localStorage.removeItem("user");
                        navigate("/");
                      }}
                      className="flex items-center  h-[28%] w-full text-gray-300 px-4 py-4 text-sm hover:bg-black hover:bg-opacity-20"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </button>
            </div>
          </div>
          <div
         
            className="h-full w-full md:scroll-smooth md:overflow-auto md:px-0 md:py-2"
          >
            <Outlet />
          </div>
        </div>
      </div>

  );
};

export default Dashboard;
