import { useContext, useState } from "react";
import api from "../api";
import { AuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";


export const useImageUpload=()=>{
    const { currentUser } = useContext(AuthContext)
    const [loading,setLoading]=useState(false)
    const ImageUpload = async ({finalName}) => {
      setLoading(true)
        try {
          
          
    
          const response = await api.post('/upload', {finalName}, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${currentUser.token}`,
            },
          });
    
          if (response.status === 200) {
            toast.success("Image Uploaded successfully",{
                position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
                autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
                hideProgressBar: true, // Hide the progress bar
            })
            setLoading(false)
            return response.status
            
          } else {
            toast.error("Something Error occured",{
                position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
                autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
                hideProgressBar: true, // Hide the progress bar
            })
           
          }
        } catch (error) {
          console.error('Upload error:', error);
          toast.error("Something Error occured",{
            position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
            autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
            hideProgressBar: true, // Hide the progress bar
        })
         
        }
        setLoading(false)
        
      };
 
      return {ImageUpload,imgLoading:loading}
}