import React from "react";
import VideoImageImports from "../../assets";
import { GiSpeaker } from "react-icons/gi";
const DesignSongCard = ({ buttonName, music, musicImage }) => {
  const { quest_type_obj } = VideoImageImports;
  return (
    <div
      style={{
        border: "1px solid black",
        background: "#FFE8C5",
        boxShadow: "-15px -15px 30px 0px #30232E,15px 15px 30px 0px #30232E82",
      }}
      className="flex relative flex-col h-[48%] rounded-xl w-[180px]"
    >
      <div
        style={{
          background: "linear-gradient(180deg, #ECC383 0%, #7A4627 100%)",
        }}
        className="w-full h-[50%] rounded-t-xl"
      >
        <img
          src={quest_type_obj.Design_Song.items[musicImage]}
          className="w-full h-full object-contain"
          alt=""
        />
      </div>
      <div className="flex flex-col justify-center gap-2 items-center h-full">
        <p className="text-[12px] text-[#999999] ">Select this Beat</p>
        <button className="w-[130px] uppercase h-[37px] bg-[#E3FFF9] text-[#5471E8] rounded-full text-[14px] font-bold">
          {buttonName}
        </button>
      </div>
      <button className="absolute top-2 right-2 text-white z-50 bg-black bg-opacity-50 rounded-full w-6 h-6 flex items-center justify-center">
        <GiSpeaker size={20} />
      </button>
    </div>
  );
};

export default DesignSongCard;
