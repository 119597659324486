import React, { useState } from "react";

import ListContainer from "../components/academicsComponent/ListContainer";
import AcademicsModal from "../components/academicsComponent/AcademicsModal";

const Academics = () => {
  // const [academicModal, setAcademicModal] = useState(false);
  return (
    <>
      <div className="w-full flex flex-col gap-4">
        <ListContainer
          title="Mathematics"
          subject="math"
          subTitle="Discover and learn a new skill today!"
          data=""
        />
      </div>
      {/* {academicModal && <SubjectModal setAcademicModal={setAcademicModal} />} */}
      {/* {academicModal && <AcademicsModal setAcademicModal={setAcademicModal} />} */}

    </>
  );
};

export default Academics;
