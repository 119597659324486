import React, { useContext, useState } from "react";
import api from "../api";
import { AuthContext } from "../context/AuthContext";
import { QuestContext } from "../context/questContext/QuestContext";
import {
  SET_QUEST_AI_ID,
  SET_QUEST_PROMPT,
} from "../context/questContext/action";
import { AiContext } from "../context/aicontext/AiContext";
import { SET_AI_MESSAGES } from "../context/aicontext/action";

const useAITextchat = () => {
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState("");
  const [aiLoader, setAiLoader] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const { dispatch, state } = useContext(AiContext);
  const { questDispatch } = useContext(QuestContext);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser.token}`,
  };
  const aiTextChat = async (body) => {
    setLoading(true);
    try {
      let res = await api.post("/aiChatText", body, {
        headers,
      });
      if (res.status === 200) {
        questDispatch({ type: SET_QUEST_PROMPT, payload: body.prompt });
        questDispatch({ type: SET_QUEST_AI_ID, payload: res.data.chatId });
        setAiLoader(true);
        dispatch({
          type: SET_AI_MESSAGES,
          payload: {
            id: state.messages.length + 1,
            questPrompt: body.prompt,
            displayedAnswer: res.data.answer,
          },
        });
        setAnswer(res.data.answer);
      }
    } catch (err) {
      console.log(err.response.message);
    }
    setLoading(false);
  };

  return { aiTextChat, answer, ailoading: loading, aiLoader, setAiLoader };
};

export default useAITextchat;
