import React, { useContext, useEffect, useRef, useState } from "react";
import DesignQuest from "../../layouts/DesignQuest";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import VideoImageImports from "../../assets";
import { LazyLoadImage } from "react-lazy-load-image-component";
import destopImage from "../../assets/screens/webpage/desktop.webp";
import { BiSolidSkipNextCircle } from "react-icons/bi";
import { BiSolidSkipPreviousCircle } from "react-icons/bi";
import { useNewGenerateImage } from "../../hooks/useNewGenerateImage";
import { useDownload } from "../../hooks/useDownload";
import { SyncLoader } from "react-spinners";
import { AiOutlineDownload } from "react-icons/ai";
import { MdOutlineZoomInMap } from "react-icons/md";
import ZoomImage from "./ZoomImage";
import { useParams } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
const designThemes = [
  {
    id: 1,
    name: "Minimalistic",
    description:
      "Utilizes clean lines, ample white space, and simple typography for a sleek and modern appearance. Prompts may include preferences for minimalist layouts, uncluttered designs, and subtle color palettes.",
  },
  {
    id: 2,
    name: "Futuristic",
    description:
      "Incorporates geometric shapes, vibrant colors, and unconventional layouts to create a cutting-edge aesthetic reminiscent of the future. Prompts may include requests for bold, abstract elements, and experimental design approaches.",
  },
  {
    id: 3,
    name: "Vintage",
    description:
      "Draws inspiration from nostalgic elements, muted tones, and classic typography to evoke a retro-inspired feel. Prompts may include preferences for vintage imagery, aged textures, and retro color schemes.",
  },
  {
    id: 4,
    name: "Material",
    description:
      "Embraces flat design with subtle shadows, bold colors, and intuitive user interfaces, following the principles of Google's Material Design. Prompts may include requests for clean, responsive layouts, and emphasis on user interaction.",
  },
  {
    id: 5,
    name: "Illustrative",
    description:
      "Features hand-drawn illustrations, whimsical elements, and playful animations to create a creative and artistic website. Prompts may include preferences for custom illustrations, animated elements, and artistic flourishes.",
  },
  {
    id: 6,
    name: "Corporate",
    description:
      "Exhibits a professional layout, sophisticated color palette, and elegant typography suitable for corporate websites. Prompts may include requests for clean, professional designs, and emphasis on brand identity.",
  },
];
const designTheme2 = [
  {
    id: 7,
    name: "Neon",
    description:
      "Utilizes neon colors, futuristic typography, and glitch effects to capture a cyberpunk-inspired aesthetic. Prompts may include preferences for neon accents, futuristic elements, and glitchy animations.",
  },
  {
    id: 8,
    name: "Nature",
    description:
      "Draws from organic shapes, earthy tones, and natural textures to create a website connected to the outdoors. Prompts may include requests for nature-themed imagery, earthy color palettes, and organic design elements.",
  },
  {
    id: 9,
    name: "Gothic",
    description:
      "Features dark backgrounds, elegant typography, and dramatic imagery for a mysterious and stylish design. Prompts may include preferences for dark color schemes, gothic-inspired motifs, and sophisticated typography.",
  },
  {
    id: 10,
    name: "Urban",
    description:
      "Incorporates grungy textures, gritty typography, and industrial elements for an edgy and urban vibe. Prompts may include requests for urban-themed graphics, distressed textures, and industrial color palettes.",
  },
  {
    id: 11,
    name: "Photography",
    description:
      "Showcases large, immersive images, minimalist layout, and elegant typography to highlight photography. Prompts may include preferences for image-centric layouts, gallery features, and minimalistic design elements.",
  },
  {
    id: 12,
    name: "Interactive",
    description:
      "Utilizes dynamic charts, interactive maps, and engaging data visualizations to present information in an interactive format. Prompts may include requests for data visualization tools, interactive elements, and infographic-style layouts.",
  },
  {
    id: 13,
    name: "Typography",
    description:
      "Highlights bold typography, creative font pairings, and minimalistic design to draw attention to text content. Prompts may include preferences for typography-driven layouts, custom font selection, and emphasis on typography hierarchy.",
  },
  {
    id: 14,
    name: "Cinematic",
    description:
      "Creates a cinematic feel with full-screen video backgrounds, parallax scrolling, and immersive animations. Prompts may include requests for video integration, cinematic effects, and immersive scrolling experiences.",
  },
  {
    id: 15,
    name: "Gaming",
    description:
      "Incorporates bold colors, pixel art elements, and dynamic layouts suitable for gaming or esports websites. Prompts may include preferences for gaming-inspired graphics, dynamic animations, and interactive gaming features.",
  },
];

const DesignCreateWebpage = () => {
  const { quest_type } = useParams();
  const { quest_type_obj } = VideoImageImports;
  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0
  const { errorState } = useContext(ErrorContext);
  const [itemModal, setItemModal] = useState(false);
  const imgRef = useRef(null);
  const [index, setIndex] = useState(0);
  const [zoomStatus, setZoomStatus] = useState(false);
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });

  const [desc, setDesc] = useState("");
  const [button, setButton] = useState([]);

  const handleButton = (butt) => {
    if (button.length >= 4) {
      alert("Maximum styles limit is four only");
      return;
    }
    if (button.includes(butt)) {
      setButton(button.filter((e) => e !== butt));
    } else {
      setButton([...button, butt]);
    }
  };
  const { fetchImage, imgloading, images } = useNewGenerateImage();
  const { downloadFunction, downloading } = useDownload();
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
  }, []);
  const modalStyle = {
    background: "linear-gradient(to right, #C0D9FF, #FFFFFF)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };
  useEffect(() => {
    const updateDimensions = () => {
      if (imgRef.current) {
        setImgDimensions({
          width: imgRef.current.offsetWidth,
          height: imgRef.current.offsetHeight,
        });
      }
    };

    // Update dimensions initially
    updateDimensions();

    // // Optional: Update dimensions on window resize
    // window.addEventListener('resize', updateDimensions);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  const handleChangeImage = (item) => {
    if (item === "prev") {
      setIndex(index - 1);
    } else {
      setIndex(index + 1);
    }
  };
  const handleGenerateButton = async () => {
    const imagePrompt = `Create a website webpage for ${desc}, having ${button.join(
      ","
    )}`;
    await fetchImage({
      prompt: imagePrompt,
      quest_type,
      width:
        imgDimensions.width % 8 === 0
          ? imgDimensions.width
          : imgDimensions.width + (imgDimensions.width % 8),
      height:
        imgDimensions.height % 8 === 0
          ? imgDimensions.height
          : imgDimensions.height + (imgDimensions.height % 8),
      num: 4,
      modelId: "ac614f96-1082-45bf-be9d-757f2d31c174",
    });
    setDesc("");
    setButton([]);
  };
  return (
    <DesignQuest modalStyle={modalStyle} title="Create Your Own Webpage">
      {zoomStatus && (
        <ZoomImage
          singleImageUrl={images?.generated_images?.[index]?.url}
          zoomStatus={zoomStatus}
          setZoomStatus={setZoomStatus}
        />
      )}
      {errorState.errorModal && <LimitErrorModal />}
      {/* <LazyLoadImage
        alt="Background"
        style={{ zIndex: -1000000000 }}
        threshold={150} // This is where you specify the threshold
        src={quest_type_obj.Create_Webpage.screen.bg1} // Your dynamic background image source
        effect="blur"
        className="object-cover absolute inset-0 w-full h-full z-[-100]"
        wrapperClassName="w-full object-cover h-full absolute top-0 left-0 z-[-100]"
      /> */}
      <div className="flex flex-col gap-4">
        {/* title */}
        <div className="flex justify-center items-center mt-2">
          <div className="px-2 py-2 w-[250px] rounded-xl flex justify-center items-center bg-[#5471E8] text-white text-xl">
            Webpage Design
          </div>
        </div>
        {/* description */}
        <div className="flex justify-center items-center">
          <div className="px-2 py-2 w-[600px] rounded-lg flex justify-center items-center bg-white text-black text-[12px]">
            Design Your Webpage in 3 Steps: Enter Your Prompt, Select Style,
            Click on Generate
          </div>
        </div>

        <div className="flex  justify-center gap-10  w-full h-[800px] px-auto mt-8">
          <div className="relative rounded-xl flex-shrink w-[500px] h-[550px]">
            <div
              style={{
                lineHeight: 1.8,
              }}
              className="relative text-black flex flex-col gap-6 rounded-xl flex-shrink  w-full px-2 py-2 h-full"
            >
              <div className="flex flex-col  text-black gap-4">
                <div className="bg-white flex items-center gap-2 w-[170px] px-2 py-1 rounded-lg">
                  <div className="w-4 h-4">
                    <img
                      className="w-full h-full object-cover"
                      src={quest_type_obj.Create_Webpage.screen.title}
                      alt=""
                    />
                  </div>
                  <h1 className="text-black uppercase font-bold">
                    Description:
                  </h1>
                </div>
                <textarea
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  style={{
                    resize: "none",
                    outline: "none",
                  }}
                  className="w-[95%] h-[150px] rounded-3xl px-4 py-2  bg-white"
                  placeholder="Enter your prompt..."
                ></textarea>
              </div>
              <div className="flex flex-col mt-4 text-black gap-4">
                <div className="bg-white flex items-center gap-2 w-[170px] px-2 py-1 rounded-lg">
                  <div className="w-4 h-4">
                    <img
                      className="w-full h-full object-cover"
                      src={quest_type_obj.Create_Webpage.screen.title}
                      alt=""
                    />
                  </div>
                  <h1 className="text-black uppercase font-bold">Styles:</h1>
                </div>
                <div className="flex flex-wrap gap-x-6 gap-y-4">
                  {designThemes.map((e, i) => (
                    <button
                      onClick={() => handleButton(e.description)}
                      className={`${
                        button.includes(e.description) ? "opacity-20" : ""
                      } w-[30%] px-2 py-2 gap-4 bg-[#5471E8] flex justify-center rounded-2xl items-center cursor-pointer`}
                    >
                    
                      <p className="text-white font-bold uppercase text-[12px]">
                        {e.name}
                      </p>
                      <FaInfoCircle color="white" size={15} className="" data-tooltip-id={`webpagedesign-${i}`} />
                      <Tooltip
                        style={{ zIndex: 10000000000,width:"300px",backgroundColor:"black",fontFamily:"cursive",textAlign:"center" }}
                        place="top-start"
                        id={`webpagedesign-${i}`}
              
                      >
                        {e.description}
                      </Tooltip>
                    </button>
                  ))}
                </div>
              </div>
              {itemModal && (
                <div
                  style={{ border: "2px dashed black", zIndex: 100000000000 }}
                  className="absolute top-[5%] bg-white left-[26%] w-[350px] h-[420px] rounded-xl px-2 py-2 flex flex-wrap gap-x-6 gap-y-2"
                >
                  {designTheme2.map((e, i) => (
                    <div className="flex flex-col gap-4 w-[28%] h-[25%] rounded-xl items-center">
                      <div
                        style={{ border: "2px dashed black" }}
                        onClick={() => handleButton(e.description)}
                        className={`${
                          button.includes(e.description) ? "opacity-20" : ""
                        } w-[99%] h-[99%] rounded-xl flex justify-center items-center cursor-pointer`}
                      >
                        <LazyLoadImage
                          alt="Background"
                          threshold={150} // This is where you specify the threshold
                          src={
                            quest_type_obj.Create_Webpage.screen.items[
                              `item${e.id}`
                            ]
                          } // Your dynamic background image source
                          effect="blur"
                          className="object-contain w-full h-full "
                          wrapperClassName="w-full object-contain h-full"
                        />
                      </div>
                      <p className="text-black font-bold uppercase text-[12px]">
                        {e.name}
                      </p>
                    </div>
                  ))}
                </div>
              )}

              <div className="flex items-center text-black gap-6">
                <div className="w-[100px]"></div>
                <div className="w-[350px] h-[50px] rounded-xl px-2 py-2 flex justify-between items-center">
                  {/* <button
                  onClick={() => setItemModal(!itemModal)}
                  style={{ border: "2px dashed black" }}
                  className=" flex justify-center font-bold items-center w-[150px] rounded-xl bg-gray-200 h-[40px] px-2 py-2"
                >
                  More Styles
                </button> */}
                  <button
                    onClick={handleGenerateButton}
                    disabled={!desc || !button.length || imgloading}
                    style={{ border: "2px dashed black" }}
                    className=" bg-[#cde75e] flex justify-center font-bold items-center w-[150px] rounded-xl h-[40px] px-2 py-2"
                  >
                    Generate
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 mt-4">
            <div
              style={{ zIndex: 1000000 }}
              ref={imgRef}
              className="relative shadow-2xl overflow-hidden flex flex-col w-[700px] rounded-lg h-[400px] text-black"
            >
              {imgloading ? (
                <SyncLoader
                  color="#36d7b7"
                  style={{
                    zIndex: 10000,
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                    marginTop: "30px",
                  }}
                />
              ) : (
                ""
              )}
              {!imgloading && images?.generated_images && (
                <img
                  src={images?.generated_images?.[index]?.url}
                  className="w-full rounded-lg aspect-square h-full hover:scale-[1.2] object-contain transition-transform duration-300 ease-in-out"
                  alt=""
                />
              )}
              <div className="flex flex-col gap-4 right-5 items-center absolute bottom-2">
                <button
                  disabled={downloading}
                  onClick={() =>
                    downloadFunction({
                      imgUrl: images?.generated_images?.[index]?.url,
                    })
                  }
                  style={{ zIndex: 100 }}
                  className="w-8 h-8 flex items-center justify-center bg-gray-300 rounded-full"
                >
                  {!downloading ? (
                    <AiOutlineDownload className="text-black" size={20} />
                  ) : (
                    <span className="text-black font-bold text-lg">...</span>
                  )}
                </button>
                <button
                  onClick={() => setZoomStatus(true)}
                  style={{ zIndex: 100 }}
                  className="w-8 h-8   flex items-center justify-center bg-gray-300 rounded-full"
                >
                  <MdOutlineZoomInMap className="text-black" size={20} />
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center text-black gap-5">
            {images?.generated_images && !imgloading && (
              <div className="flex gap-4 items-center">
                <button
                  disabled={index === 0}
                  onClick={() => handleChangeImage("prev")}
                  className={`${index === 0 ? "opacity-20" : ""}`}
                >
                  <BiSolidSkipPreviousCircle size={30} />
                </button>
                <button
                  disabled={index === images?.generated_images?.length - 1}
                  onClick={() => handleChangeImage("next")}
                  className={`${
                    index === images?.generated_images?.length - 1
                      ? "opacity-20"
                      : ""
                  } `}
                >
                  <BiSolidSkipNextCircle size={30} />
                </button>
              </div>
            )}
      
      </div>
          </div>
        </div>
      </div>
    </DesignQuest>
  );
};

export default DesignCreateWebpage;
