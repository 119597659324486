import React, { useContext, useState } from 'react'
import { AuthContext } from '../context/AuthContext';
import api from '../api';

const useFreeStyleHistory = () => {
    const [loading,setLoading]=useState(false)
    const [data,setData]=useState([])
    const { currentUser } = useContext(AuthContext);
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${currentUser.token}`,
      };
    const aifreeHisttory=async()=>{
        setLoading(true)
        try{
            let res=await api.get("/freeStyleCoding-get",{
                headers
            })
            if(res.status===200){
                setData(res.data.data)
            }

        }
        catch(err){
            console.log(err.response.message)

        }
        setLoading(false)
    }
   return {aifreeHisttory,aifreeHisttoryData:data,aiFreeHisLoad:loading}
}

export default useFreeStyleHistory