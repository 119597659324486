import React, { useEffect, useState } from "react";
import styles from "../styles/PricingPlan.module.css";
import { useCheckout } from "../hooks/useCheckout";
import { useCancelPayment } from "../hooks/useCancelPayment";
const PricingPlan = ({
  plan,
  price,
  features,
  isPremium,
  seven_days_trail,
  prev_Plan,
  payment_status,
}) => {
  const { loading, handleCheckout } = useCheckout();
  const { cancelPayment, cancelLoading, cancelStatus } = useCancelPayment();
  const handlePayment = async () => {
    await handleCheckout({ plan, amount: price, unit: price * 100 });
  };
  const [buttonText, setButtonText] = useState(null);
  useEffect(() => {
    if (payment_status && prev_Plan !== plan) {
      setButtonText("Upgrade");
    } else if (payment_status && prev_Plan === plan) {
      setButtonText("Cancel");
    }
  }, []);
  const handleCancel = async () => {
    await cancelPayment();
  };
  return (
    <div
      className={`min-h-[480px] max-h-[510px] w-full md:w-[90%] max-w-[500px] px-4 py-4 rounded-lg overflow-hidden shadow-lg bg-gray-900 flex flex-col`}
    >
      <div className="px-2 md:px-6 py-4 flex-grow">
        {/* Increased the font size for the plan type header */}
        <div className={`${styles.shinyText} font-bold text-3xl mb-4`}>
          {plan} Plan
        </div>
        {plan === "Free" ? (
          <p className={`text-3xl lg:text-5xl font-bold my-4 ${styles.shinyText}`}>${price}</p>
        ) : (
          <p className={`text-3xl lg:text-5xl font-bold my-4 text-white`}>
            ${price}{" "}
            <span
           
             className="text-sm font-semibold">
              /PER MONTH {!seven_days_trail ? "(7 DAYS FREE TRAIL)" : ""}
            </span>
          </p>
        )}
        <div className="mt-10 flex flex-col justify-center gap-6">
          {features.map((feature, index) => (
            // Increased the font size for the feature list items
            <div className="flex items-center gap-4">
              <div>

              <span className="bg-gray-200 text-center rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ">
                ✔
              </span>
              </div>
              <p key={index} className="text-gray-200 text-[15px] mt-[5px]">
              
              {feature}
            </p>
            </div>
        
          ))}
        </div>
      </div>
      <div className="px-2 md:px-6 pt-4 pb-2">
        {prev_Plan === "Free" && plan === "Free" ? (
          <button
            disabled={true}
            className={`rounded-full py-2 px-4 text-sm font-semibold text-white transition duration-300 ease-in-out transform hover:scale-105 bg-gradient-to-r from-shiny-purple via-shiny-pink to-shiny-red hover:from-shiny-pink hover:to-shiny-red`}
          >
            Subscribed
          </button>
        ) : (
          !buttonText && (
            <button
              onClick={handlePayment}
              className={`rounded-full py-2 px-4 text-sm font-semibold text-white transition duration-300 ease-in-out transform hover:scale-105
              ${
                isPremium
                  ? "bg-gradient-to-r from-shiny-purple via-shiny-pink to-shiny-red hover:from-shiny-pink hover:to-shiny-red"
                  : "bg-blue-700 hover:bg-blue-800"
              }`}
            >
              {loading ? "Subscribing..." : "Get Started"}
            </button>
          )
        )}
        {buttonText === "Upgrade" && (
          <button
            onClick={handlePayment}
            className={`rounded-full py-2 px-4 text-sm font-semibold text-white transition duration-300 ease-in-out transform hover:scale-105
              ${
                isPremium
                  ? "bg-gradient-to-r from-shiny-purple via-shiny-pink to-shiny-red hover:from-shiny-pink hover:to-shiny-red"
                  : "bg-blue-700 hover:bg-blue-800"
              }`}
          >
            {loading ? "Subscribing..." : "Upgrade"}
          </button>
        )}

        {buttonText === "Cancel" && (
          <button
            onClick={handleCancel}
            className={`rounded-full py-2 px-4 text-sm font-semibold text-white transition duration-300 ease-in-out transform hover:scale-105
              ${
                isPremium
                  ? "bg-gradient-to-r from-shiny-purple via-shiny-pink to-shiny-red hover:from-shiny-pink hover:to-shiny-red"
                  : "bg-blue-700 hover:bg-blue-800"
              }`}
          >
            {cancelLoading ? "Processing..." : "Cancel"}
          </button>
        )}
      </div>
    </div>
  );
};

export default PricingPlan;
