import React from "react";
import { motion } from "framer-motion";
import { SlClose } from "react-icons/sl";
const ZoomImage = ({ zoomStatus, singleImageUrl,setZoomStatus }) => {
    const modalVariants = {
        hidden: { x: '100vh', opacity: 0 },
        visible: { x: 0, opacity: 1, transition: { duration: 1 } },
        exit: { x: '100vh', opacity: 0, transition: { duration: 1 } }
      };

  return (
    <motion.div
      style={{ zIndex: 100000000000000 }}
      initial="hidden"
      animate={zoomStatus ? "visible" : "exit"}
      variants={modalVariants}
      className={`fixed inset-0 w-full h-full bg-black flex items-center justify-center`}
    >
      <div className="relative w-full h-full flex justify-center items-center">
        <button
          className="text-black bg-white rounded-full absolute top-[12%] md:top-[8%] right-[5%] md:right-[5%] cursor-pointer"
          onClick={()=>setZoomStatus(false)}
        >
          <SlClose className="text-[22px] md:text-[30px]" />
        </button>
  
        <img
          src={singleImageUrl}
          className="w-[90%] h-[90%] object-contain"
          alt=""
        />
      </div>
    </motion.div>
  );
};

export default ZoomImage;
