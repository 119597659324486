import React from 'react'
import useOverItem from '../hooks/useOverItem';


const AdminPrivatreRoutes = ({children}) => {
    const {state}=useOverItem()
    if(!state.overview?.role==="admin"){
        return (
            <div>You don't have this access routes</div>
        )
    }

    return children;
}

export default AdminPrivatreRoutes