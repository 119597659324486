import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
const changepasswordschema = Yup.object().shape({
  currentpassword: Yup.string().required("Cannot be left blank"),
  newpassword: Yup.string().required("Cannot be left blank"),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
    .required("Cannot be left blank"),
});
const loginschema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email cannot be left blank"),
  password: Yup.string().required("Password cannot be left blank"),
});
const blogschema = Yup.object().shape({
  title: Yup.string().required("Please enter"),
  description: Yup.string().required("Please enter"),
  language: Yup.string().required("Please select the language"),
});
const signupschema = Yup.object().shape({
  username: Yup.string().required("Cannot be left blank"),
  email: Yup.string()
    .email("Enter valid email")
    .required("Cannot be left blank"),
  password: Yup.string().required("Cannot be left blank"),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords do not match")
    .required("Cannot be left blank"),
});
const postschema = Yup.object().shape({
  post: Yup.string().required("Enter your post"),
});
const profileupdateschema = Yup.object().shape({
  parent_email: Yup.string()
    .email("Enter valid email")
    .required("Cannot be left blank"),
  fullname: Yup.string().required("Cannot be left blank"),
  username: Yup.string().required("Cannot be left blank"),
  phoneno: Yup.string()
  .required("Phone is required")
  .test(
    'isValidPhoneNumber', 
    'Phone number is not valid', 
    value => isValidPhoneNumber(value)
  ),
});
export {
  changepasswordschema,
  loginschema,
  blogschema,
  signupschema,
  postschema,
  profileupdateschema,
};
