import React, { useContext, useEffect, useRef, useState } from "react";
import DesignQuest from "../../layouts/DesignQuest";
import { SyncLoader } from "react-spinners";
import frame1 from "../../assets/screens/logoscreen/items/minimal.webp";
import frame2 from "../../assets/screens/logoscreen/items/logo.webp";
import frame3 from "../../assets/screens/logoscreen/items/rihno.webp";
import frame4 from "../../assets/screens/logoscreen/items/tv.webp";
import { AiOutlineDownload } from "react-icons/ai";
import { useDownload } from "../../hooks/useDownload";
import { useNewGenerateImage } from "../../hooks/useNewGenerateImage";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import SVGIMAGE from "../../assets/screens/logoscreen/items/SVG.webp";
import { useParams } from "react-router-dom";
import styles from "../../styles/screens/DesignLogo.module.css";
const imageArray = [0, 1, 2, 3];
const buttonArray = [
  {
    name: "Minimalistic",
    img: frame1,
  },
  {
    name: "Modern",
    img: frame2,
  },
  {
    name: "Ultra Detail",
    img: frame3,
  },
  {
    name: "Vintage",
    img: frame4,
  },
];
const DesignLogo = () => {
  const { quest_type } = useParams();
  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0
  const imgRef = useRef(null);
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });
  const [desc, setDesc] = useState("");
  const { errorState } = useContext(ErrorContext);
  const [button, setButton] = useState([]);
  const [currIndex, setCurrIndex] = useState(null);
  const handleButton = (butt) => {
    if (button.includes(butt)) {
      setButton(button.filter((e) => e !== butt));
    } else {
      setButton([...button, butt]);
    }
  };

  const { fetchImage, imgloading, images } = useNewGenerateImage();
  const { downloadFunction, downloading } = useDownload();

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
  }, []);

  const modalStyle = {
    background: "linear-gradient(180deg, #FEFEFE 0%, #C8C8D6 100%)",

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };

  useEffect(() => {
    const updateDimensions = () => {
      if (imgRef.current) {
        setImgDimensions({
          width: imgRef.current.offsetWidth,
          height: imgRef.current.offsetHeight,
        });
      }
    };

    // Update dimensions initially
    updateDimensions();

    // // Optional: Update dimensions on window resize
    // window.addEventListener('resize', updateDimensions);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const handleGenerateButton = async () => {
    const imagePrompt = `Create a Logo for ${desc},${button.join(",")}`;
    await fetchImage({
      prompt: imagePrompt,
      quest_type,
      width:
        imgDimensions.width % 8 === 0
          ? imgDimensions.width
          : imgDimensions.width - (imgDimensions.width % 8),
      height:
        imgDimensions.height % 8 === 0
          ? imgDimensions.height
          : imgDimensions.height - (imgDimensions.height % 8),
      num: 4,
      modelId: "ac614f96-1082-45bf-be9d-757f2d31c174",
    });
    setDesc("");
    setButton([]);
  };

  return (
    <DesignQuest modalStyle={modalStyle} title="Create Your Logo">
      {errorState.errorModal && <LimitErrorModal />}

      <div className="flex flex-col justify-center items-center gap-2 mt-2">
        <h1 className={`${styles.logo_header} uppercase`}>Logo Studio</h1>
        <div
          style={{
            background: " #FFFFFF",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "7px",
          }}
          className="w-[587px] h-[41px] flex justify-start items-center gap-4 px-4"
        >
          <div
            style={{
              background: "#1875FF",
            }}
            className="w-6 h-6 rounded-full"
          ></div>
          <h1 className="font-[400] text-[16px]">
            Create the Fun Logo you've Imagined in Xdojo’s Logo Studio.
          </h1>
        </div>
      </div>

      <div
        style={{ zIndex: 10000 }}
        className="flex gap-20 justify-center items-center px-2 py-2"
      >
        <div className="relative flex flex-col background-transparent gap-2 items-center py-4 px-4 rounded-xl flex-shrink w-[35%]">
          <textarea
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            placeholder="Design your logo here..."
            style={{ resize: "none" }}
            className="outline-none text-black text-sm w-[70%] h-[15vh] background-transparent rounded-xl px-4 py-4 shadow-xl"
          ></textarea>

          <div className="flex flex-wrap items-center justify-center gap-x-4 w-full mt-2 h-[40vh]">
            {buttonArray.map((e) => (
              <div className="flex flex-col  rounded items-center gap-2 w-[35%] h-[48%]">
                <div
                  onClick={() => handleButton(e.name)}
                  className={`${
                    button.includes(e.name) ? "opacity-20" : ""
                  } cursor-pointer w-[75%] bg-white rounded-xl flex items-center justify-center h-[65%]`}
                >
                  <img
                    src={e.img}
                    alt=""
                    className="w-[90%] h-[90%] rounded-xl object-contain"
                  />
                </div>
                <span className="text-black text-[14px] uppercase font-bold">
                  {e.name}
                </span>
              </div>
            ))}
          </div>
          <div className="absolute bottom-[-7%] left-1/2 transform -translate-x-1/2 flex gap-4">
            <button
              disabled={!desc || !button || imgloading}
              onClick={handleGenerateButton}
              style={{
                background: "#1875FF",
                boxShadow: "0px 14px 56px -11px #1875FF",

                mixBlendMode: "normal",
                lineHeight: "28px",
              }}
              className="outline-none px-4 py-2  uppercase text-[20px] text-white rounded-lg w-[100%]"
            >
              Generate
            </button>
          </div>
        </div>
        <div
          // ref={imgRef}
          style={{
            background: "linear-gradient(45deg,#11263d, #11274d, #11263d)",
          }}
          className="relative rounded-3xl w-[50%] flex flex-wrap justify-center items-center  gap-x-4 px-4 py-4 h-[70vh] mt-[2rem]"
        >
          {imageArray.map((e, index) => (
            <div
              ref={imgRef}
              className={`cursor-pointer relative rounded-lg w-[42%] h-[44%] overflow-hidden bg-gray-400 flex flex-col items-center`}
            >
              {!imgloading && !images?.generated_images && (
                <img
                  src={SVGIMAGE}
                  className="w-12 m-auto h-12 object-cover"
                  alt=""
                />
              )}
              {imgloading ? (
                <SyncLoader
                  color="#36d7b7"
                  style={{
                    zIndex: 10000,
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                    marginTop: "30px",
                  }}
                />
              ) : (
                ""
              )}

              {!imgloading && images?.generated_images && (
                <img
                  src={images?.generated_images?.[index]?.url}
                  className="w-full h-full hover:scale-[1.2] object-cover transition-transform duration-300 ease-in-out"
                  alt=""
                />
              )}
              <button
                disabled={downloading || !images.generated_images}
                onClick={async () => {
                  setCurrIndex(index);
                  await downloadFunction({
                    imgUrl: images?.generated_images?.[index]?.url,
                  });
                  setCurrIndex(null);
                }}
                style={{ zIndex: 10000000 }}
                className="absolute bottom-5 px-2 py-2 bg-gray-300 rounded-full right-5"
              >
                {currIndex !== e ? (
                  <AiOutlineDownload className="text-black" size={15} />
                ) : (
                  <span className="text-black font-bold text-lg">...</span>
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
    </DesignQuest>
  );
};

export default DesignLogo;
