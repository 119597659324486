import { useState, useEffect } from 'react';

export const useCountdownTimer = (initialMinutes = 2, initialSeconds = 0) => {
    const [isActive, setIsActive] = useState(false);
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        let interval = null;

        if (isActive && (minutes > 0 || seconds > 0)) {
            interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }, 1000);
        } else if (!isActive && minutes === 0 && seconds === 0) {
            resetTimer();
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isActive, minutes, seconds]);

    const startTimer = () => setIsActive(true);
    const stopTimer = () => setIsActive(false);

    const resetTimer = () => {
        setIsActive(false);
        setMinutes(initialMinutes);
        setSeconds(initialSeconds);
    };

   

    return { minutes,seconds, startTimer, stopTimer, resetTimer };
};

