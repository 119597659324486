import { saveAs } from "file-saver"
import { useState } from "react"
import { toast } from "react-toastify"
export const useDownload=()=>{
    const [loading,setLoading]=useState(false)
    const downloadFunction=async({imageName,imgUrl})=>{
        setLoading(true)
        try {
            
     
              const response = await fetch(imgUrl)
              const blob = await response.blob()
              console.log("hello download")
              const currentDate = new Date();
              const timestamp = currentDate.toISOString().replace(/:/g, '-'); // Replace colons for file name compatibility
              
              // Use 'imageName' if provided, else default to 'xdojo' with the timestamp
               saveAs(blob, `${imageName || "xdojo"}-${timestamp}.jpg`);
               toast.success("Downloaded Successfully",{
                position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
                autoClose: 1000, // Auto-close the toast after 3000 milliseconds (3 seconds)
                hideProgressBar: true, // Hide the progress bar

            })
            
          } catch (err) {
            toast.err("failed to Downlaad Image",{
                position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
                autoClose: 1000, // Auto-close the toast after 3000 milliseconds (3 seconds)
                hideProgressBar: true, // Hide the progress bar

            })
          }
        setLoading(false)
    }
    
    return {downloadFunction,downloading:loading}
}