import React, { useState } from "react";
import { motion } from 'framer-motion';
import { BsFillCaretRightFill } from "react-icons/bs";
import { BsFillCaretLeftFill } from "react-icons/bs";
import { SlClose } from "react-icons/sl";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  subMonths,
  addMonths,
  isSameMonth,
  isSameDay,
} from "date-fns";
import useOverItem from "../../hooks/useOverItem";
import { SET_STREAK_MESSAGE } from "../../context/overviewcontext/action";

const modalVariants = {
  hidden: { y: '-100vh', opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 1 } },
  exit: { y: '-100vh', opacity: 0, transition: { duration: 1 } }
};
const Calendar = ({ streakDays }) => {
  // State to hold the current month being displayed
  const [currentMonth, setCurrentMonth] = useState(new Date());

  // Determine if the next month button should be disabled
  const isNextMonthDisabled = () => {
    const today = new Date();
    const nextMonth = addMonths(currentMonth, 1);
    return nextMonth.getMonth() > today.getMonth() || nextMonth.getFullYear() > today.getFullYear();
  };
  const nextMonthDisabled = isNextMonthDisabled();
  // Function to render the header
  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    return (
      <div className="flex items-center justify-center gap-4 p-2">
        <button onClick={prevMonth} className="p-1">
          <BsFillCaretLeftFill />
        </button>
        <span>{format(currentMonth, dateFormat)}</span>
        <button onClick={nextMonth} disabled={isNextMonthDisabled()} className={`p-1 ${nextMonthDisabled?"opacity-20":""}`}>
          <BsFillCaretRightFill />
        </button>
      </div>
    );
  };

  // Function to render the days of the week
  const renderDays = () => {
    const dateFormat = "EEE"; // Shorter day names
    const days = [];
    let startDate = startOfWeek(currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="w-1/7 text-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>,
      );
    }

    return <div className="flex w-full justify-around mb-4 ml-[-1%]">{days}</div>; // Use justify-around for even spacing
  };

  // Function to render the cells
  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div className="w-1/7 flex justify-center" key={day.toString()}> {/* Corrected width to w-1/7 */}
            <div
            style={{backgroundColor:`${isSameDay(cloneDay, new Date()) ? "#1da1f2" : ""}`}}
              className={`w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full mx-auto ${
                isSameMonth(day, monthStart) ? "bg-gray-200" : "bg-gray-50"
              }
              ${
                isSameDay(cloneDay, new Date()) ? "text-white" : ""
              }
              `}
            >
              <span className="block">{formattedDate}</span>
              {streakDays.some((streakDay) => isSameDay(cloneDay, new Date(streakDay))) && (
                <span role="img" aria-label="fire" className="text-xs">
                  🔥
                </span>
              )}
            </div>
          </div>,
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="flex justify-around items-center mb-1 w-full" key={day.toString()}>
          {days}
        </div>,
      );
      days = [];
    }
    return <div className="flex flex-col justify-center gap-4">{rows}</div>;
  };

  // Function to switch to the previous month
  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  // Function to switch to the next month
  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  return (
    <div
      className="bg-white mt-[-5%] rounded-lg overflow-auto md:shadow-lg mx-auto my-8 md:p-4 w-full md:w-[50%] h-[70%]"
    >
      {renderHeader()}
      <hr/>
      <br/>
      {renderDays()}
      {renderCells()}
    </div>
  );
};

const CalenderPop = () => {
  // Example streak days - you would get these from your backend or state
const {state,dispatch}=useOverItem()
 // Function to close the modal
 const handleClose = () => {
  // You'll need to implement modal closing logic here
  dispatch({type:SET_STREAK_MESSAGE,payload:""})
};
  return (
    <motion.div 
    style={{zIndex:100000000}}
    
      initial="hidden"
      animate={state.streakDays.length? "visible" : "exit"}
      variants={modalVariants}
      className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center`}
    >
      <div className="text-black bg-white p-4 flex flex-col justify-center items-center md:rounded-lg shadow-lg w-full h-full  md:h-[90%] md:w-[80%]">
      <button className="text-black absolute top-[12%] md:top-[10%] right-[5%] md:right-[15%] cursor-pointer" onClick={handleClose}>
         <SlClose className="text-[22px] md:text-[30px]"/>
        </button>
        <div className="flex flex-col jusitfy-center items-center mb-[7%]">
          <h2 className="uppercase font-bold text-[14px] md:text-[20px]">Your Best Score: {state.best_longest_streak} days</h2>
        </div>
      <Calendar streakDays={state.streakDays} />
      <div className="flex flex-col jusitfy-center items-center gap-4">
      <div className="flex items-center gap-2">

        <h2 className="uppercase font-bold text-[14px] md:text-[20px]">Current Streak:</h2>
        <div className="flex items-center">
          
<h2 className="uppercase font-bold text-[14px] md:text-[20px]">{state.daily_count}</h2>
        <span role="img" aria-label="fire" className="text-xs">
                  🔥
                </span>

        </div>

      </div>
        <div className="flex gap-2 items-center">
          <h2 className="uppercase font-bold text-[16px] md:text-[22px] text-orange-500">XP</h2>
          <h2 className="uppercase font-bold text-[14px] md:text-[20px]">EARNED: {state.daily_count%5===0?(state.daily_count-1)*10+50:state.daily_count*10}</h2>

        </div>

        
      </div>
    </div>
    </motion.div>
  );
};

export default CalenderPop;
