import React from "react";
import MilestonesCard from "./MilestonesCard";
import styles from "../styles/MileStonesList.module.css";
import useOverItem from "../hooks/useOverItem";
const MIlestoneList = () => {
  const customStyles = {
    scrollbarWidth: "thin",
    scrollbarColor: "#D9D9D9",
  };
const {state}=useOverItem()
  return (
    <div
      style={{
        maxWidth: "calc(100vw - 140px)",


        fontFamily: "'Poppins',sans-serif",
      }}
      className="flex flex-col gap-1 px-2 mt-1"
    >
      <div className="text-lg font-bold">Milestones</div>
      <div
        style={customStyles}
        className={`flex gap-x-6 flex-grow-0 items-center overflow-auto max-w-full ${styles.scrollbar_custom} whitespace-nowrap bg-transparent p-4`}
      >
        {
          state.overview?.mileStoneArray?.map(e=>(
            <MilestonesCard key={e._id} item={e}/>
          ))
        }
      
   
      </div>
    </div>
  );
};

export default MIlestoneList;
