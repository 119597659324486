import React, { useContext, useEffect, useState } from "react";
import "./DesignBlog.css";
import { useNewTextGenerate } from "../../hooks/useNewTextGenerate";
import { SyncLoader } from "react-spinners";
import { useQuestResult } from "../../hooks/useQuestResult";
import { UseCopyText } from "../../hooks/useCopyText";
import DesignQuest from "../../layouts/DesignQuest";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { useParams } from "react-router-dom";
const ButtonArray = [
  { name: "Personal" },
  { name: "Interview" },
  { name: "Review" },
  { name: "Travel" },
  { name: "Science" },
  { name: "Facts" },
  { name: "Recipe" },
  { name: "Craft" },
  { name: "Historical" },
];
const DesignBlog = () => {
  const { quest_type } = useParams();
  const [blog, setBlog] = useState("");
  const [desc, setDesc] = useState("");
  const { errorState } = useContext(ErrorContext);
  const { copyText } = UseCopyText();
  const { fetchText, textloading, answer} = useNewTextGenerate();
  const { fetchResult } = useQuestResult();
  const handleGenerate = async () => {
    const textPrompt = `generate a blog for the topic of ${blog} and having a description as ${desc}`;
    let response=await fetchText({ prompt: textPrompt, quest_type });
    if (response) {
      let res = await fetchResult({ answer, quest_type, prompt: textPrompt });
      if (res === "OK") {
        setDesc("");
        setBlog("");
      }
    }
  };
  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
  }, []);
  const modalStyle = {
    background: `#250e2a`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };
  return (
    <DesignQuest modalStyle={modalStyle} title="Create a Blog">
      {errorState.errorModal && <LimitErrorModal />}

      <div className="flex flex-col justify-center items-center gap-2 mt-2">
        <h1
          style={{
            wordBreak: "20px",
          }}
          className={`uppercase text-[30px] text-white`}
        >
          Blogger's Paradise
        </h1>
        <div
          style={{
            background: "#E6BC82",

            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "7px",
          }}
          className="w-[530px] h-[31px] flex justify-start items-center gap-4 px-4"
        >
          <h1
            style={{ wordSpacing: "0.1em" }}
            className="font-[400] text-[16px]"
          >
            Input Description, Select Style & Click on generate for output
          </h1>
        </div>
      </div>

      <div className="flex justify-between gap-4 mt-14 m-auto px-10 w-[94%] h-[60%]">
        {/* left div */}
        <div className="h-full w-[30%] px-2 py-2">
          <div className="flex flex-col gap-4 justify-around items-center">
            <textarea
              name=""
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              style={{ resize: "none", border: "2px solid #784B23" }}
              placeholder="Description..."
              id=""
              className="text-black bg-[#EAC181] rounded-xl w-full h-[20vh] text-md font-bold  outline-none  px-4 py-4"
            ></textarea>
            <div
              style={{
                border: "2px solid #784B23",
              }}
              className="rounded-xl w-full h-[30vh] flex flex-col gap-4 pt-2 bg-[#EAC181]"
            >
              <div className="flex px-4 text-black font-bold text-[14px]">
                <span>Choose a style:</span>
              </div>
              <div className="flex flex-wrap gap-4  justify-center">
                {ButtonArray.map((e, index) => (
                  <button
                    onClick={() => setBlog(e.name)}
                    style={{
                      background:
                        "linear-gradient(124.5deg, #D5DBE4 0%, #F8F8F8 100%)",
                      boxShadow:
                        "0px 1px 2px 1px #1E235A66, 0px 0px 1px 1px #FFFFFF inset, 0px -2px 0px 0px #CDCDE6 inset",
                    }}
                    key={index}
                    className={`${
                      blog === e.name ? "opacity-20" : ""
                    } z-10 w-[28%] uppercase hover:bg-[#7b4a28] text-[#969FAF] text-[12px] font-bold py-2 px-2 rounded-md`}
                  >
                    {e.name}
                  </button>
                ))}
              </div>
            </div>

            <button
              disabled={textloading || !blog || !desc}
              onClick={handleGenerate}
              className="px-4 py-2 rounded-lg text-lg font-bold  uppercase z-50 bg-[#ab6d41]"
            >
              Generate
            </button>
          </div>
        </div>

        {/* right div */}
        <div className="h-full w-[60%] px-2 py-2">
          <div className="relative h-full bg-[#EAC181] rounded-xl">
            {/* {answer && !loading && (
              <button
                disabled={postSent}
                onClick={() => setFeedModal(true)}
                style={{ zIndex: 10000000 }}
                className={`absolute top-5 px-2 py-2 bg-gray-300 bg-opacity-50 text-black rounded-full right-5`}
              >
                <IoShareSocialOutline className="text-black" size={20} />
              </button>
            )} */}

            <div
              style={{ lineHeight: 1.8 }}
              className="overflow-scroll h-full text-black px-6 pb-4"
            >
              {textloading ? (
                <SyncLoader
                  color="#36d7b7"
                  style={{
                    zIndex: 10000,
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                    marginTop: "30px",
                  }}
                />
              ) : (
                ""
              )}
              {answer && !textloading
                ? answer.split("\n\n").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                      <br />
                    </span>
                  ))
                : ""}
            </div>
            <button
              disabled={!answer && textloading}
              onClick={() => copyText(answer)}
              style={{ border: "1px solid black" }}
              className="w-24 absolute bottom-[5%] right-[5%] h-6 uppercase text-black outline-none rounded-md text-sm  shadow-2xl bg-gray-200"
            >
              Copy text
            </button>
          </div>
        </div>
      </div>
    </DesignQuest>
  );
};

export default DesignBlog;
