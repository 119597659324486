export const SET_OVERVIEW = "SET_OVERVIEW";
export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";
export const SET_AI_MODAL = "SET_AI_MODAL";
export const SET_OVERVIEW_XP = "SET_OVERVIEW_XP";
export const SET_DAILY_COUNT = "SET_DAILY_COUNT";
export const SET_STREAK_MESSAGE = "SET_STREAK_MESSAGE";
export const SET_QUEST_MESSAGE = "SET_QUEST_MESSAGE";
export const SET_DAILY_SINGLE_QUEST = "SET_DAILY_SINGLE_QUEST";
export const SET_LONGEST_STREAK = "SET_LONGEST_STREAK";
export const SET_STREAK_DAYS = "SET_STREAK_DAYS";
export const SET_XP_CHECK = "SET_XP_CHECK";
export const SET_VIDEO_MODAL = "SET_VIDEO_MODAL";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_NOTIFICATION_ARRAY = "SET_NOTIFICATION_ARRAY";
