import { useState } from "react";
import { toast } from "react-toastify";
import api from "../api.js";
import { useNavigate } from "react-router-dom";
import moment from "moment";
export const useSignup = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const notify = (error) => toast.error(error);
  const signup = async (username, date_of_birth, email, password, country) => {
    const parsedDate = moment(date_of_birth, "DD-MM-YYYY");
    date_of_birth = parsedDate.toString();
    setLoading(true);
    try {
      const response = await api.post(`/signup`, {
        username,
        email,
        password,
      });
      if (response && response.status === 201) {
        setLoading(false);
        navigate("/");
      }
    } catch (error) {
      notify(error.response.data.error);
      setLoading(false);
    }
  };
  return { signup, loading };
};
