import React, { useState, useEffect, useRef } from 'react';
import { IoPlaySharp, IoPauseSharp, IoReloadSharp } from 'react-icons/io5'; // Importing icons from React Icons
import { MdVolumeUp } from "react-icons/md";
const AudioPlayer = ({ srcs }) => {
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const audioRefs = useRef([]);

    useEffect(() => {
        // Create audio objects for all sources
        audioRefs.current = srcs.map(src => {
            const audio = new Audio(src);
            audio.addEventListener('ended', handleTrackEnd);
            return audio;
        });

        return () => {
            audioRefs.current.forEach(audio => {
                audio.removeEventListener('ended', handleTrackEnd);
            });
        };
    }, [srcs]);

    const handleTrackEnd = () => {
        if (currentTrackIndex + 1 < srcs.length) {
            setCurrentTrackIndex(currentTrackIndex + 1);
        } else {
            setIsPlaying(false);
            setShowControls(false); // Hide controls when all tracks have finished playing
        }
    };

    const togglePlayPause = () => {
        if (!isPlaying) {
            if (audioRefs.current[currentTrackIndex]) {
                audioRefs.current[currentTrackIndex].play();
                setIsPlaying(true);
                setShowControls(true); // Show controls when play is initiated
            }
        } else {
            if (audioRefs.current[currentTrackIndex]) {
                audioRefs.current[currentTrackIndex].pause();
                setIsPlaying(false);
            }
        }
    };

    const restart = () => {
        audioRefs.current.forEach(audio => {
            audio.pause();
            audio.currentTime = 0;
        });
        setCurrentTrackIndex(0);
        setIsPlaying(false);
        setShowControls(false); // Optionally hide controls after restart
    };

    return (
        <div className="absolute top-0 md:top-6 right-6 px-4 items-center">
            {!showControls ? (
                <button onClick={togglePlayPause} className="mx-2 text-gray-200 hover:text-gray-400">
                    <MdVolumeUp size={25} />
                </button>
            ) : (
                <>
                    <button onClick={togglePlayPause} className="mx-2 text-gray-200 hover:text-gray-400">
                        {isPlaying ? <IoPauseSharp size={25} /> : <IoPlaySharp size={25} />}
                    </button>
                    <button onClick={restart} className="mx-2 text-gray-200 hover:text-gray-400">
                        <IoReloadSharp size={25} />
                    </button>
                </>
            )}
        </div>
    );
};

export default AudioPlayer;
