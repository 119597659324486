import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  TelegramIcon,
} from "react-share";

const ShareButton = ({ imageUrl, closeModal, quote }) => {
  const combinedText = `${quote}\n\n url: ${imageUrl}`;

  // const copyToClipboard = () => {
  //   navigator.clipboard
  //     .writeText(imageUrl)
  //     .then(() => {
  //       alert("URL copied to clipboard");
  //     })
  //     .catch(() => {
  //       alert("Failed to copy URL");
  //     });
  // };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 max-w-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
          Share Image or Text
        </h2>
        <div className="flex justify-between mb-4">
          {/* <div className="flex flex-col items-center">
            <FacebookShareButton url={imageUrl} quote={combinedText}>
              <FacebookIcon size={48} round={true} />
            </FacebookShareButton>
            <span className="text-sm mt-2">Facebook</span>
          </div> */}
          <div className="flex flex-col items-center">
            <TwitterShareButton url={imageUrl} title={combinedText}>
              <TwitterIcon size={48} round={true} />
            </TwitterShareButton>
            <span className="text-sm mt-2">Twitter</span>
          </div>
          <div className="flex flex-col items-center">
            <WhatsappShareButton url={imageUrl} title={combinedText}>
              <WhatsappIcon size={48} round={true} />
            </WhatsappShareButton>
            <span className="text-sm mt-2">WhatsApp</span>
          </div>
          <div className="flex flex-col items-center">
            <LinkedinShareButton url={imageUrl} title={combinedText}>
              <LinkedinIcon size={48} round={true} />
            </LinkedinShareButton>
            <span className="text-sm mt-2">LinkedIn</span>
          </div>
          <div className="flex flex-col items-center">
            <TelegramShareButton url={imageUrl} title={combinedText}>
              <TelegramIcon size={48} round={true} />
            </TelegramShareButton>
            <span className="text-sm mt-2">Telegram</span>
          </div>
        </div>
        {/* <button
          className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out mb-2"
          onClick={copyToClipboard}
        >
          Copy URL
        </button> */}
        <button
          className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition duration-300 ease-in-out"
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ShareButton;
