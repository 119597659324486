import React, { useContext, useEffect, useRef, useState } from "react";
import { textcolor } from "../constants/styles";
import styles from "../styles/Aisearch.module.css";
import { TbCircleCheckFilled } from "react-icons/tb";
import { FaMicrophone } from "react-icons/fa";
import RobotImage from "../assets/images/vector.webp";
import { FaPlay, FaStop, FaUpload } from "react-icons/fa";
import { motion } from "framer-motion";

import {
  IoCloseSharp,
  IoMicSharp,
  IoPauseSharp,
  IoPlaySharp,
  IoSearch,
  IoSquare,
  IoStopSharp,
  IoVolumeHighSharp,
} from "react-icons/io5";
import { PiPaperPlaneRightBold } from "react-icons/pi";
import { IoIosSearch } from "react-icons/io";
import useAITextchat from "../hooks/useAITextchat";
import { ClockLoader, FadeLoader, SyncLoader } from "react-spinners";
import profile from "../assets/images/userimg.webp";
import useOverItem from "../hooks/useOverItem";
import { QuestContext } from "../context/questContext/QuestContext";
import { useLocation } from "react-router-dom";
import AudioPlayer from "../components/AudioPlayer";
import useAiAudio from "../hooks/useAiAudio";
import { AiContext } from "../context/aicontext/AiContext";
import { SlClose } from "react-icons/sl";
import PomoderTimer from "../components/mobile/aisearch/PomoderTimer";
import { useUploadAudio } from "../hooks/useUploadAudio";
import RecordRTC, { StereoAudioRecorder } from "recordrtc";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const modalVariants = {
  hidden: { x: "100vh", opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 0.5 } },
  exit: { x: "100vh", opacity: 0, transition: { duration: 0.5 } },
};
const aiAgents = [
  {
    name: "Ai Bot",
    type: "Gpt Master",
    description:
      "This AI uses interactive games, challenges, and real-world applications to make solving math problems fun and engaging. Imagine using math skills to calculate superhero flight distances or laser beam angles!",
  },
  {
    name: "Captain Calculon",
    type: "Math Tutor",
    description:
      "This AI uses interactive games, challenges, and real-world applications to make solving math problems fun and engaging. Imagine using math skills to calculate superhero flight distances or laser beam angles!",
  },
  {
    name: "Professor Lumi",
    type: "Science Tutor",
    description:
      "Professor Lumi guides kids through virtual lab experiments, explains scientific concepts in a clear and engaging way, and even suggests real-world science projects kids can conduct at home.",
  },
  {
    name: "Chronos",
    type: "History Tutor",
    description:
      "Chronos takes kids on immersive journeys through time, allowing them to interact with historical figures and events through simulations and role-playing scenarios.",
  },
  {
    name: "Polyglot Parrot",
    type: "Language Learner",
    description:
      "This AI agent makes learning languages fun and interactive. Polyglot Parrot uses games, songs, and interactive stories to introduce new vocabulary and grammar, all while practicing pronunciation with speech recognition technology.",
  },
  {
    name: "Story Spinner",
    type: "Story Teller",
    description:
      "Story Spinner acts as a creative writing companion. Kids can choose genres, characters, and settings, and Story Spinner helps them craft engaging narratives. It can even suggest creative writing prompts or provide feedback on their stories.",
  },
  {
    name: "Homework Hero",
    type: "Homework Planner",
    description:
      "Homework Hero helps kids stay organized and on top of their schoolwork. It can create personalized schedules, set reminders for deadlines, and even prioritize tasks based on urgency and difficulty.",
  },
  {
    name: "Daily Docket",
    type: "Organize My Day Planner",
    description:
      "Daily Docket helps kids manage their daily activities. It can set reminders for appointments, chores, extracurricular activities, and even schedule breaks for relaxation or hobbies. It can be integrated with other AI agents, suggesting activities based on the day's schedule, like science experiments in the afternoon or quiet reading time before bed.",
  },
];

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

const formatTime = (seconds) => {
  return `${String(15 - seconds).padStart(2, "0")}`;
};
const Aisearch = () => {
  const [recorder, setRecorder] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const recordingIntervalRef = useRef(null);

  const { state } = useOverItem();
  const [randomTopic, setRandomTopic] = useState(null);
  const [openAiProducts, setOpenAiProducts] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);
  const {
    state: { loading, listData,messages },
    postAiTopics,
  } = useContext(AiContext);
  const [aiSearchName, setAiSearchName] = useState(aiAgents[0]);
  const query = useQuery();
  const queryParamValue = query.get("search") || "";
  const [isListening, setIsListening] = useState(false);
  // Initializes the state 'desc' with the query parameter value or an empty string if not present.
  const [desc, setDesc] = useState("");
  const [showRecorder, setShowRecorder] = useState(false); // New state for pop-up

  const { uploadAudio, transcript } = useUploadAudio();
  useEffect(() => {
    if (isRecording && recordingTime >= 15) {
      setIsRecording(false);
      setShowRecorder(false);
      stopRecording(true);
    }
  }, [isRecording, recordingTime]);

  useEffect(() => {
    if (transcript) {
      setAudioLoading(false);
      setShowRecorder(false);
      handleGenerateButton(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    return () => {
      if (isRecording) {
        stopRecording(true);
      }
    };
  }, [isRecording]);

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 16000,
        },
      })
      .then((stream) => {
        const audioRecorder = new RecordRTC(stream, {
          type: "audio",
          mimeType: "audio/wav",
          recorderType: StereoAudioRecorder,
          desiredSampRate: 16000,
        });
        audioRecorder.startRecording();
        setRecorder({ audioRecorder, stream });
        setIsRecording(true);
        setShowRecorder(true);
        setRecordingTime(0);
        recordingIntervalRef.current = setInterval(() => {
          setRecordingTime((prevTime) => prevTime + 1);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error accessing media devices.", error);
      });
  };

  const stopRecording = (cancel = false) => {
    if (recorder && recorder.audioRecorder) {
      recorder.audioRecorder.stopRecording(() => {
        const audioBlob = recorder.audioRecorder.getBlob();
        if (!cancel) {
          uploadAudio(audioBlob);
          setAudioLoading(true);
        }
        setIsRecording(false);
        setRecordingTime(0);
        clearInterval(recordingIntervalRef.current);
        if (recorder.stream) {
          recorder.stream.getTracks().forEach((track) => track.stop());
        }
        setRecorder(null);
      });
    }
  };

  const strRecording = () => {
    startRecording();
    setShowRecorder(true);
  };

  const stpRecording = () => {
    stopRecording();
    setAudioLoading(true);
  };

  const cancleRecording = () => {
    setIsRecording(false);
    setShowRecorder(false);
    stopRecording(true);
  };
  const {
    questState: { questPrompt, AIChatId },
  } = useContext(QuestContext);
  const { aiTextChat, answer, ailoading, aiLoader, setAiLoader } =
    useAITextchat();
  const { audioinbase64, aiaudioData } = useAiAudio();

  const handleGenerateButton = async (text) => {
    text = typeof text === "string" ? text : desc;
    console.log(text);

    if (!text) {
      return;
    }
    const textPrompt = `You are my ${aiSearchName.name} assistant of type ${aiSearchName.type} help me in this ${text}`;
    await aiTextChat({
      prompt: textPrompt,
    });
    setDesc("");
  };

  useEffect(() => {
    let isGet = true;
    if (isGet && queryParamValue) {
      aiTextChat({
        prompt: queryParamValue,
      });
    }

    return () => {
      isGet = false;
    };
  }, [queryParamValue]);

  // useEffect(() => {
  //   // This effect runs once on component mount and sets up speech recognition
  //   if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
  //     const SpeechRecognition =
  //       window.SpeechRecognition || window.webkitSpeechRecognition;
  //     const recognitionInstance = new SpeechRecognition();
  //     recognitionInstance.continuous = true;
  //     recognitionInstance.interimResults = true;

  //     recognitionInstance.onresult = (event) => {
  //       const transcript = Array.from(event.results)
  //         .map((result) => result[0])
  //         .map((result) => result.transcript)
  //         .join("");
  //       setDesc(transcript);
  //     };

  //     recognitionInstance.onend = () => {
  //       if (isListening) {
  //         recognitionInstance.start();
  //       } else {
  //         setIsListening(false);
  //       }
  //     };

  //     setRecognition(recognitionInstance);
  //   } else {
  //     alert("Your browser doesn't support speech recognition.");
  //   }
  // }, []);

  // const startListening = () => {
  //   if (recognition && !isListening) {
  //     recognition.start();
  //     setIsListening(true);
  //   }
  // };

  // const stopListening = async () => {
  //   if (recognition && isListening) {
  //     recognition.stop();
  //     setIsListening(false);
  //     await handleGenerateButton();
  //   }
  // };

  useEffect(() => {
    handleListen();
  }, [isListening]);

  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {
        console.log("continue..");
        mic.start();
      };
    } else {
      mic.stop();
      mic.onend = async () => {
        console.log("Stopped Mic on Click");
        await handleGenerateButton();
      };
    }
    mic.onstart = () => {
      console.log("Mics on");
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      console.log(transcript);
      setDesc(transcript);
      mic.onerror = (event) => {
        console.log(event.error);
      };
    };
  };

  const cancelListening = () => {
    if (mic && isListening) {
      mic.stop();
      setIsListening((prevState) => !prevState);
      setDesc(""); // Clear the transcript if canceled
    }
  };

  useEffect(() => {
    // Cleanup speech synthesis on component unmount
    let isGet = true; // This might need to be managed via state if it should persist between renders

    // Function to call the API
    const callApi = async () => {
      if (isGet && AIChatId && answer && !ailoading) {
        await audioinbase64({
          chatId: AIChatId,
        });

        setAiLoader(false);
      }
    };

    // Setting the timeout
    const intervalId = setTimeout(() => {
      callApi();
    }, 35000); // 30 seconds

    // Cleanup function to clear the timeout if the component unmounts or dependencies change
    return () => {
      clearTimeout(intervalId);
      isGet = false;
    };
  }, [AIChatId]);

  useEffect(() => {
    let isGet = true;
    if (isGet && !listData.length) {
      postAiTopics({
        prompt: `
        Generate a JSON stringified array of at least six objects. Each object should represent a random topic suitable for kids, presented as a small sentence. The format should be as follows:
        
        [
          {
            "topic": "Sample topic 1."
          },
          {
            "topic": "Sample topic 2."
          },
          {
            "topic": "Sample topic 3."
          },
          {
            "topic": "Sample topic 4."
          },
          {
            "topic": "Sample topic 5."
          },
          {
            "topic": "Sample topic 6."
          }
        ]
        
        Replace the sample topics with random topics suitable for kids.
        
        `,
      });
    }

    return () => {
      isGet = false;
    };
  }, []);

  const handleAiTopic = async (id) => {
    setRandomTopic(id);
    const textPrompt = `You are my ${aiSearchName.name} assistant of type ${aiSearchName.type} help me in this ${id}`;
    await aiTextChat({
      prompt: textPrompt,
    });
  };

  const [displayedAnswer, setDisplayedAnswer] = useState("");
  const [index, setIndex] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (answer && !ailoading) {
      setDisplayedAnswer("");
      setIndex(0);
    }
  }, [answer, ailoading]);

  useEffect(() => {
    if (index < answer.length) {
      const timeoutId = setTimeout(
        () => {
          setDisplayedAnswer((prev) => prev + answer[index]);
          setIndex((prev) => prev + 1);
        },
        index === 0 ? 0 : 5
      ); // Initial delay is 0, then 50ms for each subsequent character
      return () => clearTimeout(timeoutId);
    }
  }, [index, answer]);

  useEffect(() => {
    if (containerRef.current === null) return;
    containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
  }, [displayedAnswer]);
  return (
    <div
      style={{
        fontFamily: "'Poppins',sans-serif",
      }}
      className="flex gap-4  justify-center w-full h-full"
    >
      {/* frist div */}
      <div
        className={`w-[25%] min-w-[220px] md:max-h-[900px] lg:w-[15%] rounded-xl  hidden md:flex flex-col gap-4`}
      >
        <div
          style={{
            background: `linear-gradient(to bottom right, #373160 20%, #1c1b25 100%)`,
            scrollBehavior: "smooth",
          }}
          className={`w-full ${styles.custom_scrollbar} h-full xl:max-h-[320px] xl:h-[43%]  overflow-auto rounded-xl py-4 flex flex-col gap-1`}
        >
          <div className="text-[14px] px-4">Character</div>
          <div className="text-[12px] px-4 text-gray-400">
            Choose the character for your AI
          </div>

          <div className="flex flex-col gap-4 mt-2 w-full">
            {aiAgents.map((e) => (
              <button
                key={e.name}
                onClick={() => setAiSearchName(e)}
                className={`relative flex gap-2 hover:bg-black hover:bg-opacity-30 px-2 py-2 rounded-lg  w-full`}
              >
                <div
                  className={`rounded-full w-6 h-6 text-md bg-[${textcolor}] font-bold flex items-center justify-center text-black`}
                >
                  <span>{e.name[0]}</span>
                </div>
                <div className="flex flex-col gap-1 items-start">
                  <div className="text-gray-400 text-[14px]">{e.name}</div>
                  <small className="text-[10px] text-gray-400">{e.type}</small>
                </div>
                {e === aiSearchName && (
                  <TbCircleCheckFilled
                    className="absolute right-0 top-4 text-green-300"
                    size={20}
                  />
                )}
              </button>
            ))}
          </div>
        </div>

        <div
          style={{
            background: `linear-gradient(to bottom right, #373160 5%, #1c1b25 80%)`,
          }}
          className="w-full flex xl:hidden max-h-[320px] h-[43%] rounded-xl flex-col px-2 py-4 gap-1"
        >
          <div className="text-[14px] px-2">Popular Topics</div>
          <div className="flex items-center justify-center">
            {loading && <FadeLoader color="#36d7b7" />}
          </div>

          <div
            style={{ scrollBehavior: "smooth" }}
            className={`flex flex-col px-2 ${styles.custom_scrollbar} overflow-y-scroll py-2 gap-y-6 mt-2`}
          >
            {!loading &&
              listData?.map((e) => (
                <div
                  onClick={() => handleAiTopic(e.topic)}
                  key={e.topic}
                  className="relative flex gap-2 pr-4 items-start cursor-pointer "
                >
                  <button className="flex items-center justify-center">
                    <IoSearch size={18} className="text-gray-400" />
                  </button>
                  <div className="w-full  h-full fex justify-center text-[14px] text-gray-300 items-center">
                    {e.topic}
                  </div>
                  {e.topic === randomTopic && (
                    <TbCircleCheckFilled
                      className="absolute right-[-2%] top-1 text-green-300"
                      size={20}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* second div */}

      <div
        style={{
          background: `linear-gradient(to bottom right, #373160 5%, #1c1b25 80%)`,
        }}
        className="relative  w-full md:w-[75%] lg:w-[75%] md:max-h-[900px] h-full md:rounded-xl flex flex-col justify-center items-center"
      >
        <div

          className={`w-full   h-[100%] overflow-auto flex flex-col  items-center`}
        >
          <div ref={containerRef} className={`w-full ${styles.custom_scrollbar} h-[80%] overflow-auto  flex flex-col  items-center `}>
            {openAiProducts && (
              <div
                className={`absolute w-full h-full top-0 left-0 bg-black justify-center flex px-4 py-4 md:hidden z-20`}
                style={{ fontFamily: "'Inter',sans-serif" }}
              >
                <button
                  className="text-white absolute top-[5%] right-[10%] cursor-pointer"
                  onClick={() => setOpenAiProducts(false)}
                >
                  <SlClose size={30} />
                </button>
                <div
                  style={{
                    background: `linear-gradient(to bottom right, #373160 20%, #1c1b25 100%)`,
                    scrollBehavior: "smooth",
                  }}
                  className={`w-full ${styles.custom_scrollbar} h-[70%] overflow-auto rounded-xl py-8 flex flex-col gap-1`}
                >
                  <div className="text-[14px] px-4">Character</div>
                  <div className="text-[12px] px-4 text-gray-400">
                    Choose the character for your AI
                  </div>

                  <div className="flex flex-col gap-4 py-4 mt-2 w-full">
                    {aiAgents.map((e) => (
                      <button
                        key={e.name}
                        onClick={() => {
                          setAiSearchName(e);
                          setOpenAiProducts(false);
                        }}
                        className={`relative flex gap-2 hover:bg-black hover:bg-opacity-30 px-2 py-2 rounded-lg  w-full`}
                      >
                        <div
                          className={`rounded-full w-6 h-6 text-md bg-[${textcolor}] font-bold flex items-center justify-center text-black`}
                        >
                          <span>{e.name[0]}</span>
                        </div>
                        <div className="flex flex-col gap-1 items-start">
                          <div className="text-gray-400 text-[14px]">
                            {e.name}
                          </div>
                          <small className="text-[10px] text-gray-400">
                            {e.type}
                          </small>
                        </div>
                        {e === aiSearchName && (
                          <TbCircleCheckFilled
                            className="absolute right-0 top-4 text-green-300"
                            size={20}
                          />
                        )}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {!ailoading&& messages?.slice(0, messages?.length - 1)?.map((e) => (
              <>
                <div className="px-4 py-6 h-[90%] md:h-[80%]  w-full flex flex-col gap-2">
                  <div className="flex gap-4">
                    <img
                      src={
                        state.overview?.profileimg
                          ? state.overview?.profileimg
                          : profile
                      }
                      className="w-8 h-8 rounded-full"
                      alt=""
                    />

                    <div className="flex flex-col justify-center gap-2 mt-1">
                      <div className="">
                        <h1 className="text-white text-[14px] font-bold">
                          You
                        </h1>
                      </div>
                      <div
                        className={`text-gray-400 text-[12px] w-[90%] font-bold`}
                      >
                        {e.questPrompt}
                      </div>
                    </div>
                  </div>
                  <div className="flex  flex-col overflow-hidden flex-1 pb-2 ">
                    {/* <div className="flex items-center gap-2  rounded-full hover:bg-black hover:bg-opacity-30 py-2 px-2">
                  <div
                    className={`rounded-full w-6 h-6 text-md bg-[${textcolor}] font-bold flex items-center justify-center text-black`}
                  >
                    <span>{aiSearchName.name[0]}</span>
                  </div>
                  <div className="text-gray-400  text-[14px]">
                    {e.aisearchName}
                  </div>
                </div> */}

                    <div
                      className={`text-gray-200 ${styles.custom_scrollbar} overflow-y-scroll px-2 pb-2 md:px-6 mt-2 md:pb-4 text-[14px] font-bold`}
                    >
                      {e.displayedAnswer
                        .split("\n\n")
                        .slice(1)
                        .map((line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                            <br />
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            ))}

            {ailoading && (
              <SyncLoader
                color="#36d7b7"
                style={{
                  zIndex: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                  marginTop: "30px",
                }}
              />
            )}
            {answer && !ailoading && (
              <>
                <div className="px-4 py-6 h-[90%] md:h-[80%] w-full flex flex-col gap-2">
                  <div className="absolute top-6 right-6 px-4 items-center">
                    {aiLoader && (
                      <div className="absolute w-[200px] justify-center top-0 right-2 px-4 items-center flex gap-2 bg-black rounded-xl bg-opacity-30 py-2">
                        <ClockLoader
                          color="hsla(47, 16%, 77%, 1)"
                          cssOverride={{}}
                          size={15}
                          speedMultiplier={5}
                        />
                        <div className="text-[12px]">generating audio...</div>
                      </div>
                    )}
                    {!aiLoader && answer && (
                      <AudioPlayer srcs={aiaudioData?.audioContents} />
                    )}
                  </div>
                  <div className="flex gap-4">
                    <img
                      src={
                        state.overview?.profileimg
                          ? state.overview?.profileimg
                          : profile
                      }
                      className="w-8 h-8 rounded-full"
                      alt=""
                    />

                    <div className="flex flex-col justify-center gap-2 mt-1">
                      <div className="">
                        <h1 className="text-white text-[14px] font-bold">
                          You
                        </h1>
                      </div>
                      <div
                        className={`text-gray-400 text-[12px] w-[90%] font-bold`}
                      >
                        {questPrompt}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col  flex-1 pb-2 ">
                    <div className="flex items-center gap-2  rounded-full  py-2 px-2">
                      <div
                        className={`rounded-full w-6 h-6 text-md bg-[${textcolor}] font-bold flex items-center justify-center text-black`}
                      >
                        <span>{aiSearchName.name[0]}</span>
                      </div>
                      <div className="text-gray-400  text-[14px]">
                        {aiSearchName.name}
                      </div>
                    </div>

                    <div
                      className={`text-gray-200 h-full  px-2 pb-2 md:px-6 mt-2 md:pb-4 text-[14px] font-bold`}
                    >
                      {displayedAnswer
                        .split("\n\n")
                        .slice(1)
                        .map((line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                            <br />
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            )}
            {!answer && !ailoading && (
              <>
                <div className="flex flex-col gap-2 justify-center mt-auto items-center">
                  <div className="w-24 h-24">
                    <img
                      src={RobotImage}
                      className="w-full h-full"
                      alt="robotImage"
                    />
                  </div>
                  <div className="text-[#57546E] text-[25px]">
                    Ask AI anything
                  </div>
                </div>
                <div className="flex w-full px-4 py-4 flex-wrap items-center justify-center gap-4 mt-4">
                  <div
                    style={{
                      background: `linear-gradient(to bottom right, #373160 0%, #2d2a3d 80%)`,
                    }}
                    className="rounded-2xl w-full lg:w-[40%] h-[56px] text-[14px] text-gray-400 px-4 py-2"
                  >
                    Idea suggestions for a youtube script with Elon Musk and
                    superman
                  </div>
                  <div
                    style={{
                      background: `linear-gradient(to bottom right, #373160 0%, #2d2a3d 80%)`,
                    }}
                    className="rounded-2xl w-full lg:w-[40%] h-[56px] text-[14px] text-gray-400 px-4 py-2"
                  >
                    Idea suggestions of designing a super hero character
                  </div>
                  <div
                    style={{
                      background: `linear-gradient(to bottom right, #373160 0%, #2d2a3d 80%)`,
                    }}
                    className="hidden lg:flex rounded-2xl w-full lg:w-[40%] h-[56px] text-[14px] text-gray-400 px-4 py-2"
                  >
                    Idea suggestions of designing a super hero character
                  </div>
                  <div
                    style={{
                      background: `linear-gradient(to bottom right, #373160 0%, #2d2a3d 80%)`,
                    }}
                    className="hidden lg:flex rounded-2xl w-full lg:w-[40%] h-[56px] text-[14px] text-gray-400 px-4 py-2"
                  >
                    Idea suggestions of designing a super hero character
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            border: "1px solid #FFFFFF2E",

            background: `linear-gradient(to bottom right, #373160 5%, #1c1b25 80%)`,
          }}
          className="absolute bottom-8 right-1/2 translate-x-1/2 rounded-2xl flex justify-between px-4 items-center h-[60px] w-[90%]"
        >
          <div className="flex items-center gap-2 w-[95%]">
            {audioLoading ? (
              <div className="w-full px-2 flex items-center justify-center text-[12px] font-bold">
                <h1>processing...</h1>
              </div>
            ) : (
              <div className="bg-transparent flex items-center md:hidden gap-2">
                <div className="flex items-center gap-4">
                  {isRecording ? (
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={modalVariants}
                      className="bg-transparent w-[80vw] flex gap-8 items-center justify-end"
                    >
                      <div className="flex items-center gap-2">
                        <div style={{ fontSize: "14px" }}>
                          {formatTime(recordingTime)}
                        </div>
                        <PomoderTimer percentage={(recordingTime / 15) * 100} />
                        <button
                          onClick={cancleRecording}
                          className="flex items-center justify-center"
                        >
                          <IoCloseSharp size={25} className="text-gray-500" />
                        </button>
                      </div>

                      <button onClick={stpRecording} className="text-gray-500">
                        <PiPaperPlaneRightBold
                          size={25}
                          className="text-gray-500"
                        />
                      </button>
                    </motion.div>
                  ) : showRecorder ? (
                    <div className="w-full flex items-center justify-center">
                      <span>checking mic...</span>
                    </div>
                  ) : (
                    <button
                      onClick={strRecording}
                      disabled={isRecording}
                      className="flex md:hidden items-center justify-center"
                    >
                      <IoMicSharp size={25} className="text-gray-500" />
                    </button>
                  )}
                </div>
              </div>
            )}

            {isListening ? (
              <>
                <button
                  onClick={() => setIsListening((prevState) => !prevState)}
                  className="hidden md:flex items-center justify-center"
                >
                  <IoSquare size={25} className="text-gray-500" />
                </button>
                <button
                  onClick={cancelListening}
                  className="hidden md:flex items-center justify-center"
                >
                  <IoCloseSharp size={25} className="text-gray-500" />
                </button>
              </>
            ) : (
              <button
                disabled={ailoading || aiLoader}
                onClick={() => setIsListening((prevState) => !prevState)}
                className="hidden md:flex items-center justify-center"
              >
                <IoMicSharp size={25} className="text-gray-500" />
              </button>
            )}
            {!showRecorder && (
              <div className="w-full h-full flex justify-center items-center">
                <input
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  placeholder="Type your message here..."
                  type="text"
                  className="w-full text-md h-full px-4 placeholder-gray-400 py-2 bg-transparent outline-none border-none"
                />
              </div>
            )}
          </div>
          {!showRecorder && (
            <div className="flex items-center gap-2">
              <div
                onClick={() => setOpenAiProducts(true)}
                className={`rounded-full w-6 h-6 text-md bg-[${textcolor}] font-bold flex items-center justify-center text-black`}
              >
                <span>{aiSearchName.name[0]}</span>
              </div>
              <button
                disabled={ailoading}
                onClick={handleGenerateButton}
                className="flex items-center justify-center"
              >
                <PiPaperPlaneRightBold size={25} className="text-gray-500" />
              </button>
            </div>
          )}
        </div>
      </div>

      {/* third div */}
      <div
        style={{
          background: `linear-gradient(to bottom right, #373160 5%, #1c1b25 80%)`,
        }}
        className="w-[20%] hidden xl:flex h-[50%]  rounded-xl flex-col px-2 py-4 gap-1"
      >
        <div className="text-[14px] px-2">Popular Topics</div>
        <div className="flex items-center justify-center">
          {loading && <FadeLoader color="#36d7b7" />}
        </div>

        <div
          style={{ scrollBehavior: "smooth" }}
          className={`flex flex-col px-2 ${styles.custom_scrollbar} overflow-y-scroll py-2 gap-y-6 mt-2`}
        >
          {!loading &&
            listData?.map((e) => (
              <div
                onClick={() => handleAiTopic(e.topic)}
                key={e.topic}
                className="relative flex gap-2 pr-4 items-start cursor-pointer "
              >
                <button className="flex items-center justify-center">
                  <IoSearch size={18} className="text-gray-400" />
                </button>
                <div className="w-full  h-full fex justify-center text-[14px] text-gray-300 items-center">
                  {e.topic}
                </div>
                {e.topic === randomTopic && (
                  <TbCircleCheckFilled
                    className="absolute right-[-2%] top-1 text-green-300"
                    size={20}
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Aisearch;
