import React from 'react'
import TrendingCard from './TrendingCard'

const TrendingList = ({title,subTitle}) => {
  return (
    <div className='flex flex-col w-full gap-2 mt-4'>

            <div className='text-md px-4'>{title}</div>
            <div className='w-full text-gray-500 px-4'>{subTitle}</div>
            <div className='flex items-center gap-2 overflow-x-auto h-full'>

            <TrendingCard/>
            {/* <TrendingCard/>
            <TrendingCard/> */}
            </div>
      

        </div>
  )
}

export default TrendingList