import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import api from "../api.js";
import { SET_XP_CHECK } from "../context/overviewcontext/action.js";
import useOverItem from "./useOverItem.js";
export const useQuestResult = () => {
  const { currentUser } = useContext(AuthContext);
  const {dispatch:overViewDispatch,state,notificationPop}=useOverItem()

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser.token}`,
  };
  const fetchResult = async ({ quest_type, answer,prompt }) => {
    try {
      let res=await api.post(
        `/quest/result`,
        {
          answer,
          quest_type: quest_type,
          prompt
        },
        { headers }
      );
      if(!state?.overview?.questCompletedArray?.some(e=>e.quest.quest_type===quest_type)){
        notificationPop({
          status:"badge"
        })

      }
      overViewDispatch({type:SET_XP_CHECK})
      return res.data.message
    } catch (error) {
      console.log("Something went wrong");
    }
  };
  return { fetchResult };
};
