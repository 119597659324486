import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { SyncLoader } from "react-spinners";
import { AuthContext } from "../../context/AuthContext";
import { TbPlayerTrackNext, TbPlayerTrackPrev } from "react-icons/tb";
import api from "../../api";
import useOverItem from "../../hooks/useOverItem";
import { AiOutlineDownload } from "react-icons/ai";
import { useDownload } from "../../hooks/useDownload";
import DesignQuest from "../../layouts/DesignQuest";
import { toast } from "react-toastify";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import {
  SET_ERROR_MESSAGE,
  SET_ERROR_MODAL,
} from "../../context/errorcontext/action";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import { SET_XP_CHECK } from "../../context/overviewcontext/action";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import ShareCompoenents from "../screencomponent/ShareCompoenents";
import { QuestContext } from "../../context/questContext/QuestContext";
import { SET_QUEST_PROMPT } from "../../context/questContext/action";
import { IoShareSocialOutline } from "react-icons/io5";
import { useFeed } from "../../hooks/useFeed";
import { textcolor } from "../../constants/styles";

const buttonsArray=[
  {
    id:1,
    name:"Male",
    descripton:""
  },
  {
    id:2,
    name:"Female",
    descripton:""
  },
  {
    id:3,
    name:"Ultra",
    descripton:""
  },
  {
    id:4,
    name:"3D",
    descripton:""
  },
  {
    id:5,
    name:"Extra",
    descripton:""
  },

]
const DesignCharacter = () => {
  const { quest_type } = useParams();
  const { currentUser } = useContext(AuthContext);
  const [feedModal, setFeedModal] = useState(false);
  const { postFeed, postSent } = useFeed();

  const { questDispatch } = useContext(QuestContext);
  const { dispatch: overViewDispatch, notificationPop, state } = useOverItem();
  const {
    questState: { questPrompt },
  } = useContext(QuestContext);
  const { errorState, dispatch } = useContext(ErrorContext);

  const { downloadFunction, downloading } = useDownload();
  const [index, setIndex] = useState(0);

  const [desc, setDesc] = useState("");
  const [button, setButton] = useState([]);

  const handleButton = (butt) => {
    if (button.includes(butt)) {
      setButton(button.filter((e) => e !== butt));
    } else {
      setButton([...button, butt]);
    }
  };
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState({});
  const handleGenerateButton = async () => {
    setLoading(true);

    try {
      const prompt = `create a character of ${button.join(
        " "
      )} and  having description as ${desc} `;
      questDispatch({ type: SET_QUEST_PROMPT, payload: prompt });
      let res = await api.post(
        "/quest/imagegenaration",
        {
          prompt: prompt,
          quest_type,
          num: 4,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${currentUser.token}`,
          },
        }
      );

      setImg(res.data.imageArray.generations_by_pk);

      if (
        !state?.overview?.questCompletedArray?.some(
          (e) => e.quest.quest_type === quest_type
        )
      ) {
        notificationPop({
          status: "badge",
        });
      }

      overViewDispatch({ type: SET_XP_CHECK });
    } catch (err) {
      if (err.response.status === 400) {
        dispatch({ type: SET_ERROR_MODAL, payload: true });
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: err.response.data.message,
        });
      } else {
        toast.error(err.response.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        console.log(err);
      }
    }

    setLoading(false);
    setDesc("");
    setButton([]);
  };
  const handleChangeImage = (item) => {
    if (item === "prev") {
      setIndex(index - 1);
    } else {
      setIndex(index + 1);
    }
  };

  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
  }, []);
  const modalStyle = {
    background: "#15112CF2",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat", // corrected here
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };
  const handleFeed = async () => {
    setFeedModal(true);
  };
  return (
    <DesignQuest modalStyle={modalStyle} title="Design Your Character">
      {errorState.errorModal && <LimitErrorModal />}
      {feedModal && (
        <ShareCompoenents
          feedModal={feedModal}
          setFeedModal={setFeedModal}
          body={{
            title: quest_type,
            image: img?.generated_images?.[index]?.url,
            userPrompt: questPrompt,
          }}
          postFeed={postFeed}
          postSent={postSent}
        />
      )}

      <div
        style={{ zIndex: 10000 }}
        className="flex  justify-evenly  px-2 py-2 items-center h-full"
      >
        <div className="relative flex flex-col gap-6 text-white mt-[-4%] py-4 px-4 rounded-xl flex-shrink w-[30%]  h-[72vh]">
          <div className="flex flex-col gap-2">
            <h1 className="text-[25px]">Desing Your Character</h1>
            <h1 className="text-[12px]">Step 1. Describe the character</h1>
            <h1 className="text-[12px] text-gray-400 mt-2">Begin by describing your favourite character. You can go as detailed as possible and this can ideally be your favourite super hero.</h1>

          </div>
          <textarea
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            placeholder="Enter you description..."
            style={{ resize: "none",border: "2px solid gray"}}
            className="outline-none text-white text-sm w-[100%] min-h-[150px] max-h-[200px] h-[20vh] bg-transparent  rounded-xl px-4 py-4 overflow-scroll"
          ></textarea>
 <div className="flex flex-col gap-2 mt-2">
            <h1 className="text-[12px]">Step 2. Choose trails</h1>
            <h1 className="text-[12px] text-gray-400 mt-2">Choose the traits you’d like your character to have. You can choose as many as you like.</h1>

          </div>
          <div className="flex flex-wrap gap-4 w-full">
            {
              buttonsArray?.map(e=>(
                <button
                style={{border: `${button.includes(e.name)?`1px solid ${textcolor}`:"1px solid gray"}`}}
                onClick={() => handleButton(e.name)}
                className={`${
                  button.includes(e.name) ? `opacity-20` : ""
                } cursor-pointer px-2 py-2 flex flex-col items-center rounded-xl`}
                >
                {e.name}
                </button>
              ))
            }
            </div>
          <div className="flex gap-4 mt-4">
            <button
              disabled={!desc || !button || loading}
              onClick={handleGenerateButton}
              className={`outline-none bg-[${textcolor}] text-black px-4 py-2 uppercase rounded-lg w-[70%]`}
            >
              Generate
            </button>
            {img?.generated_images && !loading && (
              <div className="flex items-center gap-2">
                <button
                  disabled={index === 0}
                  onClick={() => handleChangeImage("prev")}
                  className={`${
                    index === 0 ? "opacity-20" : ""
                  } font-blod px-2 py-2 bg-gray-300 rounded-full text-black`}
                >
                  {" "}
                  <TbPlayerTrackPrev />{" "}
                </button>
                <button
                  disabled={index === img?.generated_images?.length - 1}
                  onClick={() => handleChangeImage("next")}
                  className={`${
                    index === img?.generated_images?.length - 1
                      ? "opacity-20"
                      : ""
                  } font-blod px-2 py-2 bg-gray-300 rounded-full text-black`}
                >
                  <TbPlayerTrackNext />{" "}
                </button>
              </div>
            )}
          </div>
        </div>
        <LazyLoadComponent>
          <div
          style={{border:"1px solid gray"}}
         
            className="relative bg-transparent rounded-xl w-[512px] h-[512px] overflow-hidden"
        
          >
            {loading ? (
              <SyncLoader
                color="#36d7b7"
                style={{
                  zIndex: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                  marginTop: "30px",
                }}
              />
            ) : (
              !img?.generated_images && (
                <div className="flex justify-center items-center h-full"><span className="text-gray-300">*Generate your favourite character*</span></div>
              )
            )}
            {img?.generated_images && !loading && (
              <img
                src={img?.generated_images?.[index]?.url}
                className="w-full h-full hover:scale-[1.2] object-cover transition-transform duration-300 ease-in-out"
                alt=""
                loading="lazy"
              />
            )}
            <button
              disabled={downloading}
              onClick={() =>
                downloadFunction({
                  imgUrl: img?.generated_images?.[index]?.url,
                })
              }
              style={{ zIndex: 10000000 }}
              className="absolute bottom-5 px-2 py-2 bg-gray-300 rounded-full right-5"
            >
              {!downloading ? (
                <AiOutlineDownload className="text-black" size={20} />
              ) : (
                <span className="text-black font-bold text-lg">...</span>
              )}
            </button>
            {img?.generated_images && !loading && (
              <button
                disabled={postSent}
                onClick={handleFeed}
                style={{ zIndex: 10000000 }}
                className={`absolute top-5 px-2 py-2 bg-gray-300 bg-opacity-50 text-black rounded-full right-5`}
              >
                <IoShareSocialOutline className="text-black" size={20} />
              </button>
            )}
          </div>
        </LazyLoadComponent>
      </div>
    </DesignQuest>
  );
};

export default DesignCharacter;
