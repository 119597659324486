import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import api from "../api.js";
import { toast } from "react-toastify";
import { ErrorContext } from "../context/errorcontext/ErrorContext.js";
import {
  SET_ERROR_MESSAGE,
  SET_ERROR_MODAL,
} from "../context/errorcontext/action.js";
import useOverItem from "./useOverItem.js";
import { SET_XP_CHECK } from "../context/overviewcontext/action.js";
export const useNewTextGenerate = () => {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { dispatch } = useContext(ErrorContext);
  const {dispatch:overViewDispatch}=useOverItem()
  const [status, setStatus] = useState(false);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser.token}`,
  };
  const fetchText = async ({ quest_type, prompt }) => {
    setLoading(true);
    try {
      const response = await api.post(
        `/quest/chat`,
        {
          prompt: prompt,
          quest_type: quest_type,
        },
        { headers }
      );
      if (response.status === 400) {
        setLoading(false);
        toast.error(response.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        return;
      }
      if (response.status === 200) {
        setAnswer(response.data.answer);
        setLoading(false);
        setStatus(true);

        return response.data.answer;

      }
    } catch (err) {
      setLoading(false);
      if (err.response.status === 400) {
        dispatch({ type: SET_ERROR_MODAL, payload: true });
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: err.response.data.message,
        });
      } else {
        toast.error(err.response.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        console.log(err);
      }
    }
  };
  return {
    fetchText,
    textloading: loading,
    answer,
    lognstatus: status,
    errorMessage,
  };
};
