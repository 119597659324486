import React from 'react'
import authbg from '../assets/images/authbg.webp'
const Auth = ({ children }) => {
    return (
        <div className='h-screen w-full flex justify-center items-center bg-no-repeat bg-cover' style={{ backgroundImage: `url(${authbg})` }}>
            {children}
        </div>
    )
}

export default Auth