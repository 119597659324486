import React from "react";
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import useOverItem from "../../hooks/useOverItem";

const MobileError = () => {
  const {state} = useOverItem();
    const navigate=useNavigate()
  const handleAIDemoClick = () => {
    navigate("/aisearch")

  };
  return (
    <>
    {state.loading ? (
        <div
          style={{
            zIndex: 100000000,
            background: `linear-gradient(to bottom right, #292157 0%, #000000 100%)`,
          }}
          className="fixed top-0 left-0 w-full h-full bg-black flex  items-center justify-center modal"
        >
          <SyncLoader
            color="#36d7b7"
            style={{
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              marginTop: "30px",
            }}
          />
        </div>
      ) : (
    <div
      style={{ zIndex: 10000 }}
      className="w-full h-full bg-transparent flex md:hidden  items-center justify-center modal"
    >
      <div className="text-black bg-white p-6 flex flex-col justify-center items-center rounded-lg shadow-lg w-72 h-auto">
        <p className="text-center mb-4 text-lg font-bold">
          Please login via Tab/Laptop/Desktop for exclusive features
        </p>
        <button
          onClick={handleAIDemoClick}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 text-lg font-bold"
          >
          Try AI Search
        </button>
      </div>
    </div>
    )}
          </>
  );
};

export default MobileError;
