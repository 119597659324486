import { useContext, useState } from "react"
import api from "../api";
import { AuthContext } from "../context/AuthContext";

export const useAnalytics=()=>{
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([])
    const { currentUser } = useContext(AuthContext)
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentUser.token}`,
    };
    const analyticsData=async()=>{
        setLoading(true)
        try{
        const res=await api.get(`/users/analytics`, { headers })
        if(res.status===200){
            setData(res.data.data)

        }
        }
        catch(err){
            console.log(err)
        }
        setLoading(false)

    }
    return {data,loading,analyticsData}
}