import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import api from "../api.js";
import { toast } from "react-toastify";
import {
  SET_ERROR_MESSAGE,
  SET_ERROR_MODAL,
} from "../context/errorcontext/action.js";
import { ErrorContext } from "../context/errorcontext/ErrorContext.js";
import useOverItem from "./useOverItem.js";
import { SET_XP_CHECK } from "../context/overviewcontext/action.js";
export const useNewGenerateImage = () => {
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [images, setImages] = useState({});
  const { dispatch } = useContext(ErrorContext);
  const [reattempt, setReattempt] = useState(0);
  const { dispatch: overViewDispatch,state,notificationPop} = useOverItem();
  const headers = {
    Authorization: `Bearer ${currentUser.token}`,
  };

  const wait = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const fetchImage = async ({
    quest_type,
    width,
    height,
    num,
    prompt,
    modelId,
    upload,
    imageId = "",
    uploadId="",
  }) => {
    setLoading(true);

    const formData = new FormData();

    const config = {
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data", // This is necessary for file uploads
      },
    };

    try {
      if (upload) {
        formData.append("file", upload);
        formData.append("height", height);
        formData.append("width", width);
        formData.append("num", num);
        formData.append("prompt", prompt);
        let response = await api.post(
          "/quest/imagegenaration/upload",
          formData,
          config
        );
        if (response.status === 200) {
          imageId = response.data.imageId;
          uploadId = response.data.uploadId;
        }
      }

      let res = await api.post(
        "/quest/imagegenaration",
        {
          prompt,
          quest_type,
          width,
          height,
          num,
          imageId,
          modelId,
          uploadId
        },
        { headers }
      );

      if (res.status === 400) {
        setLoading(false);
        toast.error(res.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        return;
      }
      setImages(res.data.imageArray.generations_by_pk);
      setStatus(true);
      if (quest_type !== "Create_Blog") {
        if(!state?.overview?.questCompletedArray?.some(e=>e.quest.quest_type===quest_type)){
          notificationPop({
            status:"badge"
          })
  
        }
        overViewDispatch({ type: SET_XP_CHECK });
      }
    } catch (err) {
      if (err.response.status === 400) {
        dispatch({ type: SET_ERROR_MODAL, payload: true });
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: err.response.data.message,
        });
      } else {
        toast.error(err.response.data.message || "Your free plan has expired", {
          position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
          autoClose: 500, // Auto-close the toast after 3000 milliseconds (3 seconds)
          hideProgressBar: true, // Hide the progress bar
        });
        console.log(err);
        setStatus(false);
      }
    }
    setLoading(false);
  };
  return {
    fetchImage,
    imgloading: loading,
    images,
    imgStatus: status,
    errorMessage,
  };
};
