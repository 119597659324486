import React, { useContext, useEffect, useState } from "react";
import GeneratedCode from "./GeneratedCode";
import AIFreeHistory from "./AIFreeHistory";
import useFreeStyleHistory from "../../hooks/useFreeStyleHistory";
import useFreeStyletext from "../../hooks/useFreeStyletext";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { useParams } from "react-router-dom";
import { useFeed } from "../../hooks/useFeed";
import ShareCompoenents from "../screencomponent/ShareCompoenents";
import { QuestContext } from "../../context/questContext/QuestContext";
import { SET_QUEST_PROMPT } from "../../context/questContext/action";
import { IoShareSocialOutline } from "react-icons/io5";

const buttonData = [
  {
    name: "Minimalistic",
    value:
      "A clean, uncluttered look with a focus on simplicity and functionality.",
  },
  {
    name: "Colorful",
    value:
      "Vibrant and eye-catching, utilizing a broad and bold color palette.",
  },
  {
    name: "Aesthetic",
    value:
      "A focus on beauty and visual appeal, often with artistic and unique elements.",
  },
  {
    name: "Futuristic",
    value:
      "A forward-looking design with metallic colors, dynamic shapes, and innovative elements.",
  },
  {
    name: "Modern",
    value:
      "Contemporary design with sleek lines, neutral color palettes, and a focus on usability.",
  },
  {
    name: "Dark Mode",
    value:
      "A dark color scheme that's easy on the eyes, especially in low-light conditions.",
  },
];
const TextGenerator = ({
  setImage,
  freeStyleCodeText, 
  textData, 
  textLoad,
  imgDimensions,
  errorMessage,
  handleOpenModal,
  setFeedModal
}) => {

  const [historyModal, setHistoryModal] = useState(false);

  const {
    questDispatch,
  } = useContext(QuestContext);
  const { postFeed, postSent } = useFeed();
  const { quest_type } = useParams();
  const [desc, setDesc] = useState("");
  const [codeModal, setCodeModal] = useState(false);
  
  const [button, setButton] = useState([]);
  const handleButton = (butt) => {
    if (button.includes(butt)) {
      setButton(button.filter((e) => e !== butt));
    } else {
      setButton([...button, butt]);
    }
  };
  const handleGenerate = async () => {
    // 'Create an image for a home page of a pizza website, the design should have a clean, uncluttered look, focusing on its simplicity and functionality. It should be vibrant, eye-catching, utilizing a bold and expansive color palette. Emphasize the beauty and visual appeal with dedication to artistic and unique elements. The centerpiece of the website should be a delicious looking, small-sized pizza with a thin crust, topped with pepperoni and sausage.'
    const prompt = `Design a clean and modern user interface for a ${desc}, displayed as if on a smartphone screen of dimension ${imgDimensions.width} * ${imgDimensions.height}. The interface should match ${button.join(",")} Exclude the physical phone boundaries for a focus on the interface itself.Put everything inside html tag and continue till the end of code.`;
    questDispatch({type:SET_QUEST_PROMPT,payload:prompt})
   await freeStyleCodeText({
      prompt: prompt,
      quest_type
    });

    setButton([]);
    setDesc("");
  };
  const buttonStyles =
    "bg-[#1c0b2b] text-white w-32 h-12 rounded-full shadow hover:bg-purple-600";
  const handleGeneratedCode = () => {
    setCodeModal(true);
  };
  const handleHistory=()=>{
    setHistoryModal(true)
  }

  return (
    <div className="flex flex-col items-center gap-6 justify-center w-full h-ful">
           
      {codeModal && (
        <GeneratedCode
          loading={textLoad}
          data={textData}
          setCodeModal={setCodeModal}
        />
      )}
      {
        historyModal&&<AIFreeHistory setHistoryModal={setHistoryModal}/>
      }
      <div className="space-x-2 mb-4">
     
        <button onClick={handleHistory} className="bg-[#1c0b2b] text-white px-4 py-2 rounded-full text-sm shadow hover:bg-purple-600">
          HISTORY
        </button>
        {textData&& !textLoad &&(
                <button
                disabled={postSent}
                onClick={()=>setFeedModal(true)}
                style={{ zIndex: 10000000 }}
                className={`px-2 py-2 bg-gray-300 bg-opacity-50 text-black rounded-full`}
              >
                <IoShareSocialOutline className="text-black" size={20} />
              </button>
           )
            
     }
      </div>
      <div className="mb-4 h-[20vh] w-[90%]">
        <textarea
          type="text"
          placeholder="Write your text..."
          className="text-white text-[20px] py-4 px-4 placeholder-gray-300 h-full w-full bg-[#1c0b2b] border-0 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
          value={desc}
          resize
          onChange={(e) => setDesc(e.target.value)}
          style={{ resize: "none" }}
        />
      </div>
      <div className="space-x-9 mb-4">
        {buttonData.slice(0, 3).map((e, index) => (
          <button
            onClick={() => handleButton(e.value)}
            key={index}
            className={`${
              button.includes(e.value) ? "opacity-20" : ""
            } ${buttonStyles}`}
          >
            {e.name}
          </button>
        ))}
      </div>
      <div className="space-x-9 mb-6">
        {buttonData.slice(3, 6).map((e, index) => (
          <button
            onClick={() => handleButton(e.value)}
            key={index}
            className={`${
              button.includes(e.value) ? "opacity-20" : ""
            } ${buttonStyles}`}
          >
            {e.name}
          </button>
        ))}
      </div>
      <div className="space-x-2 mb-4">
        <button
          disabled={!button.length ||textLoad || !desc}
          onClick={handleGenerate}
          className="bg-yellow-300 text-purple-900 px-8 py-2 rounded-full shadow hover:bg-yellow-200"
        >
          GENERATE →
        </button>
        
          <button
            disabled={textLoad || !textData}
            onClick={handleGeneratedCode}
            className={`${!textData?"opacity-20":""} bg-green-300 text-purple-900 px-8 py-2 rounded-full shadow hover:bg-yellow-200`}
          >
            {textLoad ? "Generating...</>" : "YOUR CODE →"}
          </button>
       
      </div>
    </div>
  );
};

export default TextGenerator;
