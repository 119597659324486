import { useContext, useState } from "react"
import api from "../api";
import { AuthContext } from "../context/AuthContext";

export const usePostVideoModal = () => {
    const [loading,setLoading]=useState(false);
    const { currentUser } = useContext(AuthContext)
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentUser.token}`,
    };
    const postVideoModal=async(data)=>{
        setLoading(true)
        try{
          await api.post(`/users/video-modal`, data,{ headers })
        }
        catch(err){
            console.log(err)
        }
        setLoading(false)

    }
    return {postVideoModal}
}

