import { useContext, useState } from "react"
import { AuthContext } from '../context/AuthContext'
import axios from "axios"
import api from "../api.js"
import { toast } from "react-toastify"
import useOverItem from "./useOverItem.js"
import { SET_XP_CHECK } from "../context/overviewcontext/action.js"
import { useNavigate } from "react-router-dom"
export const useProfile = () => {
    const [loading, setLoading] = useState(false)
    const { currentUser } = useContext(AuthContext)
    const navigate=useNavigate()
    const {dispatch:overViewDispatch}=useOverItem()
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentUser.token}`,
    };
    const notifySuccess = (message) => toast.success(message)
    const notifyError = (error) => toast.error(error)
    const changePassword = async (currentpassword, newpassword, confirmpassword) => {
        setLoading(true)
        try {
            const response = await api.post(`/changePassword`, {
                currentpassword,
                newpassword,
                confirmpassword
            }, { headers })
            if (response && response.status === 200) {
                notifySuccess(response.data.message)
                setLoading(false)
            }
        } catch (error) {
            notifyError(error.response.data.error)
            setLoading(false)
        }
    }
    const updateProfile = async (fullname, parent_email, username, phoneno) => {
        setLoading(true)
        try {
            const response = await api.post(`/setting/profileupdate`, {
                fullname,
                parent_email,
                username,
                phoneno
            }, { headers }, { withCredentials: true })
            if (response && response.status === 200) {

                notifySuccess(response.data.message)
                overViewDispatch({type:SET_XP_CHECK})
                navigate("/")
                setLoading(false)
                
            }
        } catch (error) {
            notifyError(error.response.data.error)
            setLoading(false)
        }
    }
    return { changePassword, loading, updateProfile }
}