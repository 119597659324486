import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const MyComponent = () => {
  // const [socket, setSocket] = useState(null);
  // const [isConnected, setIsConnected] = useState(false);

  // useEffect(() => {
  //   // Initialize socket connection on component mount
  //   const newSocket = io('http://localhost:3000/api/aitext');

  //   newSocket.on('connect', () => {
  //     console.log('Connected to server');
  //     setIsConnected(true);
  //   });

  //   newSocket.on('disconnect', () => {
  //     console.log('Disconnected from server');
  //     setIsConnected(false);
  //   });

  //   setSocket(newSocket);

  //   // Cleanup on unmount
  //   return () => {
  //     newSocket.close();
  //   };
  // }, []);

  // const handleButtonClick = () => {
  //   if (socket) {
  //     socket.emit('custom_event', { data: 'Hello from client' });
  //   }
  // };

  return (
    <div>
      {/* <h1>Socket.io with React</h1>
      <p>Connection Status: {isConnected ? 'Connected' : 'Disconnected'}</p>
      <button onClick={handleButtonClick}>Send Message</button> */}
    </div>
  );
};

export default MyComponent;
