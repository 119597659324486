import React, { createContext, useContext, useEffect, useReducer } from "react";
import notificationSound from "../../assets/audio/notification-audio.mp3";
import Pusher from "pusher-js";
import {
  SET_AI_MODAL,
  SET_DAILY_COUNT,
  SET_ERROR,
  SET_LOADING,
  SET_LONGEST_STREAK,
  SET_NOTIFICATION,
  SET_NOTIFICATION_ARRAY,
  SET_OVERVIEW,
  SET_QUEST_MESSAGE,
  SET_DAILY_SINGLE_QUEST,
  SET_STREAK_DAYS,
  SET_STREAK_MESSAGE,
  SET_VIDEO_MODAL,
  SET_XP_CHECK,
} from "./action";
import { AuthContext } from "../AuthContext";
import api from "../../api";

export const OverviewContext = createContext();

const initialState = {
  overview: {},
  loading: true,
  daily_count: 0,
  streakMessage: "",
  streakDays: [],
  best_longest_streak: 0,
  questMessage: "",
  quest: {},
  xpCheck: 0,
  error: false,
  notificatioArray: JSON.parse(localStorage.getItem("notificationArray")) || [],
  notificationStatus: localStorage.getItem("notification") === "true",
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_OVERVIEW:
      return {
        ...state,
        overview: action.payload,
      };
    case SET_STREAK_MESSAGE:
      return {
        ...state,
        streakMessage: action.payload,
      };
    case SET_QUEST_MESSAGE:
      return {
        ...state,
        questMessage: action.payload,
      };
    case SET_DAILY_SINGLE_QUEST:
      return {
        ...state,
        quest: action.payload,
      };
    case SET_DAILY_COUNT:
      return {
        ...state,
        daily_count: action.payload,
      };
    case SET_LONGEST_STREAK:
      return {
        ...state,
        best_longest_streak: action.payload,
      };
    case SET_STREAK_DAYS:
      return {
        ...state,
        streakDays: action.payload,
      };
    case SET_XP_CHECK: {
      return { ...state, xpCheck: state.xpCheck + 1 };
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case SET_VIDEO_MODAL: {
      return {
        ...state,
        overview: {
          ...state.overview,
          videoArray: [...state.overview.videoArray, action.payload],
        },
      };
    }
    case SET_NOTIFICATION_ARRAY: {
      return {
        ...state,
        notificatioArray: action.payload,
      };
    }
    case SET_NOTIFICATION: {
      return {
        ...state,
        notificationStatus: action.payload,
      };
    }
    default:
      return state;
  }
};
const OverViewProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${currentUser?.token}`,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { notificatioArray, notificationStatus } = state;
  const Overview = async () => {
    dispatch({ type: SET_LOADING, payload: true });
    dispatch({ type: SET_ERROR, payload: false });
    try {
      const response = await api.get(`/overview`, { headers });
      if (response && response.status === 200) {
        dispatch({ type: SET_OVERVIEW, payload: response.data.overview });

        dispatch({ type: SET_LOADING, payload: false });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: SET_ERROR, payload: true });
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  const notificationPop = (data) => {
    const audio = new Audio(notificationSound);
    audio.load(); // Ensure the audio is loaded
    if (!state.notificationStatus) {
      dispatch({ type: SET_NOTIFICATION, payload: true });
      localStorage.setItem("notification", "true");
      dispatch({ type: SET_NOTIFICATION_ARRAY, payload: [data] });

      audio.play().catch((error) => {
        console.error("Playback failed:", error);
        // Optional: Implement fallback logic or error handling here
      });
    } else {
      let payload = [data, ...state.notificatioArray];
      dispatch({ type: SET_NOTIFICATION_ARRAY, payload: payload });
      audio.play().catch((error) => {
        console.error("Playback failed:", error);
        // Optional: Implement fallback logic or error handling here
      });
    }
  };
  const shouldCallApi = async () => {
    const lastApiCallDate = JSON.parse(localStorage.getItem("user"));
    if (!lastApiCallDate?.lastHit) return true;
    let lastSaveHit = lastApiCallDate.lastHit;
    const now = new Date();
    // Create dates without time component for comparison
    const currentDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const lastHitTime = new Date(lastSaveHit);
    const lastHitDate = new Date(
      lastHitTime.getFullYear(),
      lastHitTime.getMonth(),
      lastHitTime.getDate()
    );

    // Calculate the difference in days
    const diffDays =
      (currentDate.getTime() - lastHitDate.getTime()) / (1000 * 3600 * 24);

    return diffDays >= 1;
  };

  const dailyStreak = async () => {
    let apicall = await shouldCallApi();
    if (!apicall) {
      console.log(apicall, "falsetrue");
      return;
    }
    try {
      const res = await api.get(`/daily-hit-counter`, { headers });
      if (res.status === 200) {
        dispatch({ type: SET_DAILY_COUNT, payload: res.data.daily_count });
        dispatch({ type: SET_STREAK_MESSAGE, payload: res.data.status });
        dispatch({ type: SET_STREAK_DAYS, payload: res.data.streakDays });
        dispatch({
          type: SET_LONGEST_STREAK,
          payload: res.data.best_longest_streak,
        });
        dispatch({ type: SET_XP_CHECK });
        dispatch({ type: SET_QUEST_MESSAGE, payload: res.data.questMessage });
        dispatch({ type: SET_DAILY_SINGLE_QUEST, payload: res.data.quest });
        const newUserData = { ...currentUser, lastHit: res.data.lastHitDate };
        localStorage.setItem("user", JSON.stringify(newUserData));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let isGet = true;
    if (isGet) {
      if (currentUser?.token) {
        dailyStreak();
      }
    }
    return () => {
      isGet = false;
    };
  }, [currentUser?.token]);

  useEffect(() => {
    let isGet = true;
    if (isGet) {
      if (currentUser?.token) {
        Overview();
      }
    }
    return () => {
      isGet = false;
    };
  }, [currentUser?.token, state?.xpCheck]);

  const setNotificationStatus = (payload) => {
    dispatch({ type: SET_NOTIFICATION, payload: payload });
  };
  const setNotificationArray = (payload) => {
    dispatch({ type: SET_NOTIFICATION_ARRAY, payload: payload });
  };
  const value = {
    state,
    dispatch,
    Overview,
    setNotificationStatus,
    setNotificationArray,
    notificationPop,
  };

  useEffect(() => {
    // if(!(localStorage.getItem("notification") === "true")){

    // }
    let isGet = true;
    if (isGet) {
      localStorage.setItem(
        "notificationArray",
        JSON.stringify(notificatioArray)
      );
    }
    return () => {
      isGet = false;
    };
  }, [notificatioArray]);
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APPID, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    const channelName = `user-${currentUser?.email}-channel`;
    const channel = pusher.subscribe(channelName);
    const audio = new Audio(notificationSound);
    audio.load(); // Ensure the audio is loaded

    channel.bind("feed-liked", (data) => {
      if (!notificationStatus) {
        setNotificationStatus(true);
        localStorage.setItem("notification", "true");
        setNotificationArray([data]);
        audio.play().catch((error) => {
          console.error("Playback failed:", error);
          // Optional: Implement fallback logic or error handling here
        });
      } else {
        setNotificationArray([data, ...notificatioArray]);
        audio.play().catch((error) => {
          console.error("Playback failed:", error);
          // Optional: Implement fallback logic or error handling here
        });
      }
    });

    // Clean up
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [notificatioArray, notificationStatus]); // Re-run the effect if userId changes, or if hasLiked changes
  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  );
};

export default OverViewProvider;
