import React, { useEffect, useRef, useState } from "react";
import "./Design.css";
import { useContext } from "react";

import CarBg from "../../assets/screens/Design_Cars.webp";
import StraightCarousel from "./StraightCarousel";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import DownloadButton from "../../components/DownloadButton";
import { useGenerateImage } from "../../hooks/useGenerateImage";
import { useDownload } from "../../hooks/useDownload";
import useOverItem from "../../hooks/useOverItem";
import { SyncLoader } from "react-spinners";
import DesignQuest from "../../layouts/DesignQuest";
import LimitErrorModal from "../../components/popups/LimitErrorModal";
import { ErrorContext } from "../../context/errorcontext/ErrorContext";
import { useParams } from "react-router-dom";
import ShareCompoenents from "../screencomponent/ShareCompoenents";
import { useFeed } from "../../hooks/useFeed";
import { QuestContext } from "../../context/questContext/QuestContext";
import { IoShareSocialOutline } from "react-icons/io5";

// const modalStyle = {
//   backgroundImage: `url(${CarBg})`,
//   backgroundSize: "contain", // or "contain" depending on how you want the image to fit
//   opacity: 0.7,
// };
const carArray = [
  { name: "Sedan" },
  { name: "Suv" },
  { name: "Hatchback" },
  { name: "coupe" },
];
const detailArray = [
  { name: "Ultra Detail" },
  { name: "Remastery" },
  { name: "Cartoon" },
  { name: "Zoomed In" },
];
const DesignCars = () => {
  const { quest_type } = useParams();
  const [selected, setSelected] = useState(0);
  const [feedModal, setFeedModal] = useState(false);
  const { postFeed, postSent } = useFeed();
  const {
    questState: { questPrompt },
  } = useContext(QuestContext);
  // function handleChange(index) {
  //   setCurrentIndex(index);
  // }
  const imgRef = useRef(null);
  const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0 });

  const [button, setButton] = useState([]);
  const [carType, setCarType] = useState("");
  const [color, setColor] = useState("");
  const [desc, setDesc] = useState("");
  const handleButton = (butt) => {
    if (button.includes(butt)) {
      setButton(button.filter((e) => e !== butt));
    } else {
      setButton([...button, butt]);
    }
  };

  const { loading, fetchImage, images, errorMessage } = useGenerateImage();
  const { state } = useOverItem();
  const { downloadFunction, downloading } = useDownload();
  const [showModal, setShowModal] = useState(false);
  const { errorState } = useContext(ErrorContext);

  const generateButton = async () => {
    let message = await fetchImage({
      color,
      setColor,
      desc,
      setDesc,
      carType,
      setCarType,
      button,
      setButton,
      width:
        imgDimensions.width % 8 === 0
          ? imgDimensions.width
          : imgDimensions.width - (imgDimensions.width % 8),
      height:
        imgDimensions.height % 8 === 0
          ? imgDimensions.height
          : imgDimensions.height - (imgDimensions.height % 8),
      quest_type,
    });
    if (message === "Error") {
      setShowModal(true);
    }
  };
  useEffect(() => {
    const updateDimensions = () => {
      if (imgRef.current) {
        setImgDimensions({
          width: imgRef.current.offsetWidth,
          height: imgRef.current.offsetHeight,
        });
      }
    };

    // Update dimensions initially
    updateDimensions();

    // // Optional: Update dimensions on window resize
    // window.addEventListener('resize', updateDimensions);

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  const [opacity, setOpacity] = useState(0); // Initial opacity set to 0
  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    setTimeout(() => {
      setOpacity(1); // Change opacity to 1 to start the transition after component mounts
    }, 200);
  }, []);
  const modalStyle = {
    backgroundImage: `url(${CarBg})`,
    backgroundSize: "contain", // or "contain" depending on how you want the image to fit
    opacity: opacity,
    transition: "opacity 1s ease-in-out",
  };

  return (
    <DesignQuest modalStyle={modalStyle} title="Welcome To My Garage">
      {errorState.errorModal && <LimitErrorModal />}
      {feedModal && (
        <ShareCompoenents
          feedModal={feedModal}
          setFeedModal={setFeedModal}
          body={{
            title: quest_type,
            image: images?.generated_images?.[selected]?.url,
            userPrompt: questPrompt,
          }}
          postFeed={postFeed}
          postSent={postSent}
        />
      )}

      <div
        style={{ zIndex: 10000 }}
        className="flex justify-center items-center mt-2 z-30"
      >
        <div className="w-[60%] relative flex h-[50vh]  rounded-lg bg-gray-700 justify-center items-center">
          <div
            ref={imgRef}
            className="overflow-hidden rounded-lg w-[80%] h-[90%]"
          >
            {loading ? (
              <SyncLoader
                color="#36d7b7"
                style={{
                  zIndex: 10000,
                  display: "flex",
                  justifyContent: "center",
                  alignItem: "center",
                  marginTop: "30px",
                }}
              />
            ) : (
              ""
            )}
            {images?.generated_images && !loading && (
              <img
                src={images?.generated_images?.[selected]?.url}
                className="w-full h-full hover:scale-[1.2] object-cover transition-transform duration-300 ease-in-out"
                alt=""
              />
            )}
          </div>
          {images?.generated_images && !loading && (
            <button
              disabled={postSent}
              onClick={() => setFeedModal(true)}
              style={{ zIndex: 10000000 }}
              className={`absolute top-5 px-2 py-2 bg-gray-300 bg-opacity-50 text-black rounded-full right-5`}
            >
              <IoShareSocialOutline className="text-black" size={20} />
            </button>
          )}

          <button
            disabled={downloading}
            onClick={() =>
              downloadFunction({
                imgUrl: images?.generated_images?.[selected]?.url,
              })
            }
            className="absolute right-10 bottom-5 px-2 py-2 bg-white font-bold text-black rounded-full"
          >
            {!downloading ? (
              <DownloadButton />
            ) : (
              <span className="text-black font-bold text-lg">...</span>
            )}
          </button>
        </div>
      </div>

      <div className="relative flex justify-center items-center z-50">
        <div className="absolute bottom-[-1rem] rounded-full flex justify-center gap-4 items-center">
          <button
            disabled={loading || !images.generated_images?.length}
            onClick={() =>
              setSelected(
                (selected - 1 + images?.generated_images?.length) %
                  images?.generated_images?.length
              )
            }
            className="bg-gray-700 p-2 rounded-full"
          >
            <IoIosArrowDropleftCircle
              className="cursor-pointer text-white"
              style={{ fontSize: "30px" }}
            />
          </button>

          <button
            disabled={loading || !color || !carType}
            onClick={generateButton}
            className="text-black rounded-full font-bold px-4 py-1 bg-white"
          >
            Generate
          </button>

          <button
            disabled={loading || !images.generated_images?.length}
            onClick={() =>
              setSelected((selected + 1) % images?.generated_images?.length)
            }
            className="bg-gray-700 p-2 rounded-full"
          >
            <IoIosArrowDroprightCircle
              className="cursor-pointer text-white"
              style={{ fontSize: "30px" }}
            />
          </button>
        </div>
      </div>

      <div className="flex items-center justify-center absolute bottom-5 left-0 right-0 z-30">
        <StraightCarousel
          images={images}
          loading={loading}
          selected={selected}
        />
      </div>
      <div className="absolute w-40  mt-2 top-[6rem] left-10">
        <input
          value={color}
          onChange={(e) => setColor(e.target.value)}
          placeholder="Type colors"
          className="text-black rounded-xl w-full  py-2 px-2 bg-white mt-2 top-[6rem] left-10"
        />
      </div>
      <div className="absolute w-40  mt-2 top-[6rem] right-10">
        <input
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
          placeholder="Enter Description"
          className="text-black rounded-xl py-2 px-2 w-full bg-white mt-2 top-[6rem] right-10"
        />
      </div>

      <div className="absolute rounded-xl text-black  bg-white mt-2 top-[14rem] left-10">
        <div className="font-bold outline-none w-40 h-40 flex  flex-shrink flex-col items-center">
          {carArray.map((e) => (
            <div
              key={e.name}
              onClick={() => setCarType(e.name.toUpperCase())}
              style={{ border: "2px solid black" }}
              className={`${
                carType === e.name.toUpperCase() ? "opacity-20" : ""
              } cursor-pointer uppercase flex-1 px-2 py-2  w-full h-1/4 flex-grow text-sm font-bold`}
            >
              {e.name}
            </div>
          ))}
        </div>
      </div>
      <div className="absolute rounded-xl text-black  bg-white mt-2 top-[14rem] right-10">
        <div className="font-bold outline-none w-40 h-40 flex  flex-shrink flex-col items-center">
          {detailArray.map((e) => (
            <div
              onClick={() => handleButton(e.name)}
              style={{ border: "2px solid black" }}
              className={`${
                button.includes(e.name) ? "opacity-20" : ""
              } cursor-pointer flex-1 px-2 py-2  w-full h-1/4 flex-grow text-sm font-bold`}
            >
              {e.name}
            </div>
          ))}
        </div>
      </div>
    </DesignQuest>
  );
};

export default DesignCars;
