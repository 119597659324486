import React, { useEffect } from 'react'
import CourseCard from '../components/CourseCard'
import { useCourses } from '../hooks/useCourses'
import { SyncLoader } from 'react-spinners'
const Courses = () => {
    const { fetchCourses, loading, courses } = useCourses()
    useEffect(() => {
        fetchCourses()
        //eslint-disable-next-line
    }, [])
    return (
        <div>
            {loading && (<SyncLoader color="#36d7b7" />)}
            {courses && courses.map((item, index) => (
                <CourseCard data={item} />
            ))}
        </div>
    )
}

export default Courses