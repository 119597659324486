import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Dashboard from "../layouts/Dashboard";
import Overview from "../pages/Overview";
import MyFeed from "../pages/MyFeed";
import Settings from "../layouts/Settings";
import ProfileSettings from "../pages/ProfileSettings";
import PasswordSettings from "../pages/PasswordSettings";
import Quest from "../layouts/Quest";
import QuestList from "../pages/QuestList";

import Schedule from "../layouts/Schedule";
import RegularCourse from "../pages/RegularCourse";
import Courses from "../layouts/Courses";
import PlanBilling from "../pages/PlanBilling";

import PaymentSuccessPage from "../pages/PaymentSuccessPage";
import PaymentCancelPage from "../pages/PaymentCancelPage";
import Analytics from "../pages/Analytics";
import AdminPrivatreRoutes from "./AdminPrivateRoutes";
import MyComponent from "../pages/MyComponent";
import Aisearch from "../pages/Aisearch";
import SingleQuest from "../pages/SingleQuest";
import Academics from "../pages/Academics";
import AcademicsPage from "../pages/AcademicsPage";

const privaterouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Dashboard />}>
        <Route path="/mycustom" element={<MyComponent />} />
        <Route index element={<Overview />} />

        <Route path="/success" element={<PaymentSuccessPage />} />
        <Route path="/cancel" element={<PaymentCancelPage />} />
        <Route path="questlist" element={<QuestList />} />

        <Route path="aisearch" element={<Aisearch />} />
        <Route path="feeds" element={<MyFeed />} />

        <Route path="settings" element={<Settings />}>
          <Route index element={<ProfileSettings />} />
          <Route path="password" element={<PasswordSettings />} />

          <Route path="plan&billing" element={<PlanBilling />} />

          <Route path="paymentmethods" element />
        </Route>
        <Route path="quest" element={<Quest />} />
        <Route
          path="analytics"
          element={
            <AdminPrivatreRoutes>
              <Analytics />
            </AdminPrivatreRoutes>
          }
        />
        <Route
          path="academics"
          element={
            <AdminPrivatreRoutes>
              <Academics />
            </AdminPrivatreRoutes>
          }
        />
        <Route path="courses" element={<Courses />} />
        <Route path="schedule" element={<Schedule />}>
          <Route index element={<RegularCourse />} />
          <Route path="OneoOne" element />
        </Route>
        <Route path="discusion" element />
      </Route>
      <Route path="/quest/:quest_type" element={<SingleQuest />} />
      <Route path="/academics/:id" element={<AcademicsPage />} />
    </>
  )
);

export { privaterouter };
