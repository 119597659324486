import { useState } from "react";
import { toast } from "react-toastify";
import api from "../api";
import { useNavigate } from "react-router-dom";

export const useResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const resetPassword = async (data) => {
    setLoading(true)
    try {
      let response = await api.post("/users/resetpassword", data);
      toast.success(response.data.message || "password reset successfully", {
        position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
        autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
        hideProgressBar: false, // Hide the progress bar
      });
      navigate("/");
    } catch (error) {
      // Handle any errors that occurred during the API call

      toast.error(error.response.data.message || "Worng credential", {
        position: toast.POSITION.TOP_RIGHT, // Change the position of the toast
        autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
        hideProgressBar: false, // Hide the progress bar
      });
    }
    setLoading(false)
  };
  return { resetPassword, resetLoading: loading };
};
