import React, { useState, useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import { ErrorContext } from '../../context/errorcontext/ErrorContext';
import { SET_ERROR_RESET } from '../../context/errorcontext/action';

const LimitErrorModal = () => {
  const [timer, setTimer] = useState(10);
  const {errorState,dispatch}=useContext(ErrorContext)

  useEffect(() => {
    let interval;
    if (errorState.errorModal && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
       dispatch({type:SET_ERROR_RESET}) // Close the modal after timer ends
    }
    return () => clearInterval(interval);
  }, [timer, dispatch,errorState.errorModal]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const circumference = 2 * Math.PI * 54;
  const circleStyle = {
    strokeDasharray: circumference,
    strokeDashoffset: circumference - (circumference * timer) / 10,
  };

  const modalVariants = {
    hidden: { y: '-100vh', opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    exit: { y: '-100vh', opacity: 0, transition: { duration: 1 } }
  };

  return (
    <motion.div 
    style={{zIndex:100000000}}
    
      initial="hidden"
      animate={errorState.errorModal? "visible" : "exit"}
      variants={modalVariants}
      className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center`}
    >
      <div className="bg-white p-4 flex flex-col justify-center items-center rounded-lg shadow-lg w-1/3">
        <h2 className="text-[20px] font-bold text-black">{errorState.errorMessage}</h2>

        <svg className="mt-4" width="120" height="120" viewBox="0 0 120 120">
          <circle cx="60" cy="60" r="54" stroke="gray" strokeWidth="8" fill="none" />
          <circle cx="60" cy="60" r="54" stroke="blue" strokeWidth="8" fill="none" style={circleStyle} />
          <text x="50%" y="50%" textAnchor="middle" stroke="#51c5cf" strokeWidth="1px" dy=".3em" fontSize="20">
            {formatTime(timer)}
          </text>
        </svg>
    
        <Link to="/settings/plan&billing" className="mt-4 bg-blue-500 font-bold uppercase text-white py-2 px-4 rounded block text-center">
          Go to Payment
        </Link>
      </div>
    </motion.div>
  );
};

export default LimitErrorModal;
