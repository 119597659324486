import React, { useContext, useEffect, useRef } from "react";
import Slider from "react-slick";
import styles from "../styles/Carausel.module.css";
import { textcolor } from "../constants/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import VideoImageImports from "../assets";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { QuestContext } from "../context/questContext/QuestContext";
import { SET_INDEX_LIST, SET_SIGLE_MODAL, SET_SINGLE_QUEST } from "../context/questContext/action";
import { Link, useNavigate } from "react-router-dom";
import useOverItem from "../hooks/useOverItem";
import { FaLock } from "react-icons/fa6";
import { IoArrowForward } from "react-icons/io5";

const OwlCarouselComponent = () => {
  const {
    questDispatch,
    questState: { quests, currIndex },
  } = useContext(QuestContext);
  const { state } = useOverItem();
  const navigate=useNavigate()
  const arrowRef = useRef(null);
  const settings = {
    infinite: true,
    className: "center",
    // variableWidth: true,

    // lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: 0,
    beforeChange: (current, next) => {
      questDispatch({
        type: SET_INDEX_LIST,
        payload: next,
      });
    },
    // afterChange: (index) => setImageIndex(index),

    dots: false,

    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
      

        },
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,

        
      //   },
      // },
    ],
  };
  useEffect(() => {
    if (arrowRef.current) {
      // Directly navigate to the slide without losing the transition.
      arrowRef.current.slickGoTo(currIndex);
    }
  }, [currIndex]);
  const { quest_type_obj } = VideoImageImports;
  const navigateFunction = () => {
    navigate("/");
  };
 
  return (
    <div className="relative w-full h-full">
      <Slider ref={arrowRef} {...settings} className="flex gap-2">
        {quests?.map((img, idx) => (
          <div
            style={{
              fontFamily: "'Poppins',sans-serif",
              // width: idx === currIndex?600:500
            }}
            className={`${
              idx === currIndex
                ? `${styles.slide} ${styles.activeSlide}`
                : `${styles.slide} rounded-none`
            } relative flex flex-col bg-[#2c2654] shadow-xl rounded-[5%] h-[75%] w-full py-4 px-6`}
          >
                  {((state.overview?.payment_status === false && currIndex + 1 > 5 && idx === currIndex) ||
              (state.overview?.payment_status === true &&  
                currIndex + 1 > state.overview?.total_unlock_quest + 5)) && (

                  <div
                  style={{ zIndex: 100000000000000 }}
                  className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center`}
                >
                  <div className="bg-transparent flex-col p-4 flex justify-center items-center rounded-lg shadow-lg gap-6">
                    <FaLock size={50}/>
                    {state.overview?.payment_status === true ? (
          <h2 className="text-[12px] font-bold text-white">
            Quest Unlock on daily visits
          </h2>
        ) : (
          <Link to="/settings/plan&billing" className="flex items-center gap-2">
            <h2 className="text-[12px] font-bold text-white">
              Please Subscribed a plan to unlock
            </h2>
            <IoArrowForward size={15} className="text-white" />
          </Link>
        )}

                    </div>
                    </div>
            )}
            <LazyLoadImage
              alt="Background"
              threshold={150} // This is where you specify the threshold
              src={quest_type_obj[img?.quest_type]?.bg} // Your dynamic background image source
              effect="blur"
              className="object-cover rounded-[5%] opacity-30 absolute  inset-0 w-full h-full"
              wrapperClassName="w-full h-full rounded-[5%] opacity-30 absolute top-0 left-0"
            />

            <LazyLoadImage
              alt="Background2"
              threshold={150} // This is where you specify the threshold
              src={quest_type_obj[img?.quest_type].animate} // Your dynamic background image source
              effect="blur"
              className="w-[90%] h-[90%] object-contain absolute top-[3%] right-[-12%]"
              wrapperClassName="w-[90%] h-[90%] object-contain absolute top-[3%] right-[-12%]"
            />
            <div className="flex flex-col w-full h-full justify-start  absolute top-[10%] px-4 py-4 left-[10%] items-start ">
              {/* title div */}
              <div className="flex flex-col items-start">
                <div className="flex items-center justify-center">
                  <h1
                    style={{
                      fontFamily: "'Inter','sans-serif'",
                    }}
                    className="text-[16px] lg:text-[20px] font-bold"
                  >
                    {img?.title}
                  </h1>
                </div>
                <div className="flex items-center justify-center">
                  <h1
                    className={`text-[12px] text-[${textcolor}] font-bold px-1`}
                  >
                    10 XP
                  </h1>
                </div>
              </div>
              {/* xp div */}

              {/* description div */}
              <div
                style={{ lineHeight: 1.6 }}
                className="h-[30%] w-[55%] flex pb-2 text-[12px]  text-gray-400 mt-4 overflow-auto"
              >
                {img?.desc}
              </div>
              {/* last div */}
              <div className="flex justify-between w-full mt-8 items-center justify-self-stretch">
                {/* text div */}
                <div className="flex gap-2 items-center">
                  <div className=""></div>
                  <div></div>
                </div>

                {/* button div */}
              </div>
            </div>
            <button
            onClick={()=>{
              navigate(`/quest/${img.quest_type}`)
              questDispatch({type:SET_SIGLE_MODAL,payload:img.quest_type})
              questDispatch({ type: SET_SINGLE_QUEST, payload:img});
            }
            }
              className={`w-24 h-8 absolute bottom-3 right-5 bg-[${textcolor}] text-black text-[16px] font-bold rounded-full`}
            >
              Start
            </button>
          </div>

          // <div
          //   className={`${
          //     idx === imageIndex
          //       ? `${styles.slide} ${styles.activeSlide}`
          //       : styles.slide
          //   }`}
          // >
          //   <img src={img} alt={img} />
          // </div>
        ))}
      </Slider>
      <div class="h-8 w-full lg:w-1/2 justify-between lg:justify-around gap-[50%]  cursor-pointer text-[white] border-none flex  absolute top-[45%] lg:translate-x-1/2">
        <button
          onClick={() => arrowRef.current.slickPrev()}
          className="rounded-full w-12 h-12 px-2 py-2 bg-black bg-opacity-70 flex justify-center items-center"
        >
          <IoIosArrowBack />
        </button>
        <button
          onClick={() => arrowRef.current.slickNext()}
          className="rounded-full w-12 h-12 px-2 py-2 bg-black bg-opacity-70 flex justify-center items-center"
        >
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  );
};
export default OwlCarouselComponent;
