import ScheduleCrad from '../components/ScheduleCard'
import { courses } from '../constants/userprofile'
const RegularCourse = () => {
    return (
        <div className="flex flex-col gap-4">
            {courses.map((item, index) => (
                <ScheduleCrad title={item.title} desc={item.desc} author={item.author} banner={item.banner} percentage={item.percentage} key={index} />
            ))}
        </div>
    )
}

export default RegularCourse