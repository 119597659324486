import React from "react";
import useOverItem from "../hooks/useOverItem";
import PricingPlan from "../components/PricingPlan";
const PlanBilling = () => {
  const goldFeatures = [
    "Access to introductory AI modules",
    "Participation in monthly challenges",
    "Weekly progress report",
  ];

  const premiumFeatures = [
    "Everything in the Gold Plan",
    "Access to interactive quizzes and assessments",
    "Personalized learning path and 1-1 mentorship",
  ];

  const { state } = useOverItem();
  return (
    <>
      <div className="flex justify-center gap-6 px-6 pb-8">
        <PricingPlan
          plan="Premium"
          price={60}
          features={premiumFeatures}
          isPremium={true}
          seven_days_trail={state.overview?.seven_days_trail}
          payment_status={state.overview?.payment_status}
          prev_Plan={state.overview?.payment_plan}
        />
        <PricingPlan
          plan="Gold"
          price={30}
          features={goldFeatures}
          isPremium={false}
          seven_days_trail={state.overview?.seven_days_trail}
          payment_status={state.overview?.payment_status}
          prev_Plan={state.overview?.payment_plan}
        />
      </div>
    </>
  );
};

export default PlanBilling;
