import React from 'react'

const PaymentPlanButton = ({title,paymentButton,setPaymentButton}) => {
  return (
    <button 
    onClick={()=>setPaymentButton(title)}
    style={{
        border: `${paymentButton!==title? "3px solid #D1D1D1":"3px solid #315CFD" }`
    }} className='rounded-xl w-32 min-h-8 text-black font-bold bg-[#FFFFFF] flex items-center justify-center px-2 py-2'><span>{title}</span></button>
  )
}

export default PaymentPlanButton