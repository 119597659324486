import React from "react";
import ListItem from "./ListItem";

const ListContainer = ({ title, subTitle,subject,setAcademicModal }) => {
  return (
    <div className="flex flex-col w-full gap-2 mt-4">
      <div className="text-lg px-4">{title}</div>
      {/* <div className="w-full text-gray-500 px-4">{subTitle}</div> */}
      <div className="flex items-center gap-2 overflow-x-auto h-full">
        <ListItem title={title} setAcademicModal={setAcademicModal} subject={subject}/>
      </div>
    </div>
  );
};

export default ListContainer;
