import { useContext, useState } from "react"
import { AuthContext } from "../context/AuthContext"
import api from "../api.js"
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
export const useLogin = () => {
    const { dispatch } = useContext(AuthContext)
    const [loginLoading, setLoginLoading] = useState(false);
    const navigate = useNavigate()
    const notify = (error) => toast.error(error);
    const login = async (email, password) => {
        setLoginLoading(true)
        try {
            const response = await api.post('/login', {
                email,
                password
            })
            if (response && response.status === 200) {
               
                dispatch({ type: "LOGIN", payload: response.data })
                localStorage.setItem("user", JSON.stringify(response.data));
                setLoginLoading(false)
            }
        } catch (error) {
            notify(error.response.data.error)
            setLoginLoading(false)
        }
    }
    return { login, loginLoading }
}