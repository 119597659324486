import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
export function PomoderTimer({ percentage, countdown }) {
  return (
    <div className="w-6 h-6  text-center text-white">
      <CircularProgressbar
        value={percentage}
        strokeWidth={20}
        styles={buildStyles({
          textSize: '16px',
          pathColor: "#4CAF50",
          trailColor: "#d6d6d6",
    
        })}
      />
    </div>
  );
}

export default PomoderTimer;
