const AuthReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN": {
            return {
                ...state,currentUser: action.payload,
            };
        }
        case "LOGOUT": {
            return {
                ...state,currentUser: null,
            };
        }
        case "PAYMENT_STATUS":{
            return {
                ...state,plan_status:action.payload

            }
        }
        case "SET_LOADING":{
            return {
                ...state,paymentLoading:action.payload
            }
        }
        default:
            return state;
    }
};

export default AuthReducer;