import React from 'react'
import person from '../assets/icons/person.webp'
const ScheduleCrad = ({ title, desc, author, banner, percentage }) => {
    return (
        <div className="flex flex-col gap-3">
            <div className="flex border-b border-white">
                <img src={banner} alt="" className='w-28 h-28 rounded-lg' />
                <div className="flex flex-col p-4 gap-4">
                    <span className='text-base font-medium'>{title}</span>
                    <span className='text-sm leading-normal text-[#84818A]'>{desc}</span>
                    <span className='text-sm leading-normal text-[#009580] flex items-center gap-2 ml-auto'><img src={person} alt="" className='w-6 h-6' /> by {author}</span>
                </div>
            </div>
            <div className="flex justify-between items-center gap-3">
                <span className='text-sm leading-normal text-[#FCC43E]'>Progress</span>
                <div className='rounded-lg h-1 bg-white w-full'>
                    <div className="bg-[#FFAB2A] h-full rounded-lg" style={{ width: percentage + "%" }} />
                </div>
                <span className='text-[#009580] text-sm leading-normal'>{percentage}%</span>
            </div>
        </div>
    )
}

export default ScheduleCrad