// The Plan component
import styles from "../styles/PricingPlan.module.css"
const PlanText = ({ name }) => {
    // const shinyGradient = {
    //     background: 'linear-gradient(to right, #19788E 0%, #0A0601 100%)',
    //     backgroundSize: '200% auto',
    //     color: '#fff',
    //     // animation: `${styles.shine} 1.5s linear infinite`,
    //   };

return (<div className={`py-1 px-4 text-center rounded-md w-auto hidden bg-gray-400 bg-opacity-30 md:flex shadow-2xl`}
    >
  <h2 className="text-sm font-bold animate-pulse">{name==="Free"?"Upgrade":name}</h2>
</div>
  
);
}
   

  export default PlanText