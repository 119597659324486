export const DATA = {
  math: [
    {
      id: 1,
      title: "addition",
      img: "addition",
      
    },
    {
      id: 2,
      title: "subtraction",
      img: "addition",
      
    },
    {
      id: 3,
      title: "multiplication",
      img: "addition",
      
    },
    {
      id: 4,
      title: "division",
      img: "addition",
      
    },
  ],
};
