import { useContext, useState } from "react";
import api from "../api";
import { AuthContext } from "../context/AuthContext";

const webmToWav = async (webmBlob) => {
  const arrayBuffer = await webmBlob.arrayBuffer();
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  const wavBlob = audioBufferToWavBlob(audioBuffer);
  return wavBlob;
};

const audioBufferToWavBlob = (audioBuffer) => {
  const numOfChan = audioBuffer.numberOfChannels;
  const length = audioBuffer.length * numOfChan * 2 + 44;
  const buffer = new ArrayBuffer(length);
  const view = new DataView(buffer);
  const channels = [];
  const sampleRate = audioBuffer.sampleRate;
  let offset = 0;
  let pos = 0;

  // write WAV header
  setUint32(0x46464952); // "RIFF"
  setUint32(length - 8); // file length - 8
  setUint32(0x45564157); // "WAVE"

  setUint32(0x20746d66); // "fmt " chunk
  setUint32(16); // length = 16
  setUint16(1); // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(sampleRate);
  setUint32(sampleRate * 2 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 2); // block-align
  setUint16(16); // 16-bit (hardcoded in this demo)

  setUint32(0x61746164); // "data" - chunk
  setUint32(length - pos - 4); // chunk length

  // write interleaved data
  for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
    channels.push(audioBuffer.getChannelData(i));
  }

  while (pos < length) {
    for (let i = 0; i < numOfChan; i++) {
      // interleave channels
      let sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
      sample = (sample * 32767) | 0; // scale to 16-bit signed int
      view.setInt16(pos, sample, true); // write 16-bit sample
      pos += 2;
    }
    offset++; // next source sample
  }

  // create Blob
  return new Blob([buffer], { type: "audio/wav" });

  function setUint16(data) {
    view.setUint16(pos, data, true);
    pos += 2;
  }

  function setUint32(data) {
    view.setUint32(pos, data, true);
    pos += 4;
  }
};
export const useUploadAudio = () => {
  const [transcript, setTranscript] = useState("");
  const { currentUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(false);
  const uploadAudio = async (audioBlobUrl) => {
    setLoading(true);
    try {
      console.log(audioBlobUrl);
      // const wavBlob = await webmToWav(audioBlobUrl);
      // console.log(wavBlob)

      // Send the wavBlob to the server
      // const formData = new FormData();
      // formData.append("file", wavBlob, "recording.wav");

      const formData = new FormData();
      formData.append('file', audioBlobUrl, 'recording.webm');
      console.log(formData);

      const res = await api.post("/upload/audio", formData, {
        headers: {
          'Authorization': `Bearer ${currentUser.token}`,
          "Content-Type": "multipart/form-data", // This is necessary for file uploads
        },
      });
      console.log(res);
      if (res.status === 200) {
        console.log(res);
        setTranscript(res.data.text);
        setLoading(false);
        return res.data.text;
      }
    } catch (e) {
      console.log("failed to upload audio");
    }
    setLoading(false);
  };
  return {
    uploadAudio,
    transcript,
    audioLoading: loading,
  };
};
