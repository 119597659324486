import { Formik } from "formik";
import React from "react";
import { profileupdateschema } from "../constants/validationschema";
import { useProfile } from "../hooks/useProfile";
import 'react-phone-number-input/style.css';
import ClipLoader from "react-spinners/ClipLoader";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
const ProfileSettings = () => {
  const { updateProfile, loading } = useProfile();
  return (
    <div className="flex flex-col px-8 py-6 gap-7">
      <div className="flex flex-col">
        <div className="text-2xl font-bold">Profile</div>
        <div className="text-sm font-normal"></div>
      </div>
      <Formik
        initialValues={{
          fullname: "",
          parent_email: "",
          username: "",
          phoneno: "",
        }}
        onSubmit={async (values) => {
          let { fullname, username, parent_email, phoneno } = values;
          phoneno=formatPhoneNumberIntl(phoneno)
          
          await updateProfile(fullname, parent_email, username, phoneno);
        }}
        validationSchema={profileupdateschema}
      >
        {({
          touched,
          values,
          setFieldValue,
          setFieldTouched,
          errors,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-2">
              <div className="flex gap-[5.44rem] items-center">
                <div className="w-[7.5rem] text-[#009580] font-medium text-base ">
                  Full Name
                </div>
                <input
                  type="text"
                  className="w-[18.875rem] h-[3rem] flex-shrink-0 rounded-[0.74rem] border border-solid border-[#009580] p-4"
                  style={{
                    background:
                      "radial-gradient(77.86% 43.80% at 50.00% 50.00%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)",
                  }}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("fullname")}
                  name="fullname"
                  id="fullname"
                />
                {touched.fullname && errors.fullname && (
                  <span className="text-[#ff0000] font-normal">
                    {errors.fullname}
                  </span>
                )}
              </div>
              <div className="flex gap-[5.44rem] items-center">
                <div className="w-[7.5rem] text-[#009580] font-medium text-base ">
                  Parent's Email
                </div>
                <input
                  type="email"
                  className="w-[18.875rem] h-[3rem] flex-shrink-0 rounded-[0.74rem] border border-solid border-[#009580] p-4"
                  style={{
                    background:
                      "radial-gradient(77.86% 43.80% at 50.00% 50.00%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)",
                  }}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("parent_email")}
                  id="parent_email"
                  name="parent_email"
                />
                {touched.parent_email && errors.parent_email && (
                  <span className="text-[#ff0000] font-normal">
                    {errors.parent_email}
                  </span>
                )}
              </div>
              <div className="flex gap-[5.44rem] items-center">
                <div className="w-[7.5rem] text-[#009580] font-medium text-base ">
                  UserName
                </div>
                <input
                  type="text"
                  className="w-[18.875rem] h-[3rem] flex-shrink-0 rounded-[0.74rem] border border-solid border-[#009580] p-4"
                  style={{
                    background:
                      "radial-gradient(77.86% 43.80% at 50.00% 50.00%, #1D2226 0%, #181D21 39.78%, #0C0E11 89.43%, #080A0D 100%)",
                  }}
                  onChange={handleChange}
                  onBlur={() => setFieldTouched("username")}
                  id="username"
                  name="username"
                />
                {touched.username && errors.username && (
                  <span className="text-[#ff0000] font-normal">
                    {errors.username}
                  </span>
                )}
              </div>
              <div className="flex gap-[5.44rem] items-center">
                <div className="w-[7.5rem] text-[#009580] font-medium text-base ">
                  Phone Number
                </div>
               

          
                <PhoneInput
                style={{paddingLeft:"10px",color:"black",backgroundColor:"white",outline:"none"}}
                className="w-[18.875rem] h-[3rem] flex-shrink-0 rounded-[0.74rem] border border-solid border-[#009580] p-4"
                  placeholder="Enter phone number"
                  id="phoneno"
                  name="phoneno"
                  value={values.phoneno}
                  onChange={(val) => setFieldValue("phoneno", val)}
                  onBlur={() => setFieldTouched("phoneno")}
                />

                {touched.phoneno && errors.phoneno && (
                  <div className="text-red-500 text-sm">{errors.phoneno}</div>
                )}
                   
              </div>
            </div>
            <div className="flex gap-5 my-6 w-[80.5%] justify-end">
              <button className="w-[9.38rem] h-[3.13rem] rounded-[0.625rem] bg-transparent  border border-solid border-[#fff] text-xl font-normal not-italic flex items-center justify-center">
                Cancel
              </button>
              <button
                className="w-[9.38rem] h-[3.13rem] rounded-[0.625rem] text-xl font-normal not-italic flex items-center justify-center"
                style={{
                  background:
                    " linear-gradient(180deg, #19788E 0%, #0A0601 100%)",
                }}
                type="submit"
              >
                {loading ? <ClipLoader color="#36d7b7" /> : "Save"}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ProfileSettings;
