import React from 'react'
import { Outlet, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import Auth from '../layouts/Auth'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import ForgotPass from '../pages/ForgotPass'
import FreeTrial from '../pages/FreeTrial'
const Root = () => {
    return (
        <>
            <Outlet />
        </>
    )
}
const publicrouter = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<Root />}>
            <Route index element={<Auth><Login /></Auth>} />
            <Route path='signup' element={<Auth><Signup /></Auth>} />
            <Route path='forgot/password' element={<Auth><ForgotPass /> </Auth>} />
            <Route path='freetrial' element={<Auth><FreeTrial /></Auth>} />
        </Route>
    )
)

export { publicrouter }