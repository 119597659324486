import React, { useContext, useEffect, useState, Suspense } from "react";
import { useGetQuests } from "../hooks/useGetQuests";
import { SyncLoader } from "react-spinners";
import { QuestContext } from "../context/questContext/QuestContext";
import OwlCarouselComponent from "../components/OwlCarouselComponent";
import TrendingList from "../components/TrendingList";
import MobileError from "../components/mobile/MobileError";
export const quest_type_obj = {};

const QuestList = () => {
  const { fetchQuests, loading } = useGetQuests();
  const {
    questState: { quests },
  } = useContext(QuestContext);

  useEffect(() => {
    if (!quests?.length) {
      fetchQuests();
    }
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <MobileError />
      <div className="hidden md:flex flex-col gap-4 w-full">
        {loading && (
          <SyncLoader
            color="#36d7b7"
            style={{
              zIndex: 10000,
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
              marginTop: "30px",
            }}
          />
        )}
        <div className="w-full h-[375px]">
          {!loading && <OwlCarouselComponent />}
        </div>
        {!loading && (
          <TrendingList
            title="Trending now"
            subTitle="AI tools trending currently among all the learners"
            data=""
          />
        )}
        {!loading && (
          <TrendingList
            title="All AI tools"
            subTitle="Discover and learn a new skill today!"
            data=""
          />
        )}
      </div>
    </>
  );
};

export default QuestList;
