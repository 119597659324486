import React, { useContext, useState } from "react";
import { IoShareSocialOutline } from "react-icons/io5";
import { useFeed } from "../../hooks/useFeed";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useOverItem from "../../hooks/useOverItem";
import { textcolor } from "../../constants/styles";
import profile from "../../assets/images/userimg.webp";
import { motion } from "framer-motion";
import { QuestContext } from "../../context/questContext/QuestContext";
import { SET_QUEST_PROMPT } from "../../context/questContext/action";
const ShareCompoenents = ({
  body,
  setFeedModal,
  feedModal,
  postFeed,
  postSent,
}) => {
  const { state } = useOverItem();
  const [status, setStatus] = useState("public");

  const handleSubmitFeed = async () => {
    let postFeedObj={
      ...body,
      email: state.overview?.email,
      xp:state.overview?.xp
    }
    if(state.overview?.role==="admin"){
      postFeedObj["postStatus"]=status
    }
    await postFeed(postFeedObj);
    setFeedModal(false)
  };
  const modalVariants = {
    hidden: { x: "100vh", opacity: 0 },
    visible: { x: 0, opacity: 1, transition: { duration: 1 } },
    exit: { x: "100vh", opacity: 0, transition: { duration: 1 } },
  };
  console.log(body);

  return (
    <motion.div
      style={{ zIndex: 1000000000000000 }}
      initial="hidden"
      animate={feedModal ? "visible" : "exit"}
      variants={modalVariants}
      className={`fixed inset-0 w-full h-full bg-opacity-50  bg-black flex items-center justify-center`}
    >
      <div className="flex flex-col gap-4 px-4 py-4 pb-6 shadow-2xl bg-black bg-opacity-90 h-[80vh] w-[40vw] rounded-2xl">
        {/* image div */}
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div className="">
              <img
                src={
                  state.overview?.profileimg
                    ? state.overview?.profileimg
                    : profile
                }
                className="w-12 h-12 rounded-full"
                alt=""
              />
            </div>
            <div className="flex flex-col">
              <div className="">
                <h1 className="text-white text-[14px]">
                  {state.overview?.username?.length > 15
                    ? state.overview?.username.substring(0, 15) + "..."
                    : state.overview?.username}
                </h1>
              </div>
              <div className={`text-[${textcolor}] text-[12px] font-bold`}>
                {state.overview?.xp * 10} XP
              </div>
            </div>
          </div>
          {/* time div */}
        </div>
        {/* body div */}
        <div className="text-[12px] h-[50px] text-gray-400 overflow-auto">
          {body.userPrompt}
        </div>
        {body?.image && (
          <div className="h-[70%] w-full">
            <LazyLoadImage
              alt="Background"
              threshold={150} // This is where you specify the threshold
              src={body?.image} // Your dynamic background image source
              effect="blur"
              className="object-fit rounded-[5%]  w-full h-full"
              wrapperClassName="w-full h-full rounded-[5%] object-fit"
            />
          </div>
        )}
        {body?.text && (
          <div className="h-[70%] w-full  bg-[#302a4e] bg-opacity-50 shadow-2xl px-6 py-4 rounded-xl">
            <div
              style={{ lineHeight: "1.8" }}
              className="h-[99%] overflow-auto text-[16px] text-gray-300"
            >

              {body?.text.split("\n\n").map((line, index) => (
                       <span key={index}>
                        {line}
                        <br />
                        <br />
                        </span>
             
                    ))}
            </div>
          </div>
        )}

        <div className="flex justify-between items-center">
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            name="status"
            id="status"
            className="rounded-2xl outline-none flex justify-center items-center w-32 h-8  text-gray-400 text-[12px]  border-2 font-bold px-4 border-gray-400"
          >
            <option value="public">Public</option>
            <option value="private">Private</option>
          </select>

          <div className="flex items-center gap-4 pr-4">
            <button
              onClick={() => setFeedModal(false)}
              className="flex items-center text-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmitFeed}
              className="flex gap-1 items-center text-gray-400"
            >
              Submit
              {/* <IoHeart size={20} className="text-red-500"/> */}
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ShareCompoenents;
