import React, { createContext, useContext, useEffect, useReducer } from "react";
import { SET_ERROR_MESSAGE, SET_ERROR_MODAL, SET_ERROR_RESET } from "./action";

export const ErrorContext = createContext();

const initialState = {
  errorModal:false,
  errorMessage:"",
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SET_ERROR_MODAL: {
      return {
        ...state,
        errorModal: action.payload,
      };
      
    }
    case SET_ERROR_RESET: {
      return {
        ...state,
        errorModal:false,
        errorMessaage:""
      };
      
    }
    default:
      return state;
  }
};
const ErrorProvider = ({ children }) => {
//   const { currentUser } = useContext(AuthContext);
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${currentUser?.token}`,
//   };
  const [errorState, dispatch] = useReducer(reducer, initialState);

  const value={
    errorState,
    dispatch,
  }
  return (
    <ErrorContext.Provider value={value}>
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
