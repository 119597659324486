import React, { useContext, useEffect, useState } from "react";
import { textcolor } from "../constants/styles";
import { LeaderBoardContext } from "../context/leaderboardContext/LeaderBoardContext";
import useOverItem from "../hooks/useOverItem";
import { SyncLoader } from "react-spinners";
import ReactCountryFlag from "react-country-flag";
import { countryCodes } from "../constants/countrycodes";
import leaderboard_img1 from "../assets/images/userimg.webp";
import { AuthContext } from "../context/AuthContext";
const LeaderBoardPage = () => {
  const {
    leaderboardState: { leaderboard, loading },
    getLeaderboard,
  } = useContext(LeaderBoardContext);
  const [percelMileStone, setPercentMileStone] = useState(0);
  const { state } = useOverItem();
  const { currentUser } = useContext(AuthContext);
  // const customStyles = {
  //   scrollbarWidth: "thin",
  //   scrollbarColor: "#4a5568 #e2e8f0", // thumb and track color
  // };
  useEffect(() => {
    let isGet = true;
    if (isGet) {
      getLeaderboard();
    }
    return () => {
      isGet = false;
    };
  }, [state?.xpCheck]);
  useEffect(() => {
    let isGet = true;
    const mileStoneCheck = () => {
      const userIndex = leaderboard?.findIndex(
        (user) => user.email === currentUser?.email
      );
      const totalUsers = leaderboard?.length;
      if (userIndex === -1) {
        console.log("not an user");
      }

      const position = userIndex + 1; // Human-readable position (1-based index)
      const percentile = Math.floor((position / totalUsers) * 100); // This reflects how close the user is to the top
      // Process milestones in ascending order of achievement
      const milestones = [90, 50, 30,10,1]; // From lowest to highest top percentile

      for (let milestone of milestones) {
        if (percentile >= milestone) {
          setPercentMileStone(milestone);
          break
        }
      }
    };
    if (isGet) {
      mileStoneCheck();
    }
    return () => {
      isGet = false;
    };
  }, [leaderboard]);
  return (
    <>
      {loading && (
        <div className="flex items-center justify-center mt-[40%] ">
          <SyncLoader color="#36d7b7" />
        </div>
      )}
      {leaderboard && !loading && (
        <div className="flex flex-col items-center gap-y-2 mt-2">
          {/* image div */}
          <div className="flex gap-6">
            <div className="flex flex-col items-center">
              <div className="relative flex justify-center mt-6 items-center w-16 h-16 bg-gray-300 rounded-full">
                <img
                  src={
                    leaderboard[1]?.profilePhoto
                      ? leaderboard[1]?.profilePhoto
                      : leaderboard_img1
                  }
                  className="w-full h-full object-cover rounded-full"
                  alt=""
                />
                <ReactCountryFlag
                  countryCode={countryCodes[leaderboard[1]?.country]}
                  svg
                  style={{
                    width: "20px",
                    height: "20px",

                    position: "absolute",
                    top: "-20%",
                    translateX: "50%",
                  }}
                  title={leaderboard[1]?.country}
                />
                <div className="absolute bottom-[-15%] w-6 h-6 flex justify-center items-center text-md font-bold bg-[#424344] rounded-full">
                  2
                </div>
              </div>
              <div className="flex flex-col gap-1 items-center mt-6">
                <div className={`text-md`}>
                  <h1>
                    {leaderboard[1]?.username.length > 8
                      ? leaderboard[1]?.username.substring(0, 8) + "..."
                      : leaderboard[1]?.username}
                  </h1>
                </div>
                <div className={`text-sm text-[${textcolor}] font-bold`}>
                  <h1>{leaderboard[1]?.xp * 10}</h1>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="relative flex justify-center items-center mt-[-10px] w-24 h-24 bg-gray-300 rounded-full">
                <img
                  src={
                    leaderboard[0]?.profilePhoto
                      ? leaderboard[0]?.profilePhoto
                      : leaderboard_img1
                  }
                  className="w-full h-full object-cover rounded-full"
                  alt=""
                />
                <ReactCountryFlag
                  countryCode={countryCodes[leaderboard[0]?.country]}
                  svg
                  style={{
                    width: "30px",
                    height: "30px",

                    position: "absolute",
                    top: "-20%",
                    translateX: "50%",
                  }}
                  title={leaderboard[0]?.country}
                />
                <div className="absolute bottom-[-10%] w-8 h-8 flex justify-center items-center text-lg font-bold bg-[#424344] rounded-full">
                  1
                </div>
              </div>
              <div className="flex flex-col gap-1 items-center mt-4">
                <div className={`text-md`}>
                  <h1>
                    {leaderboard[0]?.username.length > 8
                      ? leaderboard[0]?.username.substring(0, 8) + "..."
                      : leaderboard[0]?.username}
                  </h1>
                </div>
                <div className={`text-sm text-[${textcolor}] font-bold`}>
                  <h1>{leaderboard[0]?.xp * 10}</h1>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="relative flex justify-center mt-6 items-center w-16 h-16 bg-gray-300 rounded-full">
                <img
                  src={
                    leaderboard[2]?.profilePhoto
                      ? leaderboard[2]?.profilePhoto
                      : leaderboard_img1
                  }
                  className="w-full h-full object-cover rounded-full"
                  alt=""
                />
                <ReactCountryFlag
                  countryCode={countryCodes[leaderboard[2]?.country]}
                  svg
                  style={{
                    width: "20px",
                    height: "20px",

                    position: "absolute",
                    top: "-20%",
                    translateX: "50%",
                  }}
                  title={leaderboard[2]?.country}
                />
                <div className="absolute bottom-[-15%] w-6 h-6 flex justify-center items-center text-md font-bold bg-[#424344] rounded-full">
                  3
                </div>
              </div>
              <div className="flex flex-col gap-1 items-center mt-6">
                <div className={`text-md`}>
                  <h1>
                    {leaderboard[2]?.username.length > 8
                      ? leaderboard[2]?.username.substring(0, 8) + "..."
                      : leaderboard[2]?.username}
                  </h1>
                </div>
                <div className={`text-sm text-[${textcolor}] font-bold`}>
                  <h1>{leaderboard[2]?.xp * 10}</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="h-[60px] w-full  px-4 py-2 bg-[#312c4e] flex items-center justify-center gap-2 shadow-2xl rounded-xl">
            <div className="w-[33%] h-[95%] px-2 bg-[#3d385c] shadow- text-sm font-bold flex justify-center items-center rounded-xl shadow-2xl">
              Top {percelMileStone}%
            </div>
            <div className="w-full h-full pl-2 text-sm flex items-center flex-wrap gap-x-2">
              <span>You’re current in the </span>
              <span className={`text-[${textcolor}]`}>
                top {percelMileStone}%
              </span>{" "}
              <span>of all the</span>
              <span>learners on</span>{" "}
              <span className={`text-[${textcolor}]`}>XDojo</span>
            </div>
          </div>

          <div className="flex flex-col gap-4 py-2 h-[100px] lg:min-h-[20vh] w-full overflow-auto">
            {leaderboard?.slice(3)?.map((item, index) => (
              <div className="flex justify-between items-center px-4">
                <div className="flex gap-4 items-center">
                  <div className="flex items-center justify-center w-12 text-md font-bold">
                    {index + 4}
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-8 h-8 bg-gray-500 flex items-center rounded-full justify-center">
                      <img
                        src={
                          item?.profilePhoto
                            ? item?.profilePhoto
                            : leaderboard_img1
                        }
                        className="h-full w-full object-contain rounded-full"
                        alt=""
                      />
                    </div>
                    <div className="text-sm font-bold justify-center items-center">
                      <h2>
                        {item?.username.length > 8
                          ? item?.username.substring(0, 8) + "..."
                          : item?.username}
                      </h2>
                    </div>
                    <div className="flex justify-center items-center">
                      <ReactCountryFlag
                        countryCode={countryCodes[item.country]}
                        svg
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "5px",
                        }}
                        title={item.country}
                      />
                    </div>
                  </div>
                </div>

                {/* xpdiv */}
                <div className={`text-md font-bold text-[${textcolor}]`}>
                  {item?.xp * 10} XP
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default LeaderBoardPage;
